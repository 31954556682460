import React, { useState, useEffect } from "react";

import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { toast } from "sonner";

function WordSearch() {
	const { authenticated, login, logout } = useAuth();

	const gridSize = 10;
	const wordsToPick = 8; // 7 // 17;
	const [grid, setGrid] = useState([]);
	const [selectedCells, setSelectedCells] = useState([]);
	const [foundWords, setFoundWords] = useState([]);
	const [mouseDown, setMouseDown] = useState(false);
	const [gameWon, setGameWon] = useState(false);
	const [wordPositions, setWordPositions] = useState({});
	const [startCell, setStartCell] = useState(null);
	const [wordsToFind, setWordsToFind] = useState([]);

	const [language, setLanguage] = useState("en");

	const langOptions = {
		en: [
			"art",
			"code",
			"map",
			"game",
			"play",
			"apple",
			"river",
			"dream",
			"light",
			"night",
			"star",
			"moon",
			"sun",
			"tree",
			"cat",
			"dog",
			"fish",
			"bird",
			"desk",
			"chair",
			"table",
			"car",
			"bike",
			"shoe",
			"hat",
			"snow",
			"rain",
			"storm",
			"wind",
			"fire",
			"cloud",
			"rock",
			"stone",
			"grass",
			"flower",
			"house",
			"home",
			"city",
			"town",
			"village",
			"ocean",
			"sea",
			"lake",
			"mountain",
			"hill",
			"valley",
			"path",
			"road",
			"bridge",
			"book",
			"page",
			"word",
			"letter",
			"note",
			"pen",
			"ink",
			"paint",
			"brush",
			"song",
			"music",
			"band",
			"film",
			"movie",
			"show",
			"dance",
			"laugh",
			"smile",
			"cry",
			"tear",
			"voice",
			"sound",
			"bell",
			"drum",
			"piano",
			"guitar",
			"violin",
			"trumpet",
			"school",
			"class",
			"test",
			"quiz",
			"math",
			"science",
			"history",
			"story",
			"legend",
			"myth",
			"magic",
			"spell",
			"curse",
			"bless",
			"wish",
			"hope",
			"love",
			"heart",
			"mind",
			"soul",
			"life",
			"death",
			"birth",
			"grow",
			"child",
			"baby",
			"parent",
			"family",
			"friend",
			"mate",
			"team",
			"group",
			"party",
			"meal",
			"food",
			"drink",
			"water",
			"milk",
			"bread",
			"fruit",
			"meat",
			"soup",
			"cake",
			"pie",
			"sugar",
			"salt",
			"pepper",
			"spice",
			"butter",
			"cream",
			"ice",
			"frost",
			"cold",
			"warm",
			"hot",
			"heat",
			"freeze",
			"melt",
			"boil",
			"steam",
			"gas",
			"air",
			"breath",
			"shout",
			"whisper",
			"talk",
			"speak",
			"read",
			"write",
			"draw",
			"color",
			"shade",
			"dark",
			"bright",
			"dull",
			"sharp",
			"blur",
			"focus",
			"zoom",
			"glass",
			"window",
			"door",
			"wall",
			"roof",
			"floor",
			"ground",
			"earth",
			"soil",
			"dirt",
			"dust",
			"sand",
			"pebble",
			"boulder",
			"cliff",
			"cave",
			"hole",
			"pit",
			"pond",
			"wave",
			"tide",
			"surf",
			"sail",
			"boat",
			"ship",
			"raft",
			"island",
			"shore",
			"beach",
			"coast",
			"bay",
			"gulf",
			"stream",
			"waterfall",
			"cascade",
			"flow",
			"flood",
			"dam",
			"trail",
			"track",
			"route",
			"way",
			"street",
			"lane",
			"avenue",
			"boulevard",
			"alley",
			"square",
			"plaza",
			"park",
			"garden",
			"yard",
			"field",
			"meadow",
			"forest",
			"wood",
			"grove",
			"jungle",
			"wild",
			"nature",
			"animal",
			"creature",
			"beast",
			"insect",
			"bug",
			"fly",
			"ant",
			"bee",
			"wing",
			"feather",
			"beak",
			"claw",
			"talon",
			"paw",
			"foot",
			"hand",
			"finger",
			"toe",
			"head",
			"face",
			"eye",
			"ear",
			"nose",
			"mouth",
			"lip",
			"tongue",
			"tooth",
			"hair",
			"skin",
			"bone",
			"blood",
			"vein",
			"lung",
			"sigh",
			"noise",
			"quiet",
			"silent",
			"loud",
			"bang",
			"crash",
			"boom",
			"echo",
			"ring",
			"chime",
			"tune",
			"melody",
			"beat",
			"rhythm",
			"harmony",
			"choir",
			"orchestra",
			"instrument",
			"flute",
			"harp",
			"horn",
			"pipe",
			"reed",
			"string",
			"cord",
			"wire",
			"cable",
			"rope",
			"knot",
			"tie",
			"bow",
			"bend",
			"twist",
			"coil",
			"loop",
			"circle",
			"sphere",
			"ball",
			"globe",
			"orb",
			"disk",
			"coin",
			"medal",
			"badge",
			"symbol",
			"sign",
			"mark",
			"flag",
			"banner",
			"label",
			"tag",
			"brand",
			"logo",
			"name",
			"title",
			"text",
			"font",
			"type",
			"print",
			"paper",
			"sheet",
			"message",
			"card",
			"ticket",
			"pass",
			"stamp",
			"seal",
			"post",
			"mail",
			"send",
			"deliver",
			"drop",
			"box",
			"bag",
			"case",
			"pocket",
			"purse",
			"wallet",
			"money",
			"cash",
			"gold",
			"silver",
			"metal",
			"iron",
			"steel",
			"bronze",
			"copper",
			"brass",
			"lead",
			"tin",
			"zinc",
			"nickel",
			"alloy",
			"plastic",
			"brick",
			"block",
			"tile",
			"plank",
			"board",
			"panel",
			"ceiling",
			"stop",
			"close",
			"shut",
			"lock",
			"key",
			"signal",
			"figure",
			"number",
			"digit",
			"count",
			"measure",
			"weigh",
			"scale",
			"balance",
			"meter",
			"gauge",
			"clock",
			"watch",
			"time",
			"hour",
			"minute",
			"second",
			"moment",
			"instant",
			"flash",
			"blink",
			"glance",
			"look",
			"see",
			"view",
			"observe",
			"spy",
			"scan",
			"search",
			"find",
			"discover",
			"uncover",
			"reveal",
			"hide",
			"cover",
			"cloak",
			"mask",
			"veil",
			"shadow",
			"shine",
			"glow",
			"gleam",
			"glint",
			"spark",
			"flare",
			"blaze",
			"burn",
			"flame",
			"cool",
			"dew",
			"mist",
			"fog",
			"sky",
			"planet",
			"world",
			"space",
			"void",
			"infinite",
			"beyond",
			"unknown",
			"mystery",
			"secret",
			"hidden",
			"still",
			"calm",
			"peace",
			"rest",
			"sleep",
			"day",
			"dawn",
			"sunrise",
			"morning",
			"noon",
			"afternoon",
			"evening",
			"twilight",
			"sunset",
			"dusk",
			"midnight",
			"black",
			"white",
			"gray",
			"red",
			"orange",
			"yellow",
			"green",
			"blue",
			"purple",
			"pink",
			"brown",
			"gate",
			"fence",
			"barrier",
			"chain",
			"thread",
			"line",
			"tunnel",
			"rail",
			"train",
			"bus",
			"cycle",
			"wheel",
			"tire",
			"engine",
			"motor",
			"machine",
			"device",
			"tool",
			"gadget",
			"appliance",
			"equipment",
			"gear",
			"kit",
			"set",
			"pouch",
			"sack",
			"container",
			"bottle",
			"jar",
			"pot",
			"can",
			"pack",
			"parcel",
			"package",
			"bundle",
			"pile",
			"stack",
			"heap",
			"mound",
			"mass",
			"blob",
			"spot",
			"dot",
			"stain",
			"trace",
			"stripe",
			"shape",
			"form",
			"pattern",
			"texture",
			"surface",
			"layer",
			"coat",
			"shell",
			"crust",
			"bark",
			"leaf",
			"stem",
			"branch",
			"root",
			"trunk",
			"twig",
			"vine",
			"bloom",
			"bud",
			"petal",
			"thorn",
			"needle",
			"seed",
			"nut",
			"berry",
			"grain",
			"wheat",
			"corn",
			"rice",
			"oat",
			"barley",
			"rye",
			"bean",
			"pea",
			"lentil",
			"chickpea",
			"soy",
			"tofu",
			"egg",
			"cheese",
			"honey",
			"syrup",
			"herb",
			"mint",
			"basil",
			"thyme",
			"rosemary",
			"sage",
			"curry",
			"ginger",
			"garlic",
			"onion",
			"leek",
			"carrot",
			"potato",
			"tomato",
			"chili",
			"cookie",
			"pasta",
			"noodle",
			"dumpling",
			"pizza",
			"burger",
			"fries",
			"chip",
			"snack",
			"vegetable",
			"banana",
			"lemon",
			"lime",
			"grape",
			"cherry",
			"plum",
			"peach",
			"pear",
			"mango",
			"melon",
			"kiwi",
			"fig",
			"date",
			"tea",
			"coffee",
			"soda",
			"beer",
			"wine",
			"liquor",
			"cocktail",
			"shot",
			"sip",
			"gulp",
			"swallow",
			"taste",
			"flavor",
			"smell",
			"scent",
			"aroma",
			"odor",
			"perfume",
			"fragrance",
			"spray",
			"breeze",
			"gust",
			"universe",
			"galaxy",
			"comet",
			"meteor",
			"asteroid",
			"atlas",
			"chart",
			"diagram",
			"grid",
			"smoke",
		],
		pt: [
			"arte",
			"código",
			"mapa",
			"jogo",
			"brincar",
			"maçã",
			"rio",
			"sonho",
			"luz",
			"noite",
			"estrela",
			"lua",
			"sol",
			"árvore",
			"gato",
			"cachorro",
			"peixe",
			"pássaro",
			"escrivaninha",
			"cadeira",
			"mesa",
			"carro",
			"bicicleta",
			"sapato",
			"chapéu",
			"neve",
			"chuva",
			"tempestade",
			"vento",
			"fogo",
			"nuvem",
			"rocha",
			"pedra",
			"grama",
			"flor",
			"casa",
			"lar",
			"cidade",
			"vila",
			"oceano",
			"mar",
			"lago",
			"montanha",
			"colina",
			"vale",
			"caminho",
			"estrada",
			"ponte",
			"livro",
			"página",
			"palavra",
			"letra",
			"nota",
			"caneta",
			"tinta",
			"pintura",
			"pincel",
			"canção",
			"música",
			"banda",
			"filme",
			"cinema",
			"programa",
			"dança",
			"riso",
			"sorriso",
			"chorar",
			"lágrima",
			"voz",
			"som",
			"sino",
			"tambor",
			"piano",
			"guitarra",
			"violino",
			"trompete",
			"escola",
			"aula",
			"teste",
			"quiz",
			"matemática",
			"ciência",
			"história",
			"conto",
			"lenda",
			"mito",
			"mágica",
			"feitiço",
			"maldição",
			"benção",
			"desejo",
			"esperança",
			"amor",
			"coração",
			"mente",
			"alma",
			"vida",
			"morte",
			"nascimento",
			"crescer",
			"criança",
			"bebê",
			"pai",
			"família",
			"amigo",
			"parceiro",
			"equipe",
			"grupo",
			"festa",
			"refeição",
			"comida",
			"bebida",
			"água",
			"suco",
			"leite",
			"pão",
			"fruta",
			"carne",
			"legume",
			"sopa",
			"bolo",
			"torta",
			"açúcar",
			"sal",
			"pimenta",
			"tempero",
			"óleo",
			"manteiga",
			"creme",
			"gelo",
			"geada",
			"frio",
			"quente",
			"calor",
			"congelar",
			"derreter",
			"ferver",
			"vapor",
			"gás",
			"ar",
			"respiração",
			"grito",
			"sussurro",
			"falar",
			"ler",
			"escrever",
			"desenhar",
			"pintar",
			"cor",
			"sombra",
			"escuro",
			"brilhante",
			"opaco",
			"afiado",
			"borrado",
			"foco",
			"zoom",
			"vidro",
			"janela",
			"porta",
			"parede",
			"telhado",
			"piso",
			"chão",
			"terra",
			"solo",
			"sujeira",
			"poeira",
			"areia",
			"seixo",
			"pedregulho",
			"penhasco",
			"caverna",
			"buraco",
			"fosso",
			"lagoa",
			"onda",
			"maré",
			"surf",
			"velejar",
			"barco",
			"navio",
			"jangada",
			"ilha",
			"costa",
			"praia",
			"litoral",
			"baía",
			"golfo",
			"riacho",
			"cachoeira",
			"cascata",
			"fluxo",
			"inundação",
			"represa",
			"trilha",
			"pista",
			"rota",
			"rua",
			"avenida",
			"boulevard",
			"beco",
			"praça",
			"plaza",
			"parque",
			"jardim",
			"quintal",
			"campo",
			"prado",
			"floresta",
			"bosque",
			"selva",
			"selvagem",
			"natureza",
			"animal",
			"criatura",
			"besta",
			"inseto",
			"bicho",
			"mosca",
			"formiga",
			"abelha",
			"asa",
			"pena",
			"bico",
			"garra",
			"unha",
			"pata",
			"pé",
			"mão",
			"dedo",
			"cabeça",
			"rosto",
			"olho",
			"orelha",
			"nariz",
			"boca",
			"lábio",
			"língua",
			"dente",
			"cabelo",
			"pele",
			"osso",
			"sangue",
			"veia",
			"pulmão",
			"suspiro",
			"ruído",
			"silêncio",
			"quieto",
			"alto",
			"explosão",
			"batida",
			"eco",
			"toque",
			"melodia",
			"ritmo",
			"harmonia",
			"coro",
			"orquestra",
			"instrumento",
			"flauta",
			"harpa",
			"chifre",
			"tubo",
			"palheta",
			"corda",
			"fio",
			"cabo",
			"nó",
			"laço",
			"curva",
			"torção",
			"bobina",
			"anel",
			"círculo",
			"esfera",
			"bola",
			"globo",
			"orbe",
			"disco",
			"moeda",
			"medalha",
			"insígnia",
			"símbolo",
			"sinal",
			"marca",
			"bandeira",
			"banner",
			"etiqueta",
			"logotipo",
			"nome",
			"título",
			"texto",
			"fonte",
			"tipo",
			"impressão",
			"papel",
			"folha",
			"mensagem",
			"carta",
			"cartão",
			"bilhete",
			"passe",
			"selo",
			"correio",
			"enviar",
			"entregar",
			"soltar",
			"caixa",
			"saco",
			"caso",
			"bolso",
			"bolsa",
			"carteira",
			"dinheiro",
			"ouro",
			"prata",
			"metal",
			"ferro",
			"aço",
			"bronze",
			"cobre",
			"latão",
			"chumbo",
			"estanho",
			"zinco",
			"níquel",
			"liga",
			"plástico",
			"madeira",
			"tijolo",
			"bloco",
			"azulejo",
			"tábua",
			"painel",
			"teto",
			"portão",
			"cerca",
			"barreira",
			"parar",
			"fechar",
			"trancar",
			"chave",
			"figura",
			"número",
			"dígito",
			"contar",
			"medir",
			"pesar",
			"balança",
			"medidor",
			"relógio",
			"tempo",
			"hora",
			"minuto",
			"segundo",
			"momento",
			"instante",
			"relâmpago",
			"piscar",
			"olhar",
			"ver",
			"observar",
			"espiar",
			"escanear",
			"procurar",
			"encontrar",
			"descobrir",
			"desvendar",
			"revelar",
			"mostrar",
			"esconder",
			"cobrir",
			"manto",
			"máscara",
			"véu",
			"brilhar",
			"cintilar",
			"faísca",
			"fagulha",
			"flash",
			"chama",
			"queimar",
			"orvalho",
			"névoa",
			"neblina",
			"céu",
			"planeta",
			"mundo",
			"espaço",
			"vazio",
			"infinito",
			"além",
			"dentro",
		],
	};

	const WORD_LIST = langOptions[language];

	const [seed, setSeed] = useState("");

	useEffect(() => {
		const preventScroll = (e) => e.preventDefault();

		// Attach touch listeners to prevent scroll on mobile
		window.addEventListener("touchmove", preventScroll, { passive: false });

		return () => {
			// Clean up the event listener
			window.removeEventListener("touchmove", preventScroll);
		};
	}, []);

	// set random seed

	useEffect(() => {
		async function getRandomSeed() {
			const response = await fetch(
				"https://nerded.io/api/games/getwordsearch",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated?.userId,
						session_token: authenticated?.sessionToken,
					}),
				},
			);

			const data = await response.json();
			console.log(data);

			if (data.requestStatus === "error") {
				if (data?.relog) {
					toast.error("Please login again!");
					logout();
				}

				toast.error(data.error);
				return;
			}

			setSeed(data.seed);
		}

		getRandomSeed();
	}, [authenticated]);

	function createSeededRandom(seed) {
		return function () {
			const x = Math.sin(seed++) * 10000;
			return x - Math.floor(x);
		};
	}

	useEffect(() => {
		initializeGrid();
	}, [language, seed]); // Reinitialize grid whenever the language changes

	const initializeGrid = () => {
		let newGrid = Array(gridSize)
			.fill()
			.map(() => Array(gridSize).fill(""));
		let newWordPositions = {};
		let placedWords = [];

		// Create a seeded random number generator
		const seededRandom = createSeededRandom(seed);

		if (seed !== "") {
			// Shuffle the word list based on the seed
			const shuffledWordList = WORD_LIST.sort(
				() => seededRandom() - 0.5,
			).slice(0, wordsToPick);

			shuffledWordList.forEach((word) => {
				const positions = placeWordInGrid(newGrid, word, seededRandom);
				if (positions) {
					newWordPositions[word.toUpperCase()] = positions;
					placedWords.push(word.toUpperCase());
				}
			});

			// Fill remaining empty cells with random letters
			for (let i = 0; i < gridSize; i++) {
				for (let j = 0; j < gridSize; j++) {
					if (newGrid[i][j] === "") {
						newGrid[i][j] = String.fromCharCode(
							65 + Math.floor(seededRandom() * 26),
						);
					}
				}
			}

			setGrid(newGrid);
			setWordPositions(newWordPositions);
			setWordsToFind(placedWords);
			setFoundWords([]);
			setGameWon(false);
		}
	};

	const placeWordInGrid = (grid, word, seededRandom) => {
		let placed = false;
		let positions = [];
		let attempts = 0;
		const maxAttempts = 100; // Safety mechanism to prevent infinite loops

		while (!placed && attempts < maxAttempts) {
			const row = Math.floor(seededRandom() * gridSize);
			const col = Math.floor(seededRandom() * gridSize);
			const direction = Math.floor(seededRandom() * 2); // 0: right, 1: down (no diagonals)

			if (canPlaceWord(grid, word, row, col, direction)) {
				for (let i = 0; i < word.length; i++) {
					const [dx, dy] = getDirectionOffsets(direction);
					grid[row + i * dy][col + i * dx] = word[i].toUpperCase();
					positions.push([row + i * dy, col + i * dx]);
				}
				placed = true;
			}
			attempts++;
		}

		if (!placed) {
			console.warn(`Failed to place word: ${word}`);
			return null; // If failed to place, return null
		}

		return positions;
	};

	const canPlaceWord = (grid, word, row, col, direction) => {
		const [dx, dy] = getDirectionOffsets(direction);
		for (let i = 0; i < word.length; i++) {
			const newRow = row + i * dy;
			const newCol = col + i * dx;
			if (
				newRow < 0 ||
				newRow >= gridSize ||
				newCol < 0 ||
				newCol >= gridSize ||
				(grid[newRow][newCol] !== "" &&
					grid[newRow][newCol] !== word[i].toUpperCase())
			) {
				return false;
			}
		}
		return true;
	};

	const getDirectionOffsets = (direction) => {
		return direction === 0 ? [1, 0] : [0, 1]; // Right or Down
	};

	const handleMouseDown = (row, col) => {
		setMouseDown(true);
		setStartCell([row, col]);
		setSelectedCells([[row, col]]);
	};

	const handleMouseOver = (row, col) => {
		if (mouseDown && startCell) {
			const [startRow, startCol] = startCell;
			let newSelectedCells = [];

			if (row === startRow) {
				// Horizontal selection
				const start = Math.min(startCol, col);
				const end = Math.max(startCol, col);
				for (let i = start; i <= end; i++) {
					newSelectedCells.push([row, i]);
				}
			} else if (col === startCol) {
				// Vertical selection
				const start = Math.min(startRow, row);
				const end = Math.max(startRow, row);
				for (let i = start; i <= end; i++) {
					newSelectedCells.push([i, col]);
				}
			} else {
				// If not in the same row or column, keep only the start cell
				newSelectedCells = [startCell];
			}

			setSelectedCells(newSelectedCells);
		}
	};

	const handleMouseUp = () => {
		setMouseDown(false);
		checkSelectedWord();
		setSelectedCells([]);
		setStartCell(null);
	};

	// Mobile touch handlers
	const handleTouchStart = (e, row, col) => {
		e.preventDefault(); // Prevent default behavior like scrolling
		handleMouseDown(row, col);
	};

	const handleTouchMove = (e) => {
		e.preventDefault(); // Prevent scrolling during touch move
		const touch = e.touches[0];
		const element = document.elementFromPoint(touch.clientX, touch.clientY);
		if (element && element.dataset.row && element.dataset.col) {
			const row = parseInt(element.dataset.row, 10);
			const col = parseInt(element.dataset.col, 10);
			handleMouseOver(row, col);
		}
	};

	const handleTouchEnd = () => {
		handleMouseUp();
	};

	const handleMouseMove = (e) => {
		if (!mouseDown) return;
		const element = e.target;
		if (element && element.dataset.row && element.dataset.col) {
			const row = parseInt(element.dataset.row, 10);
			const col = parseInt(element.dataset.col, 10);
			handleMouseOver(row, col);
		}
	};

	const checkSelectedWord = () => {
		const selectedWord = selectedCells
			.map(([row, col]) => grid[row][col])
			.join("");
		const reversedWord = selectedWord.split("").reverse().join("");
		if (
			(wordsToFind.includes(selectedWord) ||
				wordsToFind.includes(reversedWord)) &&
			!foundWords.includes(selectedWord) &&
			!foundWords.includes(reversedWord)
		) {
			const wordToAdd = wordsToFind.includes(selectedWord)
				? selectedWord
				: reversedWord;
			setFoundWords((prev) => [...prev, wordToAdd]);
			if (foundWords.length + 1 === wordsToFind.length) {
				setGameWon(true);
			}
		}
	};

	const isSelected = (row, col) => {
		return selectedCells.some((cell) => cell[0] === row && cell[1] === col);
	};

	const isPartOfFoundWord = (row, col) => {
		return foundWords.some((word) =>
			wordPositions[word]?.some(
				(pos) => pos[0] === row && pos[1] === col,
			),
		);
	};

	const getCellColor = (row, col) => {
		if (isSelected(row, col)) return "bg-nord-700/40";
		if (isPartOfFoundWord(row, col))
			return "bg-green-800/50 text-green-500";
		return "bg-nord-800 text-slate-200";
	};

	const reset = () => {
		initializeGrid();
	};

	if (seed === "") {
		return (
			<div>
				<Navbar />

				<div className="text-center w-full text-xl font-bold my-[10rem]">
					{/* <div className="flex flex-row gap-4 items-center mx-auto p-2 bg-nord-800 w-fit rounded-lg pr-[1rem]">
                        <div className="bg-sky-900 h-[50px] w-[50px] rounded-lg flex items-center justify-center">
                        <span className="material-symbols-outlined animate-spin text-[2rem] font-normal text-sky-500 flex items-center justify-center">
                            autorenew
                        </span>
                        </div>
                        <div className="font-normal text-[1.4rem]">
                            Loading...
                        </div>
                    </div> */}

					<div className="flex items-center w-fit mx-auto gap-3 bg-slate-800/50 px-6 py-4 rounded-lg border border-slate-700">
						<div className="w-5 h-5 border-2 border-slate-300 border-t-transparent rounded-full animate-spin"></div>
						<span className="text-slate-300 font-medium">
							Loading game...
						</span>
					</div>
				</div>

				<Footer />
			</div>
		);
	}

	return (
		<div>
			<Navbar />
			<div className="relative min-h-screen bg-nord-900 flex flex-col items-center justify-center text-center">
				<h1 className="text-4xl text-slate-300 mb-[3rem] font-bold">
					Word Search
				</h1>
				<div className="flex">
					<div
						className={`grid grid-cols-10 gap-1 select-none`}
						onMouseLeave={handleMouseUp}
					>
						{grid.map((row, rowIndex) =>
							row.map((letter, colIndex) => (
								<div
									key={`${rowIndex}-${colIndex}`}
									className={`w-12 h-12 rounded-sm flex items-center justify-center cursor-pointer ${getCellColor(
										rowIndex,
										colIndex,
									)}`}
									onMouseDown={() =>
										handleMouseDown(rowIndex, colIndex)
									}
									onMouseOver={() =>
										handleMouseOver(rowIndex, colIndex)
									}
									onMouseUp={handleMouseUp}
									onTouchStart={(e) =>
										handleTouchStart(e, rowIndex, colIndex)
									}
									onTouchMove={handleTouchMove}
									onTouchEnd={handleTouchEnd}
									data-row={rowIndex}
									data-col={colIndex} // Add these attributes for touch move
								>
									{letter}
								</div>
							)),
						)}
					</div>
					<div className="ml-8 flex flex-col text-slate-400">
						<span className="font-semibold mb-1">
							Words to Find:
						</span>

						{wordsToFind.map((word, index) => (
							<p
								key={index}
								className={
									foundWords.includes(word)
										? "text-green-500"
										: ""
								}
							>
								{word}
							</p>
						))}
						{gameWon && (
							<div
								onClick={reset}
								className="fixed top-0 left-0 w-[100dvw] h-[100dvh] flex justify-center items-center bg-black/60"
							>
								<div className="text-green-500 p-[1rem] rounded bg-nord-900 absolute">
									You found all the words! Congratulations!
									<div
										onClick={reset}
										className="mt-4 px-4 select-none cursor-pointer bg-nord-800 w-fit text-white p-2 rounded hover:bg-nord-hover"
									>
										Restart
									</div>
								</div>
							</div>
						)}
					</div>

					<div
						className={`text-slate-400 fixed top-[5.8rem] left-[1rem] flex space-x-4 ${
							!authenticated.authenticated ? "mt-[3rem]" : ""
						}`}
					>
						<button
							onClick={() => setLanguage("en")}
							className={`px-4 py-2 rounded ${
								language === "en"
									? "bg-green-800/50 text-green-400"
									: "bg-nord-800 text-slate-400"
							}`}
						>
							English
						</button>
						<button
							onClick={() => setLanguage("pt")}
							className={`px-4 py-2 rounded ${
								language === "pt"
									? "bg-green-800/50 text-green-400"
									: "bg-nord-800 text-slate-400"
							}`}
						>
							Portuguese
						</button>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default WordSearch;
