import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useAuth } from "../context/AuthContext";

function InputCenter(props) {
	const { authenticated } = useAuth();
	const navigate = useNavigate();

	const ifOnSearchPage = window.location.pathname === "/search";
	const searchTerm = ifOnSearchPage
		? window.location.search.substring(3)
			? decodeURIComponent(window.location.search.substring(3))
			: ""
		: "";

	const inputRef = useRef(null);
	const suggestionRef = useRef(null);
	const [inputValue, setInputValue] = useState(searchTerm);
	const [suggested, setSuggested] = useState([]);
	const [showingSuggested, setShowingSuggested] = useState(false);

	async function fetchToSetSuggested() {
		const value = inputValue;

		if (value === "") {
			setSuggested([]);
			setShowingSuggested(false);
			return;
		}

		if (value.length < 2) {
			setSuggested([]);
			setShowingSuggested(false);
			return;
		}

		if (value !== "" && value.length >= 2) {
			await fetch("https://nerded.io/api/suggest?q=" + value, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({}),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.requestStatus === "error") {
						console.log(data.message);
						return;
					}

					if (data.items.length === 0) {
						setSuggested([]);
						setShowingSuggested(false);
						return;
					}

					setSuggested(data.items);
					setShowingSuggested(true); // Show suggestions when data is fetched
				});
		}
	}

	async function handleInput(e) {
		const value = e.target.value;
		setInputValue(value);

		fetchToSetSuggested();
	}

	function strip(str) {
		return str.replace(/(^\s+|\s+$)/g, "").toLowerCase();
	}

	function enterSearch(searchTermToEnterSearch) {
		let prevLocation = window.location.pathname;

		const articles = ["the", "an", "this", "of", "to", "be"];

		if (
			searchTermToEnterSearch.length <= 4 &&
			articles.includes(strip(searchTermToEnterSearch))
		) {
			let errString = `Query must be specific [${strip(
				searchTermToEnterSearch,
			)}]`;

			toast.error(errString);
			return;
		}

		if (inputValue !== "") {
            if (prevLocation === "/search" && searchTerm === searchTermToEnterSearch) {
                props.searchWithQuery();
            }

			navigate("/search?q=" + searchTermToEnterSearch);
		} else {
			toast.error("Please enter a search term");
		}
	}

	function focusSearch() {
		inputRef.current.focus();
		setShowingSuggested(true); // Show suggestions when input is focused
	}

	const handleKeyPress = useCallback(
		(event) => {
			if (event.key === "Enter") {
				if (inputValue !== "") {
                    // unfocus the input
                    inputRef.current.blur();
                    setShowingSuggested(false);

					enterSearch(inputValue);
				} else {
					toast.error("Please enter a search term");
				}
			}
		},
		[inputValue],
	);

	const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowInnerWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// on cmd + k
	const handleCmdK = (event) => {
		if (event.key === "k" && event.metaKey) {
			focusSearch();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleCmdK);
	}, []);

	function clickOnSuggested(query) {
		setInputValue(query);
		setSuggested([]);
		enterSearch(query);
	}

	// click outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				inputRef.current &&
				suggestionRef.current &&
				!inputRef.current.contains(event.target) &&
				!suggestionRef.current.contains(event.target)
			) {
				setShowingSuggested(false); // Hide suggestions instead of clearing them
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [inputRef, suggestionRef]);

	return (
		<>
			{windowInnerWidth >= 700 ? (
				<div className="flex flex-col justify-start m-0 absolute right-0 translate-x-[calc(-50vw+12.5rem)]">
					<div className="flex-row items-center">
						<input
							onKeyDown={handleKeyPress}
							value={inputValue}
							onChange={handleInput}
							onClick={fetchToSetSuggested}
							ref={inputRef}
							type={props.type}
							spellCheck="true"
							placeholder={props.placeholder}
							className={`bg-nord-700/20 text-slate-50 px-3 box-border ${
								suggested.length === 0 || !showingSuggested
									? "rounded-lg"
									: "rounded-t-lg"
							} p-2 outline-none border-2 border-transparent cursor-text w-[25rem] focus:border-sky-500`}
						/>
						<span
							onClick={() => enterSearch(inputValue)}
							className="material-symbols-outlined z-20 translate-x-[22.7rem] mt-[-37.5px] absolute select-none text-slate-500 hover:text-sky-500 hover:bg-sky-950 cursor-pointer p-[0.13rem] rounded bg-nord-800 flex items-center justify-center"
						>
							keyboard_return
						</span>
					</div>

					{showingSuggested && suggested.length > 0 && (
						<div
							ref={suggestionRef}
							className={`flex flex-col height-[-${
								suggested.length * 3.5
							}rem] absolute top-[3rem]`}
						>
							{suggested.map((item, index) => (
								<div
									key={index}
									className={`bg-sky-950 truncate border-2 border-t-0 border-sky-500 flex-row gap-2 transition flex items-center text-white p-2 h-[3.5rem] px-[0.6rem] ${
										index + 1 === suggested.length
											? "rounded-b-lg"
											: ""
									} cursor-pointer w-[25rem] hover:bg-sky-950 hover:text-sky-300`}
									onClick={() => clickOnSuggested(item.query)}
								>
									<span className="material-symbols-outlined text-sky-400 p-1 rounded-lg bg-sky-900 transition">
										search
									</span>
									<span className="truncate flex items-center mb-[-1px]">
										{item.query}
									</span>
								</div>
							))}
						</div>
					)}
				</div>
			) : null}
			{windowInnerWidth < 700 ? (
				<div className={`flex flex-col w-full justify-start items-start`}>
					<div className={`flex-row w-full items-center`}>
						<input
							onKeyDown={handleKeyPress}
							value={inputValue}
							onChange={handleInput}
							onClick={fetchToSetSuggested}
							ref={inputRef}
							type={props.type}
							spellCheck="true"
							placeholder={props.placeholder}
							className={`${
								suggested.length === 0 || !showingSuggested
									? "rounded-lg"
									: "rounded-t-lg"
							} bg-slate-700 text-slate-50 px-3 box-border rounded-lg p-2 w-full outline-none border-2 border-transparent cursor-text focus:border-sky-500`}
						/>
					</div>

					{showingSuggested && suggested.length > 0 && (
						<div
							ref={suggestionRef}
							className={`flex flex-col absolute border-t-2 border-t-sky-500 left-0 w-full mt-[62px] height-[-${
								suggested.length * 3.5
							}rem]`}
						>
							{suggested.map((item, index) => (
								<div
									key={index}
									className={`bg-sky-950 truncate border-2 border-t-0 w-full border-sky-500 flex-row gap-2 transition flex items-center text-white p-2 h-[3.5rem] px-[0.6rem] ${
										index + 1 === suggested.length
											? "rounded-b-lg"
											: ""
									}

                                        cursor-pointer hover:bg-sky-950 hover:text-sky-300`}
									onClick={() => clickOnSuggested(item.query)}
								>
									<span className="material-symbols-outlined text-sky-400 p-1 rounded-lg bg-sky-900 transition">
										search
									</span>
									<span className="truncate flex items-center mb-[-1px]">
										{item.query}
									</span>
								</div>
							))}
						</div>
					)}
				</div>
			) : null}
		</>
	);
}

export default InputCenter;
