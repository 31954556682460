import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { Progress } from "../components/ui/progress"
import { Link } from "react-router-dom";
import { toast } from "sonner";

import Challenge from "../components/Challenge";

export default function ViewChallengesPage() {
    const { authenticated, login, logout, signup } = useAuth();

    if (!authenticated.authenticated) {
        login();
        toast.error("You must be logged in to view this page.");
    }

    const [activeChallenges, setActiveChallenges] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);

    useEffect(() => {
        async function fetchChallenges() {
            await fetch("https://nerded.io/api/challenges/view", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                console.log(data?.error);

                setActiveChallenges(data.challenges_active);
                setCompletedChallenges(data.challenges_ended);
            })
            .catch((error) => {
                toast.error("Failed to fetch challenges");
            });
        }

        fetchChallenges();
    }, [authenticated]);

    console.log(activeChallenges);

    const [showInactiveChallenges, setShowInactiveChallenges] = useState(false);

    function toggleShowInactiveChallenges() {
        setShowInactiveChallenges(!showInactiveChallenges);
    }

    async function deleteChallenge(challengeId) {
        console.log("delete")
        console.log(challengeId)

        const response = await fetch("https://nerded.io/api/challenges/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: authenticated.userId,
                session_token: authenticated.sessionToken,
                challenge_id: challengeId,
            }),
        });

        const data = await response.json();

        if (data.requestStatus === "success") {
            toast.success("Challenge deleted successfully");

            setActiveChallenges(activeChallenges.filter((challenge) => challenge._id !== challengeId));
            setCompletedChallenges(completedChallenges.filter((challenge) => challenge._id !== challengeId));

            setCanCreateNewChallenge(true);

        } else {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(`Failed to delete challenge: ${data.error}`);
        }
    }

    const [canCreateNewChallenge, setCanCreateNewChallenge] = useState(true);

    let counter = 0;
    activeChallenges?.map((challenge) => {
        let jsEndDate = new Date(challenge.challenge_due_date * 1000);

        if (jsEndDate >= Date.now()) {
            counter++;
        }
    });

    const [isActiveChallenge, setIsActiveChallenge] = useState(false);

    const [anyInactiveChallenges, setAnyInactiveChallenges] = useState(false);

    useEffect(() => {
        let activeCount = 0;
        let inactiveCount = 0;

        activeChallenges?.forEach((challenge) => {
            let jsEndDate = new Date(challenge.challenge_due_date * 1000);

            if (jsEndDate >= Date.now()) {
                activeCount++;
            } else {
                inactiveCount++;
            }
        });

        // Determine if there are active challenges
        if (activeCount >= 1) {
            setCanCreateNewChallenge(false);
            setIsActiveChallenge(true);
        } else {
            setIsActiveChallenge(false);
        }

        // Determine if there are any inactive challenges
        if (inactiveCount >= 1) {
            setAnyInactiveChallenges(true);
        } else {
            setAnyInactiveChallenges(false);
        }
    }, [activeChallenges]);

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-col w-fit ml-auto mr-auto my-[5rem] mb-[8rem]">

            <span className="text-[1.2rem] font-bold mb-[1rem]">
                Your Active Challenges
            </span>

            <div className="flex flex-col h-fit justify-center w-[95vw] md:w-[80vw] lg:w-[60vw] xl:w-[60vw] 2xl:w-[60vw] gap-[1rem] items-start">
                {activeChallenges?.map((challenge) => {
                    return (
                        <Challenge
                            name={challenge.challenge_name}
                            booksRead={challenge.books_read}
                            challengeTotal={challenge.num_books}
                            endDate={challenge.challenge_due_date}
                            deleteChallenge={() => deleteChallenge(challenge._id)}
                        />
                    )
                })}

                {!isActiveChallenge ?
                    <div className="p-3 text-[1.1rem] rounded-lg border-2 border-slate-800 bg-slate-900 flex items-center gap-2">
                        <span className="material-symbols-outlined text-[1.3rem] text-slate-100">info</span>
                        <span>No active challenges</span>
                    </div>
                : null}
            </div>

            <div onClick={toggleShowInactiveChallenges} className="w-fit cursor-pointer text-[1.2rem] select-none font-bold items-center flex flex-row gap-2 mb-[1rem] mt-[2rem]">
                <span>Your Inactive Challenges</span>

                <span className={`material-symbols-outlined ${showInactiveChallenges ? "rotate-90" : ""} transition`}>
                    chevron_right
                </span>
            </div>

            {showInactiveChallenges ?
            <div className="flex flex-col h-fit justify-center w-[95vw] md:w-[80vw] lg:w-[60vw] xl:w-[60vw] 2xl:w-[60vw] gap-[1rem] items-start">

                {completedChallenges?.map((challenge) => {
                    return (
                        <Challenge
                            name={challenge.challenge_name}
                            booksRead={challenge.books_read}
                            challengeTotal={challenge.num_books}
                            endDate={challenge.challenge_due_date}
                            deleteChallenge={() => deleteChallenge(challenge._id)}
                            id={challenge._id}
                        />
                    )
                })}

                {!completedChallenges ?
                    <div className="p-3 text-[1.1rem] rounded-lg border-2 border-slate-800 bg-slate-900 flex items-center gap-2">
                        <span className="material-symbols-outlined text-[1.3rem] text-slate-100">info</span>
                        <span>No Completed challenges</span>
                    </div>
                : null}
            </div>
            : null}


            {canCreateNewChallenge ?
            <Link to="/challenge/create" className="mt-[1rem] w-fit px-6 h-[3rem] cursor-pointer rounded-md flex items-center justify-center bg-slate-800 hover:bg-slate-700 transition">
                Create Challenge
            </Link>
            :
            <span className="mt-[1rem] w-fit px-6 h-[3rem] rounded-lg flex items-center justify-center bg-slate-900 border border-slate-600 transition">
                You have reached the maximum number of active challenges (1)
            </span>
            }

            </div>

            <Footer />
        </div>
    )
}
