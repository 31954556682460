import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "sonner";

import bgImg from "../assets/bg.png";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@radix-ui/react-hover-card";

function Streak() {
	const { authenticated, login, logout } = useAuth();

	const Navigate = useNavigate();

	if (!authenticated.authenticated) {
		login();
	}

	const mapColors = {
		unranked: "text-gray-400",
		bronze: "text-amber-700",
		silver: "text-slate-400",
		gold: "text-amber-500",
		platinum: "text-slate-500",
		diamond: "text-blue-300",
		master: "text-red-600",
		legend: "text-sky-200",
	};

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const [streakInfo, setStreakInfo] = useState({
		current_streak: 0,
		longest_streak: 0,
		last_updated: 0,
		date_longest_streak_started: 0,
		date_current_streak_started: 0,
	});

	const [levelingData, setLevelingData] = useState({
		level: 0,
		experience: 0,
		experience_to_next_level: 0,
		current_league: "unranked",
		highest_league: "unranked",
		current_rank: 0,
	});

	const [rendered, setRendered] = useState(false);

	useEffect(() => {
		async function getStreakData() {
			await fetch("https://nerded.io/api/streaks/view_user_streak", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.requestStatus === "error") {
						if (data?.relog) {
							toast.error("Please login again!");
							logout();
						}

						toast.error(data.error);
						return;
					}

					setStreakInfo(data.streaks);
					setLevelingData(data.leveling);

					setRendered(true);
				});
		}

		getStreakData();
	}, [authenticated.userId, authenticated.sessionToken]);

	console.log(streakInfo);

	const streakChecks = [];
	const today = new Date();
	const streakStartDate = new Date(
		streakInfo.date_current_streak_started * 1000,
	);
	const streakDays = streakInfo.current_streak;

	// Calculate the start of the current week (Sunday)
	const currentWeekStart = new Date(today);
	const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
	currentWeekStart.setDate(today.getDate() - dayOfWeek);
	currentWeekStart.setHours(0, 0, 0, 0);

	// Calculate the days from the start of the current week to today
	const daysFromStartOfWeek = dayOfWeek + 1; // Today is included

	let sundayYes = false;
	let mondayYes = false;
	let tuesdayYes = false;
	let wednesdayYes = false;
	let thursdayYes = false;
	let fridayYes = false;
	let saturdayYes = false;

	// Fill the streakChecks array
	for (let i = 0; i < 7; i++) {
		const checkDate = new Date(currentWeekStart);
		checkDate.setDate(currentWeekStart.getDate() + i);

		if (i < daysFromStartOfWeek) {
			const dayIndexFromStart = i - (daysFromStartOfWeek - streakDays);
			if (dayIndexFromStart >= 0 && dayIndexFromStart < streakDays) {
				streakChecks.push(
					<div
						className="text-amber-500 h-fit w-fit rounded-full border-[2px] bg-amber-900 p-3 border-amber-500 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
						key={i}
					>
						<span className="material-symbols-outlined text-amber-400 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
							local_fire_department
						</span>
					</div>,
				);

				switch (i) {
					case 0:
						sundayYes = true;
						break;
					case 1:
						mondayYes = true;
						break;
					case 2:
						tuesdayYes = true;
						break;
					case 3:
						wednesdayYes = true;
						break;
					case 4:
						thursdayYes = true;
						break;
					case 5:
						fridayYes = true;
						break;
					case 6:
						saturdayYes = true;
						break;
				}
			} else {
				streakChecks.push(
					<div
						className="text-sky-500 h-fit w-fit rounded-full border-[2px] bg-slate-900/30 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
						key={i}
					>
						<span className="material-symbols-outlined text-sky-500 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
							local_fire_department
						</span>
					</div>,
				);

				switch (i) {
					case 0:
						sundayYes = false;
						break;
					case 1:
						mondayYes = false;
						break;
					case 2:
						tuesdayYes = false;
						break;
					case 3:
						wednesdayYes = false;
						break;
					case 4:
						thursdayYes = false;
						break;
					case 5:
						fridayYes = false;
						break;
					case 6:
						saturdayYes = false;
						break;
				}
			}
		} else {
			streakChecks.push(
				<div
					className="text-gray-500 h-fit w-fit rounded-full border-[2px] bg-slate-900/30 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
					key={i}
				>
					<span className="material-symbols-outlined text-sky-500 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
						local_fire_department
					</span>
				</div>,
			);

			switch (i) {
				case 0:
					sundayYes = false;
					break;
				case 1:
					mondayYes = false;
					break;
				case 2:
					tuesdayYes = false;
					break;
				case 3:
					wednesdayYes = false;
					break;
				case 4:
					thursdayYes = false;
					break;
				case 5:
					fridayYes = false;
					break;
				case 6:
					saturdayYes = false;
					break;
			}
		}
	}

	const allYes =
		sundayYes &&
		mondayYes &&
		tuesdayYes &&
		wednesdayYes &&
		thursdayYes &&
		fridayYes &&
		saturdayYes;

	return (
		<div className="grid-new">
			<Navbar showAccountPrompt={true} />

			{rendered ? (
				<div className="flex flex-col ml-auto mr-auto w-fit gap-[1rem] mt-[1rem] mb-[2rem] justify-center items-center">
					<div className="flex flex-col max-w-[95vw] gap-[1rem] bg-nord-900 py-[1.5rem] px-[1.8rem] my-[4rem] rounded-xl shadow shadow-slate-950 border border-slate-700">
						<div className="flex flex-row justify-between items-center mb-[1rem]">
							<div className="text-2xl font-semibold text-slate-100">
								My Streak
							</div>

							<HoverCard openDelay={50} closeDelay={100}>
								<HoverCardTrigger>
									<div className="text-amber-500 mr-4 cursor-pointer h-[50px] w-[50px] rounded-full border-[2px] bg-amber-900 border-amber-500 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center">
										<div className="bg-amber-900 rounded-full ml-[9px] mr-1 px-[5px] text-[1.8rem]">
											{streakInfo.current_streak}
										</div>

										<span className="material-symbols-outlined bg-amber-900 justify-center flex items-center border-2 border-amber-500 text-amber-500 text-[1.7rem] rounded-full font-normal">
											local_fire_department
										</span>
									</div>
								</HoverCardTrigger>
								<HoverCardContent side="top" className="m-3 border rounded-lg border-nord-700/50">
									<div className="flex flex-row items-center w-fit gap-2 px-3 py-2 rounded-lg bg-slate-950">
										<span className="material-symbols-outlined text-[2.1rem] text-amber-500">
											local_fire_department
										</span>

										<span className="text-[1.2rem]">
											{streakInfo.current_streak} day
											streak
										</span>
									</div>
								</HoverCardContent>
							</HoverCard>
						</div>

						<div
						className={`w-full flex flex-col ${
							allYes
								? "bg-amber-950/90 border-amber-700"
								: "bg-sky-950/50 border-sky-700/80"
						} rounded-xl border-2 p-4 relative`}
					>
						<div
							className={`absolute inset-0 ${
								allYes ? "bg-amber-900" : "bg-sky-950"
							} pointer-events-none`}
							style={{
								backgroundImage:
									`url(${bgImg})`,
								backgroundBlendMode: "overlay", // Ensures the background color blends with the image
                                zoom: "1.5",
								opacity: "0.12",
							}}
						/>
						<div className="relative z-[1]">
							<div className="flex flex-col gap-3 px-0 md:px-3 py-2 rounded-lg">
								<div className="flex flex-row flex-wrap gap-0 md:gap-4 justify-between w-full items-center">
									{streakChecks}
								</div>

								<div className="flex flex-row text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg gap-0 md:gap-2 lg:gap-4 items-center w-full justify-between">
									<span className="flex justify-center w-full">
										<span
											className={`${
												sundayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Sun
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												mondayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Mon
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												tuesdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Tue
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												wednesdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Wed
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												thursdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Thu
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												fridayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Fri
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												saturdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Sat
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>

						<div className="w-full border-b border-b-nord-700/50 my-2"></div>

						<div className="flex flex-row items-center gap-2 px-3 py-2 rounded-lg border border-nord-700/80 bg-slate-950/50">
							<span
								className={`material-symbols-outlined text-[2.1rem] ${
									mapColors[levelingData.current_league]
								}`}
							>
								emoji_events
							</span>

							<span className={`text-[1.2rem]`}>
								{levelingData.current_league === "unranked"
									? "Unranked"
									: capitalize(levelingData.current_league) +
									  " League"}
							</span>

							<span className="text-slate-400 ml-auto pr-1">
								Level {levelingData.level} &nbsp;(
								{levelingData.experience} XP)
							</span>
						</div>

						{/*<div className="flex flex-col gap-3 p-3 rounded-lg bg-slate-950/50">
                        <div className="flex flex-row gap-3 items-center rounded-lg">
                            <img src={authenticated.picture} className="rounded-full w-[40px] h-[40px]" />
                            <span className="text-[1rem]">
                                {authenticated.name}
                            </span>

                            <span className="ml-auto text-slate-400">
                                40 XP
                            </span>
                        </div>

                            <span className="rounded bg-slate-950/50 px-2 py-2 flex flex-row gap-1 items-center">
                                <span className="material-symbols-outlined text-[1.5rem] text-slate-400">
                                    workspace_premium
                                </span>
                            </span>
                        </div>
                    </div>*/}
					</div>

					{/*<div className="flex flex-row gap-[1rem] w-[55vw]">
                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[45%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                workspace_premium
                            </span>

                            Level 1
                        </div>
                    </div>

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[55%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                local_fire_department
                            </span>

                            50 day streak
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-[1rem] w-[55vw]">

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[55%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                emoji_events
                            </span>
                            4 Completed
                        </div>
                    </div>

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[45%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                database
                            </span>
                            1,204 Coins
                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-row gap-5 justify-center rounded-lg w-fit mt-[3rem] mb-[3rem] ml-auto mr-auto">
                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-yellow-950 border-2 border-yellow-600">
                    <span className="material-symbols-outlined font-semibold text-[2.7rem] text-yellow-500">
                        done
                    </span>
                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>
            </div>

            <div className="flex flex-col gap-y-[2rem] mt-[2rem] mb-[3rem]">
                <div className="flex flex-col bg-blue-950 w-[40rem] h-[15rem] ml-auto mr-auto p-4 rounded-lg">
                    <div className="text-2xl">Streaks</div>

                    <div className="flex flex-col gap-2">
                        <div className="">
                            You've logged in daily for the past
                        </div>

                        <div className="p-2 px-3 bg-blue-600 w-fit rounded-full">
                            59 days
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-blue-950 w-[40rem] h-[15rem] ml-auto mr-auto p-4 rounded-lg">
                    <div className="text-2xl">Store</div>

                    <div className="flex flex-col gap-2">
                        <div className="">
                            You've logged in daily for the past
                        </div>

                        <div className="p-2 px-3 bg-blue-600 w-fit rounded-full">
                            59 days
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex flex-row gap-[1rem] w-[55vw] ml-auto mr-auto mb-[4rem]">

                <div className="p-3 rounded-xl flex items-center justify-center text-center bg-cyan-950 border-[3px] border-cyan-800 text-slate-100 w-[60%] h-[150px]">
                    <div className="text-cyan-500 text-3xl font-semibold flex gap-3 items-center">
                        <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                            workspace_premium
                        </span>
                        Diamond League
                    </div>
                </div>

                <div className="p-3 rounded-xl flex items-center justify-center text-center bg-cyan-950 border-[3px] border-cyan-800 text-slate-100 w-[40%] h-[150px]">
                    <div className="text-cyan-500 text-3xl font-semibold flex gap-3 items-center">
                        500 xp
                    </div>
                </div>*/}
				</div>
			) : (
				<span className="mx-auto text-[2rem] flex justify-center my-[4rem] font-semibold text-slate-400">
					Loading...
				</span>
			)}

			<Footer />
		</div>
	);
}

export default Streak;
