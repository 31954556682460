//import { useAuth } from '../context/AuthContext';

/*async function getUserInfoByToken(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function verifyTokenForAudience(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function loginToNerded(googleToken) {
    const response = await fetch(`https://nerded.io/api/users/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token: googleToken
        }),
    })

    const data = await response.json();

    return data;
}

/*async function CacheImage(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          return base64data
        };
        reader.readAsDataURL(blob);

      } else {
        console.log('Failed to fetch profile image, status:', response.status);
        return imageUrl;
      }
    } catch (error) {
      console.error('Error fetching profile image:', error);
      return imageUrl;
    }
  };

async function AuthenticationLogin(codeResponse, showToast) {
    //const { authenticated, setAuthenticated } = useAuth();
    const googleClientId = "337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com"

    let userInfo = await getUserInfoByToken(codeResponse);
    let tokenInfo = await verifyTokenForAudience(codeResponse);

    let tokenAud = tokenInfo.aud;

    const matchAudienceBool = tokenAud === googleClientId;

    if (matchAudienceBool) {

        const loginResponse = await loginToNerded(
            codeResponse.access_token,
        );

        console.log(loginResponse);

        if (Object.keys(loginResponse).includes("success")) {

            //const sessionToken = loginResponse["session_token"];
            //const userId = loginResponse["user_id"];

            // TODO: check that all variables get saved to user data compared to the signup function

            let userData = {
                authenticated: true,
                userId: loginResponse.user_info.user_id,
                sessionToken: loginResponse.user_info.session_token,
                name: userInfo.name,
                email: userInfo.email,
                picture: userInfo.picture,
                googleTokenInfo: {
                    googleToken: codeResponse.access_token,
                    googleTokenExpires: codeResponse.expires_in,
                    googleTokenExpiresDate: codeResponse.expires_at,
                    googleRefreshToken: codeResponse.refresh_token,
                }
            }

            localStorage.setItem("authenticated", JSON.stringify(userData));
            //localStorage.setItem("authenticated", JSON.stringify(userInfo));
            showToast("Successfully logged in!", "success");

            return userData;

            //window.location = redirect;
        } else {
            showToast("Error: something went wrong with your login!", "error");
            return "error";
        }

    } else if (!matchAudienceBool) {
        //logoutWrapper();
        showToast("Error: something went wrong with your authentication!", "error");
        return "error";
    }
}

export default AuthenticationLogin;*/

import Cookies from 'js-cookie';
import { toast } from "sonner";

async function getUserInfoByToken(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function verifyTokenForAudience(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function loginToNerded(googleToken) {
    const response = await fetch(`https://nerded.io/api/users/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token: googleToken
        }),
    })

    const data = await response.json();

    return data;
}

async function AuthenticationLogin(codeResponse, showToast) {
    const googleClientId = "337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com";

    let userInfo = await getUserInfoByToken(codeResponse);
    let tokenInfo = await verifyTokenForAudience(codeResponse);

    let tokenAud = tokenInfo.aud;

    const matchAudienceBool = tokenAud === googleClientId;

    if (matchAudienceBool) {

        const loginResponse = await loginToNerded(
            codeResponse.access_token,
        );

        //console.log(codeResponse)

        if (Object.keys(loginResponse).includes("success")) {

            console.log(loginResponse);

            let userData = {
                authenticated: true,
                userId: loginResponse.user_info.user_id,
                sessionToken: loginResponse.user_info.session_token,
                name: loginResponse.user_info.name,
                rank: loginResponse.user_info.rank,
                email: userInfo.email,
                picture: userInfo.picture,
                language: loginResponse.user_info.language,
                searchLanguages: loginResponse.user_info.search_languages,
                picture_is_default: loginResponse.user_info.picture_is_default,
                picture_generation_details: {
                    variant: loginResponse.user_info.picture_generation_details.variant,
                    seed: loginResponse.user_info.picture_generation_details.seed,
                    colors: loginResponse.user_info.picture_generation_details.colors
                },
                googleTokenInfo: {
                    googleToken: codeResponse.access_token,
                    googleTokenExpires: codeResponse.expires_in,
                    googleTokenExpiresDate: codeResponse.expires_at,
                    googleRefreshToken: codeResponse.refresh_token,
                }
            };

            // Replace localStorage with Cookies
            Cookies.set("authenticated", JSON.stringify(userData), { expires: 21 }); // Set the cookie with 7-day expiration
            toast.success("Successfully logged in!");

            return userData;

        } else {
            toast.error("Something went wrong with your login!");
            return "error";
        }

    } else if (!matchAudienceBool) {
        toast.error("Something went wrong with your authentication!");
        return "error";
    }
}

export default AuthenticationLogin;
