import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Posthead from "./Posthead";

import { toast } from "sonner";
import { Link, useLocation } from "react-router-dom";

import ProfilePicture from "../../components/ProfilePicture";

import BookClubSelector from "./BookClubSelector";

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
  } from "../../components/ui/hover-card"

import { Button } from "../../components/ui/button"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"

export default function BookClubView() {
    const location = useLocation();
    const textareaRef = useRef(null);
    const titleRef = useRef(null);
    const { authenticated, login, logout, signup } = useAuth();

    const textInputRef = useRef(null);
    const linkInputRef = useRef(null);

    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const [userNotInBookClub, setUserNotInBookClub] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    // select ?id from the url
    //const urlParams = new URLSearchParams(window.location.search);
    //const bookClubId = urlParams.get("id");

    if (!authenticated.authenticated) {
        login();
        alert("You must be logged in to view this page.");
    }

    const urlParams_ = new URLSearchParams(window.location.search);
    const [bookClubId, setBookClubId] = useState(urlParams_.get("id"));

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id");
        if (id !== bookClubId) {
            setBookClubId(id);
        }
    }, [location]);

    function format(symbol) {
        // get selected text, add ** to the start and end of the selection
        // if there is no selection, add ** to the start and end of the textarea

        // get the textarea
        const textarea = textareaRef.current;

        // get the selection
        const selectionStart = textarea.selectionStart;

        // get the selection
        const selectionEnd = textarea.selectionEnd;

        // get the text
        const text = textarea.value;

        // get the selected text
        const selectedText = text.substring(selectionStart, selectionEnd);

        // get the text before the selection
        const textBefore = text.substring(0, selectionStart);

        // get the text after the selection
        const textAfter = text.substring(selectionEnd);

        // add the bold text
        const boldText = `${symbol}${selectedText}${symbol}`;

        // set the new text
        textarea.value = textBefore + boldText + textAfter;

        // set the new selection
        textarea.selectionStart = selectionStart + 1;
        textarea.selectionEnd = selectionEnd + 1;

        // focus the textarea
        textarea.focus();
    }

    function linkify() {

        // get the textarea
        const textarea = textareaRef.current;

        // get the selection
        const selectionStart = textarea.selectionStart;
        const selectionEnd = textarea.selectionEnd;

        // get the text
        const text = textarea.value;

        // get the selected text

        // If no text is selected, prompt for name and link
        let name = textInputRef.current.value;
        let link = linkInputRef.current.value;

        if (name.trim() === "" || link.trim() === "") {
            toast.error("Please enter a name and link.");
            return;
        }

        if (!link.startsWith("http://") && !link.startsWith("https://")) {
            link = "http://" + link
        }

        if (link.includes(" ")) {
            toast.error("Link cannot contain spaces.");
            return;
        }

        // see if has .
        if (!link.includes(".")) {
            toast.error("Invalid link.");
            return;
        }

        if (name !== null && link !== null) {
            // Insert the link with the provided name and link
            const linkText = `[${name}](${link})`;
            textarea.value = text.substring(0, selectionStart) + linkText + text.substring(selectionEnd);

            // Set the selection to the end of the inserted text
            textarea.selectionStart = selectionStart + linkText.length;
            textarea.selectionEnd = textarea.selectionStart;

            // remove the text from the input fields
            textInputRef.current.value = "";
            linkInputRef.current.value = "";

            // close dialog
            setIsOpenDialog(false);

            // Focus the textarea
            textarea.focus();
        }
    }

    async function postComment(asAnnouncement) {
        const textarea = textareaRef.current;
        const titleInput = titleRef.current;
        const text = textarea.value;
        const title = titleInput.value;

        if (text.trim() === undefined || text.trim() === "" || text.trim() === null) {
            toast.error("Please enter some text to post.");
        } else if (title.trim() === undefined || title.trim() === "" || title.trim() === null) {
            toast.error("Please enter a title for your post.");
        } else {
            await fetch("https://nerded.io/api/bookclub/add_post", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_token: authenticated.sessionToken,
                    user_id: authenticated.userId,
                    author_id: authenticated.userId,
                    author: authenticated.name,
                    author_image: authenticated.picture,
                    author_picture_is_default: authenticated.picture_is_default,
                    picture_generation_details: {
                        variant: authenticated.picture_generation_details.variant,
                        seed: authenticated.picture_generation_details.seed,
                        colors: authenticated.picture_generation_details.colors,
                    },
                    club_id: bookClubId,
                    date: Math.floor(Date.now() / 1000),
                    post_title: title,
                    post_content: text,
                    is_announcement: asAnnouncement,
                }),
            }).then((response) => response.json()).then((data) => {
                if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                    toast.error(data.error);
                } else {
                    textarea.value = "";
                    titleInput.value = "";

                    if (asAnnouncement) {
                        setFeed({
                            announcements: [
                                {
                                    _id: data.post_id,
                                    title: title,
                                    content: text,
                                    author_role: "admin",
                                    author_id: authenticated.userId,
                                    author: authenticated.name,
                                    author_image: authenticated.picture,
                                    author_picture_is_default: authenticated.picture_is_default,
                                    picture_generation_details: {
                                        variant: authenticated.picture_generation_details.variant,
                                        seed: authenticated.picture_generation_details.seed,
                                        colors: authenticated.picture_generation_details.colors,
                                    },
                                    date: Math.floor(Date.now() / 1000),
                                },
                                ...feed.announcements,
                            ],
                            posts: feed.posts,
                        });
                    } else {
                        setFeed({
                            announcements: feed.announcements,
                            posts: [
                                {
                                    _id: data.post_id,
                                    title: title,
                                    content: text,
                                    date: Math.floor(Date.now() / 1000),
                                    author_image: authenticated.picture,
                                    author: authenticated.name,
                                    author_role: isAdmin ? "admin" : "member",
                                    author_picture_is_default: authenticated.picture_is_default,
                                    picture_generation_details: {
                                        variant: authenticated.picture_generation_details.variant,
                                        seed: authenticated.picture_generation_details.seed,
                                        colors: authenticated.picture_generation_details.colors,
                                    },
                                },
                                ...feed.posts,
                            ],
                        });
                    }
                }
            });
        }
    }

    const [feed, setFeed] = useState({
        announcements: [],
        posts: [],
    });

    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        async function loadFeed() {
            const response = await fetch(`https://nerded.io/api/bookclub/get_feed`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_token: authenticated.sessionToken,
                    user_id: authenticated.userId,
                    club_id: bookClubId,
                })
            });

            const data = await response.json();

            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
                setUserNotInBookClub(true);
            } else {
                // reverse the feed so the newest posts are at the top
                data.feed.posts.reverse();
                data.feed.announcements.reverse();
                setFeed(data.feed);

                if (data.admins.includes(authenticated.userId)) {
                    setIsAdmin(true);
                }

                setMemberList(data.member_list);
            }
        }

        loadFeed();

    }, [authenticated.sessionToken, authenticated.userId, bookClubId]);

    function parseContent(content) {
        const elements = [];
        let currentIndex = 0;

        const regex = /_(.*?)_|~(.*?)~|\*(.*?)\*|\[(.*?)\]\((.*?)\)/g;
        let match;

        while ((match = regex.exec(content)) !== null) {
            const [fullMatch, underline, italic, bold, linkText, linkUrl] = match;
            const startIndex = match.index;

            if (startIndex > currentIndex) {
                elements.push(content.slice(currentIndex, startIndex));
            }

            if (underline) {
                elements.push(<u key={startIndex}>{underline}</u>);
            } else if (italic) {
                elements.push(<i key={startIndex}>{italic}</i>);
            } else if (bold) {
                elements.push(<b key={startIndex}>{bold}</b>);
            } else if (linkText && linkUrl) {
                elements.push(
                    <HoverCard openDelay="200" closeDelay="400">
                        <HoverCardTrigger>
                            <Link key={startIndex} to={linkUrl} target="_blank" rel="noreferrer" className="text-cyan-400 hover:underline">
                                {linkText}
                            </Link>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-fit m-[10px]" side="top">
                            {linkUrl}
                        </HoverCardContent>
                    </HoverCard>
                );
            }

            currentIndex = regex.lastIndex;
        }

        if (currentIndex < content.length) {
            elements.push(content.slice(currentIndex));
        }

        return elements;
    };

    function capitalize(string) {
        try {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } catch (e) {
            return "Unknown";
        }
    }

    function formatTimeAgo(datePosted) {
        if (!datePosted) {
            return "Unknown";
        }

        // datePosted is time.time() from python
        const timeAgo = new Date(datePosted * 1000);
        const now = new Date();

        const timeDifferenceInSeconds = Math.floor((now - timeAgo) / 1000);

        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

        if (timeDifferenceInSeconds < 60) {
            return rtf.format(-timeDifferenceInSeconds, 'second');
        } else if (timeDifferenceInSeconds < 3600) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 60), 'minute');
        } else if (timeDifferenceInSeconds < 86400) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 3600), 'hour');
        } else if (timeDifferenceInSeconds < 2592000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 86400), 'day');
        } else if (timeDifferenceInSeconds < 31536000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 2592000), 'month');
        } else {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 31536000), 'year');
        }
    }

    async function deletePost(postId, typeOfPost) {
        await fetch("https://nerded.io/api/bookclub/delete_post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_token: authenticated.sessionToken,
                user_id: authenticated.userId,
                post_id: postId,
                type: typeOfPost,
                book_club_id: bookClubId,
            }),
        }).then((response) => response.json()).then((data) => {
            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
            } else {
                toast.success("Post deleted successfully.");

                if (typeOfPost === "announcements") {
                    setFeed({
                        announcements: feed.announcements.filter((post) => post._id !== postId),
                        posts: feed.posts,
                    });
                } else {
                    setFeed({
                        announcements: feed.announcements,
                        posts: feed.posts.filter((post) => post._id !== postId),
                    });
                }
            }
        });
    }

    const [bookClubExternalLink, setBookClubExternalLink] = useState("");
    const [genreFocus, setGenreFocus] = useState("No Focus");

    useEffect(() => {
        async function fetchBookClubData() {
            const response = await fetch(`https://nerded.io/api/bookclub/get_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_token: authenticated.sessionToken,
                    user_id: authenticated.userId,
                    club_id: bookClubId,
                }),
            });

            const data = await response.json();

            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
            } else {
                setBookClubExternalLink(data?.book_club_info?.external_link);
                setGenreFocus(data?.book_club_info?.genre_focus);
            }
        }

        fetchBookClubData();

    }, [authenticated.sessionToken, authenticated.userId, bookClubId]);

    //console.log(setBookClubExternalLink);
    //console.log(genreFocus);

    const [confirmLeaveText, setConfirmLeaveText] = useState("");

    function typedInConfirmLeaveBox(event) {
        setConfirmLeaveText(event.target.value);
    }

    async function leaveBookClub() {
        if (confirmLeaveText === "confirm") {
            await fetch("https://nerded.io/api/bookclub/leave", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_token: authenticated.sessionToken,
                    user_id: authenticated.userId,
                    club_id: bookClubId,
                }),
            }).then((response) => response.json()).then((data) => {
                if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                    toast.error(data.error);
                } else {
                    toast.success(data.data);
                    window.location.href = "/bookclub";
                }
            });
        }
    }

    if (userNotInBookClub) {
        return (
            <div>
                <Navbar />
                <div className="flex flex-col items-center justify-center mt-20 mb-20">
                    <h1 className="text-2xl font-bold text-slate-100">You are not a member of this book club.</h1>
                    <Link to="/home" className="text-slate-100 text-xl bg-nord-800 px-3 py-2 rounded-lg hover:bg-slate-700 transition mt-4">Home</Link>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Navbar />

            <div className="flex flex-col lg:grid lg:grid-cols-10 xl:grid xl:grid-cols-10 2xl:grid 2xl:grid-cols-10 gap-[2rem] px-[2rem] min-h-[50vh] mt-4 mb-[4rem]">

                <div className="col-span-2">
                    <div className="flex flex-col gap-4">

                        <BookClubSelector />

                        {!isAdmin ?
                        <div className="flex flex-row flex-wrap items-center border-t border-t-slate-800 pt-4 gap-4 mt-[-0.6rem]">
                            <div className="p-3 rounded-xl w-full bg-nord-800 justify-center flex flex-row items-center gap-2">
                                <span className="material-symbols-outlined">
                                    stylus_note
                                </span>
                                <span className="text-[1rem]">
                                    {genreFocus === "No Focus" ?
                                        <span>No Genre Focus</span>
                                        :
                                        <span>Focus on {genreFocus}</span>
                                    }
                                </span>
                            </div>

                            <div className="p-3 rounded-xl w-full bg-nord-800 justify-center flex flex-row items-center gap-2">
                                <span className="material-symbols-outlined">
                                    forum
                                </span>
                                <span className="text-[1rem]">
                                    {
                                        bookClubExternalLink === "" ?
                                        <span>No external link</span>
                                        :
                                        <span>{bookClubExternalLink}</span>
                                    }
                                </span>
                            </div>
                        </div>
                        : null}

                        {isAdmin ?
                        <div className="flex flex-col gap-4 p-[8px] border-[1.5px] border-slate-800 rounded-lg">
                            <Link to={`/bookclub/manage?id=${bookClubId}&tab=club_settings`} className="px-4 py-3 bg-nord-800/50 hover:bg-slate-700/50 rounded-lg justify-center flex cursor-pointer">
                                Manage Club
                            </Link>

                            <Link to={`/bookclub/manage?id=${bookClubId}&tab=invite_codes`} className="px-4 py-3 bg-nord-800/50 hover:bg-slate-700/50 rounded-lg justify-center flex cursor-pointer">
                                Invite Members
                            </Link>

                            <Link to={`/bookclub/manage?id=${bookClubId}&tab=manage_members`} className="px-4 py-3 bg-nord-800/50 hover:bg-slate-700/50 rounded-lg justify-center flex cursor-pointer">
                                Manage Members
                            </Link>
                        </div>
                        : null}


                        {!isAdmin ? <Dialog>
                            <DialogTrigger asChild onClick={() => setConfirmLeaveText("")}>
                                <div className="flex flex-row justify-center w-fit gap-4 p-[8px] border-[1.5px] border-slate-800 transition text-slate-200 hover:border-slate-700 cursor-pointer hover:bg-nord-800 rounded-lg">
                                    <div className="items-center w-fit gap-2 flex-row flex">
                                        <span className="material-symbols-outlined">
                                            more_horiz
                                        </span>
                                    </div>
                                </div>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                        <span>Do you want to leave this book club?</span>
                                    </DialogTitle>

                                    <div className="pt-2 text-slate-400 text-md">
                                        Are you sure you want to leave this book club? You will not be able to rejoin unless you have an invite code.
                                    </div>

                                </DialogHeader>

                                <div className="flex flex-col gap-2">
                                    <span className="text-slate-300">
                                        Type "confirm" to leave the book club.
                                    </span>

                                    <input type="text" onChange={typedInConfirmLeaveBox} className="w-full border border-slate-700 focus:z-10 bg-nord-950/30 outline-none focus:border-slate-600 p-3 rounded-lg" />
                                </div>

                                <div className="flex flex-row gap-4 ml-auto items-center">
                                    <DialogTrigger asChild>
                                        <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                    </DialogTrigger>

                                    {confirmLeaveText === "confirm" ?
                                        <DialogTrigger asChild>
                                            <Button type="submit" onClick={leaveBookClub} className={`w-fit ml-auto ${confirmLeaveText === "confirm" ? "bg-red-700 hover:bg-red-800" : "bg-nord-800 hover:bg-nord-800 cursor-not-allowed"}`}>Leave Book Club</Button>
                                        </DialogTrigger>
                                    :
                                        <Button type="submit" className={`w-fit ml-auto ${confirmLeaveText === "confirm" ? "bg-red-700 hover:bg-red-800" : "bg-nord-800 hover:bg-nord-800 cursor-not-allowed"}`}>Leave Book Club</Button>
                                    }
                                </div>

                            </DialogContent>
                        </Dialog>
                        : null}

                    </div>
                </div>

                <div className="col-span-6 gap-[1rem] flex flex-col">

                    <div className="p-3 rounded-xl bg-nord-950/30 flex flex-col gap-2">
                        <span>
                            Create new post
                        </span>

                        <input ref={titleRef} className="w-full border border-slate-700 focus:z-10 bg-nord-950/30 outline-none focus:border-sky-600 p-3 rounded-tl-lg rounded-tr-lg" placeholder="Title" />

                        <textarea ref={textareaRef} className="w-full min-h-[3rem] mt-[-9px] focus:z-10 h-[7rem] border border-slate-700 bg-nord-950/30 outline-none focus:border-sky-600 p-3 rounded-bl-lg rounded-br-lg" placeholder="Write something amazing here..."></textarea>

                        <div className="flex flex-row flex-wrap gap-3 items-center">
                            <div className="flex flex-wrap">
                                <span onClick={() => format("*")} className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                    format_bold
                                </span>

                                <span onClick={() => format("~")} className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                    format_italic
                                </span>

                                <span onClick={() => format("_")} className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                    format_underlined
                                </span>

                                <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>

                                    <DialogTrigger asChild>
                                        <span className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                            link
                                        </span>
                                    </DialogTrigger>

                                    <DialogContent>
                                        <DialogHeader>
                                        <DialogTitle>Add a hyperlink to your post</DialogTitle>
                                        <DialogDescription>

                                            <div className="flex flex-col gap-3 mt-4">
                                                <Label>Link Text</Label>
                                                <Input ref={textInputRef} placeholder="Link Text" />
                                            </div>

                                            <div className="flex flex-col gap-3 mt-4">
                                                <Label>Link URL</Label>
                                                <Input ref={linkInputRef} placeholder="Link URL" />
                                            </div>

                                        </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter>

                                        <Button className="px-5" onClick={linkify} type="submit">Save</Button>

                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            </div>

                            {/*<div className="border-l-2 border-l-slate-800 pl-3 h-[70%] items-center flex">
                                <span className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                    reviews
                                </span>

                                <span className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                    book
                                </span>
                            </div>*/}

                            <div className="flex flex-row gap-3 ml-auto">
                                {isAdmin ?
                                    <div onClick={() => postComment(true)} className="flex ml-auto select-none bg-transparent border border-slate-800 rounded-md justify-between w-fit px-6 py-2 cursor-pointer hover:bg-nord-800/50">
                                        Post as Announcement
                                    </div>
                                : null}

                                <div onClick={() => postComment(false)} className="flex select-none bg-nord-800 rounded-md justify-between w-fit px-6 py-2 cursor-pointer hover:bg-slate-700">
                                    Post
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        feed.announcements.map((post) => {
                            return (
                                <div key={post._id} className="p-3 rounded-xl bg-nord-950/30 flex flex-col gap-2 border-[1.25px] border-cyan-700">

                                    <div className="flex flex-row w-full justify-between items-center">
                                        {console.log(post)}
                                        <Posthead
                                            name={post.author}
                                            role={capitalize(post.author_role)}
                                            timestamp={post.date}
                                            pinned={true}
                                            image={post.author_image}
                                            picture_is_default={post?.author_picture_is_default}
                                            seed={post?.picture_generation_details?.seed}
                                            variant={post?.picture_generation_details?.variant}
                                            colors={post?.picture_generation_details?.colors}
                                        />

                                        <div className="flex flex-row gap-2 flex-wrap">
                                            {/*<span className="material-symbols-outlined text-[1.2rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                                thumb_up
                                            </span>*/}

                                            {/*<span className="material-symbols-outlined text-[1.2rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                                thumb_down
                                            </span>*/}

                                            {/*<span className="material-symbols-outlined text-[1.2rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                                flag
                                            </span>*/}

                                            {isAdmin || post.author_id === authenticated.userId ?
                                                <span onClick={() => deletePost(post._id, "announcements")} className="material-symbols-outlined text-[1.2rem] text-slate-300 p-3 hover:bg-nord-800/80 rounded-full hover:text-slate-200 cursor-pointer">
                                                    delete
                                                </span>
                                            : null}
                                        </div>
                                    </div>

                                    <div className="font-semibold text-[1.2rem]">
                                        {post.title}
                                    </div>

                                    <div className="h-fit">
                                        <div className="border-l-[3px] border-l-slate-800/90 h-full text-[1.1rem] py-[0.5rem] pl-3">
                                            {parseContent(post.content)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        feed.posts.map((post) => {
                            return (
                                <div key={post._id} className="py-2 px-[0.85rem] rounded-lg flex flex-col bg-nord-800/50">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-row items-center gap-3">
                                            {/*<img src={post.author_image} className="rounded-full w-[2rem] h-[2rem]" />*/}
                                            <ProfilePicture variant={post?.picture_generation_details?.variant} seed={post?.picture_generation_details?.seed} colors={post?.picture_generation_details?.colors} isDefault={post.author_picture_is_default} picture={post.author_image} size="32" className="rounded-full w-[2rem] h-[2rem]" />
                                            {post.author}
                                        </div>

                                        <div className="text-indigo-400 font-semibold text-[0.8rem] mx-3 bg-indigo-900/50 p-2 py-1 rounded">
                                            {capitalize(post.author_role)}
                                        </div>

                                        <span>
                                            {formatTimeAgo(post.date)}
                                        </span>

                                        <div className="flex ml-auto gap-1">
                                            {/*<span className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800 rounded-full hover:text-slate-200 cursor-pointer">
                                                flag
                                            </span>*/}

                                            {isAdmin || post.author_id === authenticated.userId ?
                                                <span onClick={() => deletePost(post._id, "posts")} className="material-symbols-outlined text-[1.3rem] text-slate-300 p-3 hover:bg-nord-800 rounded-full hover:text-slate-200 cursor-pointer">
                                                    delete
                                                </span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-row mt-2">
                                        <div className="font-semibold text-[1.2rem]">
                                            {post.title}
                                        </div>
                                    </div>

                                    <div className="h-fit">
                                        <div className="mt-2 mb-1 text-[1.1rem]">
                                            {parseContent(post.content)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </div>

                <div className="col-span-2">

                    {isAdmin ? <div className="flex flex-row flex-wrap items-center border-b border-b-slate-800 pb-4 gap-4 mb-4">
                        <div className="p-3 rounded-xl w-full bg-nord-800 justify-center flex flex-row items-center gap-2">
                            <span className="material-symbols-outlined">
                                stylus_note
                            </span>
                            <span className="text-[1rem]">
                                {genreFocus === "No Focus" ?
                                    <span>No Genre Focus</span>
                                    :
                                    <span>Focus on {genreFocus}</span>
                                }
                            </span>
                        </div>

                        <div className="p-3 rounded-xl w-full bg-nord-800 justify-center flex flex-row items-center gap-2">
                            <span className="material-symbols-outlined">
                                forum
                            </span>
                            <span className="text-[1rem]">
                                {
                                    bookClubExternalLink === "" ?
                                    <span>No external link</span>
                                    :
                                    <span>{bookClubExternalLink}</span>
                                }
                            </span>
                        </div>
                    </div> : null}

                    <div className="flex flex-col gap-[1rem]">

                        {memberList.map((member) => {
                            return (
                            <Link key={member.id} to={`/profile?id=${member.id}`} className={`w-full flex flex-row gap-3 items-center p-[0.6rem] bg-nord-800 rounded-full cursor-pointer`}>
                                {/*<img src={member.picture} className="rounded-full w-[2rem] h-[2rem]" />*/}
                                <ProfilePicture variant={member?.picture_generation_details?.variant} seed={member?.picture_generation_details?.seed} colors={member?.picture_generation_details?.colors} isDefault={member.picture_is_default} picture={member.picture} size="32" className="rounded-full w-[2rem] h-[2rem]" />
                                <span className="text-[1.1rem] items-center flex">
                                    {member.name}
                                </span>
                                {member.role === "admin" ?
                                <span className="material-symbols-outlined text-emerald-500 ml-auto pr-1">
                                    award_star
                                </span>
                                : null}
                            </Link>
                            )
                        })}

                        {isAdmin ? <Link to={`/bookclub/manage?id=${bookClubId}&tab=invite_codes`} className="w-full flex flex-row gap-3 items-center rounded-full border border-slate-700 hover:bg-slate-700/30 p-3 justify-center cursor-pointer">
                            <span className="text-[1.3rem] font-bold material-symbols-outlined">add</span>

                            <div className="text-[1.1rem]">
                                Invite Member
                            </div>
                        </Link> : null}

                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
}
