import { useState, useRef, useEffect } from "react";
import { LogOut } from "lucide-react";
import { useAuth } from "../context/AuthContext";
import ProfilePicture from "./ProfilePicture";

export default function DropdownMenuDemo() {
    const [open, setOpen] = useState(false);
    const { authenticated, logout } = useAuth();
    const dropdownRef = useRef(null);

    function handleOpen() {
        setOpen(!open);
    }

    function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    function goHome() {
        window.location = "/home";
    }

    return (
        <div ref={dropdownRef} className="ml-auto">
            <div>
                <div onClick={handleOpen}>
                    <ProfilePicture
                        variant={authenticated?.picture_generation_details?.variant}
                        seed={authenticated?.picture_generation_details?.seed}
                        colors={authenticated?.picture_generation_details?.colors}
                        isDefault={authenticated.picture_is_default}
                        picture={authenticated.picture}
                        size="40"
                        className="w-10 h-10 rounded-lg ml-auto cursor-pointer"
                    />
                </div>
            </div>
            {open && (
                <div className="absolute bg-white border border-zinc-200 right-[1.5rem] rounded-lg flex flex-col p-2 pt-2 top-[4.5rem] gap-2 w-[10rem]">
                    <span className="text-[1.05rem] w-full flex text-zinc-900 justify-center mb-[-2px]">Hi {authenticated.name.split(" ")[0]}!</span>

                    <div onClick={goHome} className="flex flex-row text-zinc-900 gap-[0.1rem] items-center cursor-pointer border border-zinc-300 hover:bg-zinc-100 px-2 p-1 rounded-md">
                        <span className="material-symbols-outlined mr-2 text-[1.4rem]">
                            flag
                        </span>
                        <span className="text-[1rem]">Home</span>
                    </div>
                    <div onClick={logout} className="flex flex-row text-zinc-900 gap-[0.1rem] items-center cursor-pointer border border-zinc-300 hover:bg-zinc-100 px-2 p-1 rounded-md">
                        <span className="material-symbols-outlined mr-2 text-[1.4rem]">
                            logout
                        </span>
                        <span className="text-[1rem]">Log out</span>
                    </div>
                </div>
            )}
        </div>
    );
}
