import { useState, useEffect, useRef } from "react";

import { useAuth } from "../context/AuthContext";
import { toast } from "sonner";

import BookActionButtons from "../components/BookActionButtons";

import SimilarBookSection from "../components/SimilarBooks/SimilarBookSection";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import StarRating from "../components/StarRating";

import ShowMoreReview from "../components/ReviewPage/ShowMoreReview";

import ProfilePicture from "../components/ProfilePicture";

import RankBadge from "../components/RankBadge";

import { Link } from "react-router-dom";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../components/ui/select";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../components/ui/dialog";

import { Button } from "../components/ui/button";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { NutIcon } from "lucide-react";

//type Checked = DropdownMenuCheckboxItemProps["checked"]

function BookView() {
	const { authenticated, login, logout, signup } = useAuth();

	//const bookIsbn = window.location.search.substring(6);
	const [bookIsbn, setBookIsbn] = useState(
		window.location.search.substring(6),
	);
	const [bookData, setBookData] = useState({});
	const [fullData, setFullData] = useState(null);

	const [showStatusBar, setShowStatusBar] = useState(true);
	const [showPanel, setShowPanel] = useState(false);

	//const [readList, setReadList] = useState([]);
	//const [bookmarkedList, setBookmarkedList] = useState([]);

	//console.log(bookIsbn);

	//const [readingListNameInput, setReadingListNameInput] = useState("");

	const readingListNameRef = useRef(null);
	const [showingCreateReadingListDialog, setShowingCreateReadingListDialog] =
		useState(false);

	//function handleReadingListNameInput(e) {
	//	setReadingListNameInput(e.target.value);
	//}

	const languageMap = {
		EN: "English",
		FR: "French",
		ES: "Spanish",
		DE: "German",
		IT: "Italian",
		PT: "Portuguese",
		"PT-BR": "Portuguese",
		NL: "Dutch",
		SV: "Swedish",
		DA: "Danish",
		NO: "Norwegian",
		FI: "Finnish",
		CS: "Czech",
		HU: "Hungarian",
		RU: "Russian",
		PL: "Polish",
		TR: "Turkish",
		AR: "Arabic",
		ZH: "Chinese",
		"ZH-TW": "TAIWANESE",
		JA: "Japanese",
		KO: "Korean",
		HE: "Hebrew",
	};

	function formatTimeAgo(datePosted) {
		if (!datePosted) {
			return "Unknown";
		}

		// datePosted is time.time() from python
		const timeAgo = new Date(datePosted * 1000);
		const now = new Date();

		const timeDifferenceInSeconds = Math.floor((now - timeAgo) / 1000);

		const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

		if (timeDifferenceInSeconds < 60) {
			return rtf.format(-timeDifferenceInSeconds, "second");
		} else if (timeDifferenceInSeconds < 3600) {
			return rtf.format(
				-Math.floor(timeDifferenceInSeconds / 60),
				"minute",
			);
		} else if (timeDifferenceInSeconds < 86400) {
			return rtf.format(
				-Math.floor(timeDifferenceInSeconds / 3600),
				"hour",
			);
		} else if (timeDifferenceInSeconds < 2592000) {
			return rtf.format(
				-Math.floor(timeDifferenceInSeconds / 86400),
				"day",
			);
		} else if (timeDifferenceInSeconds < 31536000) {
			return rtf.format(
				-Math.floor(timeDifferenceInSeconds / 2592000),
				"month",
			);
		} else {
			return rtf.format(
				-Math.floor(timeDifferenceInSeconds / 31536000),
				"year",
			);
		}
	}

	// if bookisbn isnt just a number, go back, and alert
	if (bookIsbn.match(/[a-z]/i)) {
		window.history.back();
		alert("Invalid book ISBN.");
	}

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	function getLanguageName(languageCode) {
		try {
			return languageMap[languageCode];
		} catch (error) {
			return languageCode;
		}
	}

	//  const key = "AIzaSyA_pi0QsRav4GtmaBPsyeaGT8VMpHlj5Jw"; //"AIzaSyB8S7AxXGMHUoHBqmFtXe0-jgnW2HHbLk8"; // "AIzaSyA_pi0QsRav4GtmaBPsyeaGT8VMpHlj5Jw";
	//	`https://www.googleapis.com/books/v1/volumes?q=isbn:${bookIsbn}&key=${key}`,

	useEffect(() => {
		async function getBookData() {
			await fetch(`https://nerded.io/api/getbook`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					isbn: bookIsbn,
					user_id: authenticated?.userId,
					session_token: authenticated?.sessionToken,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					try {
						// make authors look nice

						//let authors = data.items[0].volumeInfo.authors;

						//if (authors.length > 1) {
						//	authors = authors.join(", ");
						//}

						//data.items[0].volumeInfo.authors = authors;

						setBookData(data.item.data.volumeInfo);
						setFullData(data.item.data);

						// check if book has industry identifier
					} catch (error) {
						console.log(error);
						alert(
							"There was an error loading this specific book. Please try again later.",
						);
						window.history.back();
					}
				});
		}

		getBookData();
	}, [bookIsbn]);

	const [readList, setReadList] = useState([]);
	const [bookmarkedList, setBookmarkedList] = useState([]);
	const [booksReviewed, setBooksReviewed] = useState([]);
	const [lists, setLists] = useState([]);

	useEffect(() => {
		async function getUserData() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					//console.log(data);
					setReadList(data.books_read);
					setBookmarkedList(data.books_bookmarked);
					setBooksReviewed(data.books_reviewed);
					setLists(data.book_lists);
				});
		}

		if (authenticated.authenticated) {
			getUserData();
		}
	}, [authenticated.authenticated]);

	//const [bookReviews, setBookReviews] = useState([]);
	const [recentReviews, setRecentReviews] = useState([]);

	//const [refreshReviews, setRefreshReviews] = useState(false);

	function refreshReviewsList() {
		//getBookDataFromDB();
		getBookDataFromDB();
	}

	async function getBookDataFromDB() {
		await fetch(`https://nerded.io/api/public/get_book_reviews_db`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				isbn: bookIsbn,
				// only if user is authenticated
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data?.relog) {
					toast.error("Please login again!");
					logout();
				}

				if (data.requestStatus === "success") {
					const reviewsToDisplay = data.reviews.map((review) => {
						return {
							...review,
							// dates are in python time.time() format
							date_posted: new Date(
								review.timestamp * 1000,
							).toLocaleDateString("en-US", {
								year: "numeric",
								month: "long",
								day: "numeric",
							}),
						};
					});

					setRecentReviews(reviewsToDisplay);

					if (data.reviews === undefined) {
						setBooksReviewed([]);
					} else {
						// if reviews does not have isbn, add it
						let tempReviews = data.reviews;

						for (let i = 0; i < tempReviews.length; i++) {
							if (!tempReviews[i].hasOwnProperty("isbn")) {
								tempReviews[i]["isbn"] = bookIsbn;
							}
						}

						setBooksReviewed(tempReviews);
					}
				}
			});
	}

	function openPreview() {
		try {
			let previewLink = bookData.previewLink;

			// window.location.href = previewLink;

			window.open(previewLink, "_blank");

			//window.location.href = epubLink;
		} catch (error) {
			alert("This book does not have an preview link available.");
		}
	}

	function openStore() {
		try {
			let buyLink = bookData.infoLink; // `https://www.amazon.com/s?k=${bookIsbn}`; //
			// open in new tab
			window.open(buyLink, "_blank");
		} catch (error) {
			alert("This book does not have an online store link available.");
		}
	}

	useEffect(() => {
		// request backend book data for reviews, ratings, etc.
		getBookDataFromDB();
	}, []); // refreshReviews

	let bookCategories = bookData.categories;

	if (bookCategories) {
		// if there is .. fiction, add the thing then aother one for fiction
		for (let i = 0; i < bookCategories.length; i++) {
			if (bookCategories[i].includes("Fiction")) {
				// if is more than just fiction
				if (bookCategories[i] !== "Fiction") {
					// remove current fiction category
					let term = bookCategories[i].replace("Fiction", "");
					bookCategories.splice(i, 1);
					bookCategories.push("Fiction");
					bookCategories.push(term);
				}
			}
		}
	}

	async function deleteReview(review) {
		await fetch(
			"https://nerded.io/api/user_actions/delete_book_review",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					review_id: review.review_id,
					isbn: bookIsbn,
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
				}),
			},
		)
			.then((response) => response.json())
			.then((data) => {
				if (data?.relog) {
					toast.error("Please login again!");
					logout();
				}

				if (data.requestStatus === "success") {
					//console.log("deleted review");
					refreshReviewsList();
				}
			});
	}

	const [similarBooks, setSimilarBooks] = useState([]);

	useEffect(() => {
		async function getSimilarBooks() {
			try {
				// ${bookData.title}
				/*await fetch(
					`https://www.googleapis.com/books/v1/volumes?q=related to ${
						bookData.authors
					}'s ${
						bookData.title
					}&maxResults=40&orderBy=relevance&printType=books${
						bookData?.language !== undefined
							? `&langRestrict=${bookData?.language}`
							: ""
					}&key=${key}`,
				)*/
				// fetch https://nerded.io/api/getsimilar

				await fetch(`https://nerded.io/api/getsimilar`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated?.userId,
						session_token: authenticated?.sessionToken,
						isbn: bookIsbn,
						search_languages: authenticated.searchLanguages,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						let newData = data?.items;

						// if two books have the same title and author, remove with shortest description
						// for (let i = 0; i < newData?.length; i++) {
						// 	for (let j = 0; j < newData?.length; j++) {
						// 		try {
						// 			if (i !== j) {
						// 				if (
						// 					newData[i]?.volumeInfo?.title ===
						// 						newData[j].volumeInfo.title &&
						// 					newData[i].volumeInfo.authors[0] ===
						// 						newData[j].volumeInfo.authors[0]
						// 				) {
						// 					if (
						// 						newData[i]?.volumeInfo
						// 							?.description?.length >
						// 						newData[j].volumeInfo
						// 							.description.length
						// 					) {
						// 						newData.splice(j, 1);
						// 					} else {
						// 						newData.splice(i, 1);
						// 					}
						// 				}
						// 			}
						// 		} catch (error) {}
						// 	}
						// }

						// try {
						// 	for (let i = 0; i < newData.length; i++) {
						// 		if (
						// 			newData[
						// 				i
						// 			].volumeInfo?.industryIdentifiers[0]?.identifier.match(
						// 				/[a-z]/i,
						// 			)
						// 		) {
						// 			newData = newData.filter((book) => {
						// 				return (
						// 					book?.volumeInfo?.industryIdentifiers[0]?.identifier.match(
						// 						/[a-z]/i,
						// 					) === null
						// 				);
						// 			});
						// 		}

						// 		// if book.volumeInfo.imageLinks?.thumbnail === undefined, remove
						// 		if (
						// 			newData[i]?.volumeInfo?.imageLinks
						// 				?.thumbnail === undefined
						// 		) {
						// 			// filter out
						// 			newData = newData.filter((book) => {
						// 				return (
						// 					book?.volumeInfo?.imageLinks
						// 						?.thumbnail !== undefined
						// 				);
						// 			});
						// 		}

						// 		if (
						// 			newData[i]?.volumeInfo
						// 				?.industryIdentifiers[0]?.identifier ===
						// 			bookIsbn
						// 		) {
						// 			newData.splice(i, 1);
						// 		}

						// 		// remove book if they have the title in the name
						// 		else if (
						// 			newData[i]?.volumeInfo?.title?.includes(
						// 				bookData.title,
						// 			)
						// 		) {
						// 			newData.splice(i, 1);
						// 		}

						// 		// if not isbn
						// 		else if (
						// 			newData[i]?.volumeInfo
						// 				?.industryIdentifiers[0]?.type !==
						// 			"ISBN_13"
						// 		) {
						// 			newData.splice(i, 1);
						// 		}

						// 		// if "sampler" in the title
						// 		else if (
						// 			newData[i]?.volumeInfo?.title
						// 				?.toLowerCase()
						// 				?.includes("sample")
						// 		) {
						// 			newData.splice(i, 1);
						// 		}
						// 	}
						// } catch (error) {
						// 	console.log(error);
						// }

						// if there are any books with the same name
						// remove them

						for (let i = 0; i < newData.length; i++) {
							if (
								newData[i].data.volumeInfo.title ===
								bookData.title
							) {
								newData.splice(i, 1);
							}
						}

						// only 5
						if (newData?.length > 10) {
							newData?.splice(10, newData?.length - 10);
						}

						setSimilarBooks(newData);
					});
			} catch (error) {
				console.log(error);
			}
		}

		getSimilarBooks();
	}, []); // bookData

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	function copyReviewLink(id) {
		let reviewLink = `https://nerded.io/review?id=${id}`;

		navigator.clipboard.writeText(reviewLink);

		toast("Copied review link to clipboard!", {
			type: "success",
			duration: 2000,
			position: "bottom-right",
		});
	}

	function likeReview(review) {
		toast(`Liked review by ${review.user_name}!`, {
			type: "success",
			duration: 2000,
			position: "bottom-right",
		});
	}

	function reportReview(review) {
		toast(`Reported review by ${review.user_name}!`, {
			type: "success",
			duration: 2000,
			position: "bottom-right",
		});
	}

	function dateToWords(date) {
		try {
			if (date === undefined || date === "" || date === null) {
				return "Unknown";
			}
			// from 2013-01-29 to January 29, 2013
			const dateArray = date.split("-");
			let month = dateArray[1];
			let day = dateArray[2];
			const year = dateArray[0];

			const monthNames = [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			];

			// if 0 before another number, remove
			if (month[0] === "0") {
				month = month[1];
			}

			if (day[0] === "0") {
				day = day[1];
			}

			if (monthNames[month - 1] === undefined) {
				return "Unknown";
			}

			return `${monthNames[month - 1]} ${day}, ${year}`;
		} catch (error) {
			return "Unknown";
		}
	}

	function shareApi() {
		if (navigator.share) {
			navigator
				.share({
					title: bookData.title,
					text: bookData.description,
					url: window.location.href,
				})
				.then(() => console.log("Successful share"))
				.catch((error) => console.log("Error sharing", error));
		} else {
			toast.error("Web Share API not supported");
		}
	}

	function editBookInList(listId, action) {
		// Create a shallow copy of the lists array to avoid direct mutation
		let listsToSave = [...lists];

		// Find the specified list by its ID
		let specifiedList = listsToSave.find((list) => list.id === listId);

		if (!specifiedList) {
			console.error("List not found");
			return;
		}

		// Handle adding or removing the book
		if (action === "add") {
			specifiedList.books.push({
				title: bookData.title,
				authors: bookData.authors,
				cover: bookData.imageLinks.thumbnail,
				isbn: bookIsbn,
			});
		} else if (action === "remove") {
			specifiedList.books = specifiedList.books.filter(
				(book) => book.isbn !== bookIsbn,
			);
		}

		// Update the state with the modified lists array
		setLists(listsToSave);
	}

	function focusOnReadingListNameInput() {
		//readingListNameRef.current.focus();
	}

	function CheckBoxItemCustom(props) {
		const [checked, setChecked] = useState(props.checked);

		function handleCheck() {
			editBookInList(props.id, checked ? "remove" : "add");

			setChecked(!checked);
		}

		return (
			<div className="flex flex-row items-center p-1" onClick={handleCheck}>
				<label className="relative flex items-center px-3 py-2 rounded-full cursor-pointer">
					<input
						id={`${props.name}-checkbox`}
						type="checkbox"
						checked={props.checked}
						onChange={() => {}}
						className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-600 transition-all checked:border-gray-900 checked:bg-blue-600"
					/>
					<span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
						<svg
							xmlns="https://www.w3.org/2000/svg"
							className="h-3.5 w-3.5"
							viewBox="0 0 20 20"
							fill="currentColor"
							stroke="currentColor"
							strokeWidth="1"
						>
							<path
								fillRule="evenodd"
								d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
								clipRule="evenodd"
							/>
						</svg>
					</span>
				</label>
				<label className="text-[1rem] select-none pl-1 mb-[-2px] font-500 text-gray-300 cursor-pointer">
					{props.name}
				</label>
			</div>
		);
	}

	const [currentlyUpdatingBookLists, setCurrentlyUpdatingBookLists] =
		useState(false);

	async function saveListsAlterations() {
		let successes = 0;

		setCurrentlyUpdatingBookLists(true);

		for (let i = 0; i < lists.length; i++) {
			const list = lists[i];

			// Validate the books array
			for (const book of list.books) {
				if (!book.isbn || !book.title || !book.authors) {
					console.error(
						`Book in list ${list.id} is missing required fields`,
					);
					continue; // Skip this book if required fields are missing
				}
			}

            console.log(list.name)
            console.log(list);

			await fetch("https://nerded.io/api/books/save_book_list", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
					list_id: list.id,
					list_name: list.name,
					list_description: list.description,
					list_books: list.books,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					if (data.requestStatus === "success") {
						//toast.success(`List "${list.name}" saved successfully!`);
						successes++;
					} else {
						toast.error(`Failed to save list "${list.name}".`);
					}
				})
				.catch((error) => {
					console.error(`Error saving list "${list.name}":`, error);
					toast.error(
						`An error occurred while saving list "${list.name}".`,
					);
				});
		}

		// Check if all lists were saved successfully
		if (successes === lists.length) {
			toast.success("All lists saved successfully!");
			setCurrentlyUpdatingBookLists(false);
		} else {
			toast.error("Failed to save all lists.");
			setCurrentlyUpdatingBookLists(false);
		}
	}

	async function createBookList() {
		if (readingListNameRef.current.value === "") {
			toast.error("Please enter a name for the book list");
			return;
		}

		await fetch(`https://nerded.io/api/books/create_book_list`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				list_name: readingListNameRef.current.value,
				//list_description: readingListDescriptionRef.current.value,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.requestStatus === "success") {
					toast.success("Successfully created book list");

					setLists([
						...lists,
						{
							id: data.list_id,
							name: readingListNameRef.current.value,
							books: [],
						},
					]);

					//setReadingListNameInput("");
					readingListNameRef.current.value = "";
				} else {
					toast.error("Failed to create book list");

					if (data.error) {
						toast.error(data.error);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<div className="grid-new">
			<Navbar />

			{fullData ? (
				<div className="mt-[2rem] mb-[3rem] flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] bg-nord-900 border rounded-lg shadow-lg shadow-slate-900 border-slate-700 ml-auto mr-auto p-[1.4rem] gap-[1.5rem]">
					<div className="flex flex-col gap-4 items-center">
						<img
							className="w-[14rem] rounded-lg"
							src={bookData.imageLinks?.thumbnail
								.replace("http://", "https://")
								.replace("edge=curl", "edge=natural")}
						/>

						{/* <div className="w-full">
							<Select>
								<SelectTrigger className="border text-[1.1rem] text-slate-200 w-full">
									<SelectValue className="text-lg text-slate-100" placeholder="Edition" />
								</SelectTrigger>
								<SelectContent className="bg-slate-900 max-w-[99%]">
									<SelectGroup>
										<SelectLabel className="text-slate-100">
                                            Edition
                                        </SelectLabel>
										<SelectItem value="Collector's Edition" className="cursor-pointer truncate text-slate-100 w-[99.2%]">
											Collector's Edition
										</SelectItem>
									</SelectGroup>
								</SelectContent>
							</Select>
						</div> */}

						<div className="flex flex-row justify-between">
							<div
								onClick={() => window.history.back()}
								className="w-fit select-none cursor-pointer flex flex-row items-center gap-[10px] rounded-lg group px-3 py-2 bg-nord-800 text-slate-300"
							>
								<span className="material-symbols-outlined transform transition-transform duration-300 group-hover:rotate-45">
									arrow_back
								</span>
								Go Back
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-[5px] w-full">
						<div className="flex flex-row justify-between items-center w-full gap-[10px]">
							<h1 className="text-xl font-semibold">
								{bookData.title ? bookData.title : "Loading..."}
							</h1>

							<div className="ml-2 mr-[-12px]">
								<BookActionButtons
									isbn={bookIsbn}
									bookData={bookData}
									showSendButton={true}
									readList={readList}
									bookmarkedList={bookmarkedList}
									booksReviewed={booksReviewed}
									refreshReviewsList={refreshReviewsList}
								/>
							</div>
						</div>

						<div className="flex flex-row gap-3 items-center">
							<span className="material-symbols-outlined text-emerald-500">
								draw
							</span>

							<div className="flex flex-row gap-1 flex-wrap items-center text-lg text-white line-clamp-1">
								{bookData.authors.length === 1 ? (
									bookData.authors[0] !== "Unknown" ? (
										<Link
											to={`/search?q=${bookData.authors[0]} [author]`}
											className="cursor-pointer hover:underline text-[1.2rem] items-center flex w-fit text-slate-200 line-clamp-1"
										>
											{bookData.authors[0]}
										</Link>
									) : (
										<div className="text-[1.2em] w-fit h-[1.3rem] text-slate-200 flex items-center line-clamp-1">
											{bookData.authors[0]}
										</div>
									)
								) : (
									bookData.authors.map(
										(authorName, index) => (
											<div className="flex flex-row items-center gap-0 text-[1.2rem] text-slate-200 line-clamp-1">
												<Link
													to={`/search?q=${authorName}`}
													className="text-[1.2rem] text-slate-200 transition cursor-pointer w-fit hover:underline"
												>
													{authorName}
												</Link>
												{index ===
												bookData.authors.length - 1
													? ""
													: ","}
											</div>
										),
									)
								)}
							</div>
						</div>

						{bookData.publisher ? (
							<div className="flex flex-row gap-3 items-center mt-1 mb-[5px]">
								<span className="material-symbols-outlined text-emerald-500 mt-[0px]">
									domain
								</span>

								<span className="text-[1.2em] text-slate-200 mb-[-2px]">
									{bookData.publisher}
								</span>
							</div>
						) : null}

						<div className="mt-[-10px]">
							<span className="text-slate-400">
								<ShowMoreReview
									text={bookData.description}
									chars={1000}
								/>
							</span>
						</div>

						<div className="flex flex-row flex-wrap gap-[10px] mt-[0.8rem]">
							{bookCategories?.map((category, index) => {
								return (
									<span
										key={index}
										// onClick={() =>
										// 	(window.location = `/bookshelf/${category.toLowerCase()}`)
										// }
										className="text-emerald-500 items-center gap-[7px] flex flex-row bg-emerald-950 bg-opacity-50 p-2 px-3 rounded"
									>
										<span className="material-symbols-outlined">
											label
										</span>
										{capitalizeFirstLetter(
											category.toLowerCase(),
										)}
									</span>
								);
							})}

							<div className="flex flex-row gap-[10px] items-center w-fit cursor-default text-emerald-500 bg-emerald-950 bg-opacity-50 rounded-md p-2 pl-[0.6rem] pr-3">
								<span className="material-symbols-outlined">
									flag
								</span>
								<span>
									{getLanguageName(
										bookData?.language?.toUpperCase(),
									)}
								</span>
							</div>

							<div className="flex flex-row gap-[10px] items-center w-fit cursor-default text-emerald-500 bg-emerald-950 bg-opacity-50 rounded-md p-2 pl-[0.6rem] pr-3">
								<span className="material-symbols-outlined">
									date_range
								</span>
								<span>
									{dateToWords(bookData?.publishedDate)}
								</span>
							</div>

							{bookData.maturityRating === "MATURE" ? (
								<div className="flex flex-row gap-[10px] items-center w-fit cursor-default text-emerald-500 bg-emerald-950 bg-opacity-50 rounded-md p-2 pl-[0.6rem] pr-3">
									<span className="material-symbols-outlined">
										warning
									</span>
									<span>Mature</span>
								</div>
							) : null}
						</div>

						<div className="flex flex-row mt-[1.1rem] gap-[1rem] w-full justify-start items-center flex-wrap">
							{/*<div className="cursor-pointer flex flex-row w-fit bg-nord-800 hover:bg-nord-700/30 rounded-lg p-2 px-4 gap-[10px]">
                            <span className="material-symbols-outlined text-slate-400 transition">
                                flag
                            </span>
                            <span className="text-slate-200">
                                Flag Duplicate
                            </span>
                        </div>

                        <div className="flex flex-row gap-[10px] items-center w-fit cursor-pointer transition hover:bg-nord-700/30 bg-nord-800 rounded-md p-2 px-4">
                            <span className="material-symbols-outlined text-slate-400">
                                avg_pace
                            </span>
                            <span className="text-slate-200">
                                Take Quiz on this Book
                            </span>
                        </div>*/}
							{authenticated.authenticated ? (
								<DropdownMenu
									open={isDropdownOpen}
									onOpenChange={(open) =>
										setIsDropdownOpen(open)
									}
								>
									<DropdownMenuTrigger
										asChild
										className="w-full"
									>
										<Button
											variant="outline"
											className="w-[11rem] flex outline-none border-none justify-center gap-2 text-[0.9rem] items-center rounded-md hover:bg-nord-700/30 font-normal text-slate-200 bg-nord-800 select-none"
										>
											<span className="material-symbols-outlined text-slate-400">
												playlist_add
											</span>
											{bookData.title
												? `Add to List (${
														lists.filter((list) =>
															list.books.some(
																(book) =>
																	book.isbn ===
																	bookIsbn,
															),
														).length
												  }/${lists.length})`
												: "Loading..."}
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent className="border m-1 bg-slate-900 border-slate-700 outline-none p-0 w-[10rem] md:w-[10rem] lg:w-[10rem] xl:w-[10rem] 2xl:w-[10rem] flex flex-col">
										<div className="flex flex-col justify-center mt-[1px]">
                                            <div className="flex flex-col overflow-y-scroll max-h-[30vh]">
											{lists.map((list, index) => {
												return (
													<CheckBoxItemCustom
														key={index}
														name={list.name}
														checked={list.books.some(
															(book) =>
																book.isbn ===
																bookIsbn,
														)}
														id={list.id}
													/>
												);
											})}
                                            </div>

											<div className="w-full mx-auto pt-1 border-t border-t-nord-700/50">
												<div
													onClick={() => {
														setShowingCreateReadingListDialog(
															true,
														);
														// Ensure dropdown stays open
														setIsDropdownOpen(
															false,
														);
													}}
													className="w-full mx-auto mb-[-5px] mt-[-5px] px-4 transition py-3 cursor-pointer text-slate-300 hover:text-sky-500 hover:bg-sky-950/50"
												>
													<div className="flex flex-row gap-2 items-center w-full justify-center">
														<span className="material-symbols-outlined ml-[-5px]">
															add
														</span>
														<span className="">
															New List
														</span>
													</div>
												</div>
											</div>

											<div
												onClick={saveListsAlterations}
												className="flex flex-row justify-center items-center gap-[10px] cursor-pointer bg-nord-800 hover:bg-nord-700/20 p-2 w-[calc(100% + 4px)] m-[-4px] mt-[6px] pb-3 border-t border-t-slate-700 rounded-b-md"
											>
												{currentlyUpdatingBookLists ? (
													<span
														className={`material-symbols-outlined ${
															currentlyUpdatingBookLists
																? "animate-spin"
																: ""
														} text-slate-300 text-[1.3rem]`}
													>
														cached
													</span>
												) : null}
												<span>Save</span>
											</div>
										</div>
									</DropdownMenuContent>
								</DropdownMenu>
							) : (
								<Button
									variant="outline"
									className="w-fit flex justify-center gap-2 text-[0.9rem] items-center rounded-md hover:bg-nord-700/30 font-normal text-slate-200 bg-nord-800 select-none"
									onClick={login}
								>
									<span className="material-symbols-outlined text-slate-400">
										playlist_add
									</span>
									{bookData.title
										? `Add to List`
										: "Loading..."}
								</Button>
							)}

							{navigator.share ? (
								<div
									onClick={shareApi}
									className="flex flex-row gap-[10px] items-center w-fit cursor-pointer transition text-slate-300 hover:bg-nord-700/30 bg-nord-800 rounded-md p-2 px-4"
								>
									<span className="material-symbols-outlined text-slate-400">
										open_in_browser
									</span>
									<span className="text-slate-200 select-none">
										Share
									</span>
								</div>
							) : null}

							<div
								onClick={openPreview}
								className="flex flex-row gap-[10px] items-center w-fit select-none cursor-pointer transition text-slate-300 hover:bg-nord-700/30 bg-nord-800 rounded-md p-2 px-4"
							>
								<span className="material-symbols-outlined text-slate-400">
									format_quote
								</span>
								<span className="text-slate-200">
									Preview Book
								</span>
							</div>

							<div
								onClick={openStore}
								className="flex flex-row gap-[7px] items-center w-fit select-none cursor-pointer transition text-slate-300 hover:bg-nord-700/30 bg-nord-800 rounded-md p-2 pr-4 pl-3"
							>
								<span className="material-symbols-outlined text-slate-400">
									attach_money
								</span>
								<span className="text-slate-200">
									View Store{" "}
									{fullData.saleInfo?.listPrice?.amount}{" "}
									{fullData.saleInfo?.listPrice?.currencyCode}
								</span>
							</div>

							<div className="flex flex-row gap-2 items-center bg-nord-800 transition hover:bg-nord-700/30 cursor-pointer rounded-md p-2 px-3 text-slate-200 select-none">
								<span className="material-symbols-outlined text-slate-400">
									report
								</span>
								<span>Report Problem {/* as Collection*/}</span>
							</div>

							{/*<div className="flex flex-row gap-[10px] items-center w-fit cursor-default text-slate-300 bg-nord-800 rounded-md p-2 pr-4 pl-3">
                            <span className="material-symbols-outlined">
                                translate
                            </span>
                            <span>
                                Preview {fullData?.accessInfo?.country}
                            </span>
                        </div>*/}
						</div>

						<div className="flex flex-row gap-[10px] items-center mt-[1.5rem]">
							<div className="flex flex-col gap-[5px]">
								<p className="text-slate-300 text-[1.1rem]">
									{recentReviews.length > 0
										? "Top Reviews"
										: "No Reviews Yet!"}
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1">
							{recentReviews.map((review, index) => {
								const timeAgoString = formatTimeAgo(
									review.timestamp,
								);
								const isReviewAuthor =
									review.user_id === authenticated.userId;

								return (
									<div
										key={index}
										className="flex flex-col w-full mt-[1.5rem] gap-[10px] border-2 border-slate-800 rounded-xl p-4 px-6"
									>
										<div className="flex flex-row items-center gap-[10px]">
											<div className="flex flex-row items-center gap-[10px] text-slate-600 text-[1.1rem]">
												<Link
													className="text-slate-200 hover:underline flex flex-row items-center gap-[10px]"
													to={`/profile?id=${review.user_id}`}
												>
													<ProfilePicture
														variant={
															review
																.picture_generation_details
																?.variant
														}
														seed={
															review
																.picture_generation_details
																?.seed
														}
														colors={
															review
																.picture_generation_details
																?.colors
														}
														isDefault={
															review.picture_is_default
														}
														picture={
															review.user_picture
														}
														size="36"
														className="w-[36px] h-[36px] rounded-full"
													/>
													<span className="text-[1.2rem]">
														{review.user_name}
													</span>
												</Link>
												{review.user_rank ? (
													<div className="mx-[1px]">
														<RankBadge
															rank={
																review.user_rank
															}
														/>
													</div>
												) : null}
												·
												<span className="text-slate-400">
													{capitalizeFirstLetter(
														timeAgoString,
													)}
												</span>
											</div>

											<div className="flex select-none flex-row gap-[8px] ml-auto mt-[-5px] mr-[-10px]">
												{/* <div
													onClick={() =>
														likeReview(review)
													}
													className="flex items-center gap-[7px] -mr-[5px] justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
												>
													<span className="material-symbols-outlined ml-[-1px] mr-[1px] mt-[1px]">
														favorite
													</span>
													<span className="flex items-center align-middle mt-[1px] mr-[2px]">
														1
													</span>
												</div> */}

												<Link
													to={`/review?isbn=${bookIsbn}&id=${review.review_id}`}
													className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
												>
													<span className="material-symbols-outlined">
														expand_all
													</span>
												</Link>

												<div
													onClick={() =>
														copyReviewLink(
															review.review_id,
														)
													}
													className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
												>
													<span className="material-symbols-outlined ml-[-2px] mr-[2px]">
														link
													</span>
												</div>

												{isReviewAuthor ? (
													<div
														className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
														onClick={() =>
															deleteReview(review)
														}
													>
														<span className="material-symbols-outlined">
															delete
														</span>
													</div>
												) : (
													<div
														onClick={() =>
															reportReview(review)
														}
														className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
													>
														<span className="material-symbols-outlined">
															flag
														</span>
													</div>
												)}
											</div>
										</div>

										<div className="flex flex-col gap-[10px] mt-[3px] items-start">
											{review.review !== "" ? (
												<div className="text-slate-100 bg-nord-800/50 border-[1.5px] mb-1 border-slate-700 p-3 pt-0 rounded-lg text-[1.2rem] mt-[-1px] w-full">
													<ShowMoreReview
														color="text-slate-300"
														text={review.review}
													/>
												</div>
											) : null}

											<div className="flex items-center bg-nord-800/50 px-2 py-2 rounded-lg w-fit">
												<StarRating
													type="yellow-non-viewer"
													rating={review.rating}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div
						className={`${
							showingCreateReadingListDialog ? "flex" : "hidden"
						} fixed z-[100] inset-0 bg-black bg-opacity-80 top-0 left-0 w-full h-full items-center justify-center pointer-events-auto cursor-pointer overflow-hidden`}
						onClick={() => {
							setShowingCreateReadingListDialog(false);
							setIsDropdownOpen(false); // Close dropdown when modal is closed
						}}
					>
						<div
							className="bg-slate-950 border border-nord-700/40 max-w-[90vw] pointer-events-auto p-4 rounded-lg flex flex-col gap-[1rem]"
							onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
						>
							<div className="w-[32rem] max-w-full rounded-lg">
								<div className="text-[1.2rem] font-semibold mb-2">
									<div>Create Reading List</div>
								</div>

								<div className="pt-[0.4rem] pb-3 flex flex-col">
									<input
										type="text"
										placeholder="List Name"
										ref={readingListNameRef}
										className="w-full border border-slate-700 focus:z-10 bg-slate-950/30 outline-none focus:border-slate-600 p-3 rounded-lg"
									/>
								</div>

								<div>
									<div
										onClick={() => {
											createBookList();
											setShowingCreateReadingListDialog(
												false,
											);
											setIsDropdownOpen(false); // Close dropdown after creating list
										}}
										className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-nord-700/30"
									>
										Save
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="mx-auto text-slate-300 text-[1.2rem] font-semibold py-[5rem] w-full flex justify-center">
					Loading...
				</div>
			)}

			{fullData ? (
				<div className="flex flex-col w-fit gap-[1rem] ml-auto mr-auto text-slate-300 mb-[4rem]">
					<span className="text-[1.2rem] font-semibold mb-[0.5rem]">
						Books Similar to {bookData.title}
					</span>

					<SimilarBookSection similarBooks={similarBooks} />
				</div>
			) : null}

			<Footer />
		</div>
	);
}

export default BookView;
