import Cookies from 'js-cookie';

export default function HelperClearAuth() {
    let emptyUserData = {
        authenticated: false,
        userId: "",
        sessionToken: "",
        name: "",
        email: "",
        picture: "",
        language: "en",
        searchLanguages: [],
        googleTokenInfo: {
            googleToken: "",
            googleTokenExpires: "",
            googleTokenExpiresDate: "",
            googleRefreshToken: "",
        }
    };

    // Replace the content of the cookie with empty user data
    Cookies.set("authenticated", JSON.stringify(emptyUserData)); // or set a different expiration if needed
}
