import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { Link } from "react-router-dom";

import ProfilePicture from "../../components/ProfilePicture";
import RankBadge from "../../components/RankBadge";

export default function Admin() {
    const { authenticated, login, signup, logout } = useAuth();

    if (!authenticated.authenticated) {
        login();
        return (
            <div>
                <Navbar />

                <div className="text-center w-full my-[2rem]">
                    Not logged in
                </div>

                <Footer />
            </div>
        )
    }

    if (authenticated.rank !== "admin") {
        return (
            <div>
                <Navbar />

                <div className="text-center w-full my-[2rem]">
                    Not an admin
                </div>

                <Footer />
            </div>
        )
    }

	return (
		<div>
			<Navbar />

			<div>
				<h1 className="text-4xl text-slate-300 mb-[2rem] mt-[2rem] font-bold mx-auto w-fit">
					Admin Panel
				</h1>

                <div className="flex flex-col gap-4 w-[75vw] mx-auto items-center p-3">
                    <div className="flex flex-col gap-2 w-full overflow-y-scroll">
                        
                    </div>
                </div>
			</div>

			<Footer />
		</div>
	);
}
