import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { toast } from "sonner";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

export default function PracticeVocab() {
	const { authenticated, login, logout, signup } = useAuth();
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [progress, setProgress] = useState(0); // Starts from 0
	const [hearts, setHearts] = useState(3); // Starts with 3 hearts
	const [shake, setShake] = useState(false); // For the shake animation
	const [totalQuestionsArr, setTotalQuestionsArr] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(null); // Initial question
	const [totalQuestions, setTotalQuestions] = useState(0);
    const [restart, setRestart] = useState(false);

	const questions = {
		easy: [
            { question: "What does the word 'dusk' mean?", options: ["Morning", "Twilight", "Midday", "Night"], answer: "Twilight", type: "multiple" },
            { question: "What does the word 'vibrant' mean?", options: ["Dull", "Full of life", "Quiet", "Faint"], answer: "Full of life", type: "multiple" },
            { question: "What does the word 'fragile' mean?", options: ["Strong", "Breakable", "Flexible", "Sturdy"], answer: "Breakable", type: "multiple" },
            { question: "What does the word 'generous' mean?", options: ["Selfish", "Kind and giving", "Stingy", "Indifferent"], answer: "Kind and giving", type: "multiple" },
            { question: "What does the word 'clever' mean?", options: ["Foolish", "Smart", "Confused", "Weak"], answer: "Smart", type: "multiple" },
            { question: "What does the word 'veneer' mean?", options: ["Surface layer", "Core", "Interior", "Solid"], answer: "Surface layer", type: "multiple" },
            { question: "What does the word 'slippery' mean?", options: ["Smooth and difficult to hold", "Sticky", "Coarse", "Solid"], answer: "Smooth and difficult to hold", type: "multiple" },
            { question: "What does the word 'delicate' mean?", options: ["Fragile", "Rough", "Tough", "Strong"], answer: "Fragile", type: "multiple" },
            { question: "What does the word 'timid' mean?", options: ["Shy", "Bold", "Confident", "Aggressive"], answer: "Shy", type: "multiple" },
            { question: "What does the word 'eager' mean?", options: ["Uninterested", "Excited", "Reluctant", "Lazy"], answer: "Excited", type: "multiple" },
            { question: "What does the word 'bizarre' mean?", options: ["Normal", "Weird", "Ordinary", "Typical"], answer: "Weird", type: "multiple" },
            { question: "What does the word 'ample' mean?", options: ["Insufficient", "Plenty", "Scarce", "Lacking"], answer: "Plenty", type: "multiple" },
            { question: "What does the word 'shimmer' mean?", options: ["Glow faintly", "Dull", "Absorb", "Disappear"], answer: "Glow faintly", type: "multiple" },
            { question: "What does the word 'tame' mean?", options: ["Wild", "Domesticated", "Ferocious", "Reckless"], answer: "Domesticated", type: "multiple" },
            { question: "What does the word 'dim' mean?", options: ["Bright", "Faint", "Strong", "Vivid"], answer: "Faint", type: "multiple" },
        ],
		medium: [
            { question: "What does the word 'dogmatic' mean?", options: ["Open to change", "Stubbornly opinionated", "Indifferent", "Hesitant"], answer: "Stubbornly opinionated", type: "multiple" },
            { question: "What does the word 'pragmatic' mean?", options: ["Theoretical", "Practical and realistic", "Idealistic", "Emotional"], answer: "Practical and realistic", type: "multiple" },
            { question: "The word ___ refers to something unusual or quirky.", options: ["common", "eccentric", "typical", "timid"], answer: "eccentric", type: "fill-in-the-blank" },
            { question: "The term 'eclectic' means drawing from ___ sources.", options: ["limited", "narrow", "diverse", "conservative"], answer: "diverse", type: "fill-in-the-blank" },
            { question: "What does the word 'prolific' mean?", options: ["Productive", "Lazy", "Rare", "Inactive"], answer: "Productive", type: "multiple" },
            { question: "What does the word 'subtle' mean?", options: ["Obvious", "Not obvious", "Clear", "Direct"], answer: "Not obvious", type: "multiple" },
            { question: "The word ___ means something mysterious or secret.", options: ["conspicuous", "cryptic", "blatant", "open"], answer: "cryptic", type: "fill-in-the-blank" },
            { question: "What does the word 'obscure' mean?", options: ["Hidden", "Clear", "Popular", "Famous"], answer: "Hidden", type: "multiple" },
            { question: "What does the word 'tedious' mean?", options: ["Boring", "Exciting", "Fast-paced", "Engaging"], answer: "Boring", type: "multiple" },
            { question: "The word 'eloquent' refers to ___ speech.", options: ["awkward", "fluent", "unskilled", "clumsy"], answer: "fluent", type: "fill-in-the-blank" },
            { question: "What does the word 'volatile' mean?", options: ["Stable", "Unpredictable", "Calm", "Predictable"], answer: "Unpredictable", type: "multiple" },
            { question: "What does the word 'antithesis' mean?", options: ["Opposite", "Similarity", "Same", "Equivalent"], answer: "Opposite", type: "multiple" },
            { question: "What does the word 'ambiguous' mean?", options: ["Clear", "Uncertain", "Definite", "Precise"], answer: "Uncertain", type: "multiple" },
            { question: "The word ___ means something widely accepted.", options: ["controversial", "orthodox", "rebellious", "deviant"], answer: "orthodox", type: "fill-in-the-blank" },
            { question: "What does the word 'intrinsic' mean?", options: ["Inherent", "External", "Superficial", "Acquired"], answer: "Inherent", type: "multiple" },
            { question: "What does the word 'peripheral' mean?", options: ["Central", "Outer", "Main", "Crucial"], answer: "Outer", type: "multiple" },
            { question: "What does the word 'lucid' mean?", options: ["Confusing", "Clear", "Ambiguous", "Unclear"], answer: "Clear", type: "multiple" },
            { question: "What does the word 'obsolete' mean?", options: ["Current", "Outdated", "Modern", "New"], answer: "Outdated", type: "multiple" },
            { question: "The term 'juxtapose' means to place things ___ for comparison.", options: ["together", "far apart", "above", "under"], answer: "together", type: "fill-in-the-blank" },
            { question: "What does the word 'insipid' mean?", options: ["Flavorless", "Tasty", "Bold", "Rich"], answer: "Flavorless", type: "multiple" },
        ],
        hard: [
            { question: "What does the word 'auriferous' mean?", options: ["Containing gold", "Fearful of light", "Airy", "Foul-smelling"], answer: "Containing gold", type: "multiple" },
            { question: "The word 'zeitgeist' refers to the ___ of the times.", options: ["fashion", "spirit", "traditions", "laws"], answer: "spirit", type: "fill-in-the-blank" },
            { question: "What does the word 'ersatz' mean?", options: ["Genuine", "Inferior substitute", "Authentic", "New"], answer: "Inferior substitute", type: "multiple" },
            { question: "What does the word 'quixotic' mean?", options: ["Realistic", "Impractically idealistic", "Rational", "Logical"], answer: "Impractically idealistic", type: "multiple" },
            { question: "What does the word 'ephemeral' mean?", options: ["Lasting a long time", "Short-lived", "Eternal", "Endless"], answer: "Short-lived", type: "multiple" },
            { question: "What does the word 'obfuscate' mean?", options: ["Clarify", "Make unclear", "Simplify", "Explain"], answer: "Make unclear", type: "multiple" },
            { question: "What does the word 'lugubrious' mean?", options: ["Cheerful", "Excessively mournful", "Energetic", "Optimistic"], answer: "Excessively mournful", type: "multiple" },
            { question: "What does the word 'pellucid' mean?", options: ["Cloudy", "Transparently clear", "Opaque", "Thick"], answer: "Transparently clear", type: "multiple" },
            { question: "What does the word 'perfidious' mean?", options: ["Loyal", "Deceitful and untrustworthy", "Honorable", "Sincere"], answer: "Deceitful and untrustworthy", type: "multiple" },
            { question: "What does the word 'ameliorate' mean?", options: ["Worsen", "Improve", "Destroy", "Complicate"], answer: "Improve", type: "multiple" },
            { question: "What does the word 'insouciant' mean?", options: ["Worried", "Carefree", "Angry", "Tense"], answer: "Carefree", type: "multiple" },
            { question: "What does the word 'obdurate' mean?", options: ["Flexible", "Stubborn", "Compassionate", "Soft-hearted"], answer: "Stubborn", type: "multiple" },
            { question: "What does the word 'recalcitrant' mean?", options: ["Obedient", "Resistant to authority", "Compliant", "Submissive"], answer: "Resistant to authority", type: "multiple" },
            { question: "What does the word 'spurious' mean?", options: ["Genuine", "False or fake", "Authentic", "Real"], answer: "False or fake", type: "multiple" },
            { question: "The word 'proclivity' refers to a ___ tendency.", options: ["natural", "forced", "artificial", "unnatural"], answer: "natural", type: "fill-in-the-blank" },
            { question: "What does the word 'mendacious' mean?", options: ["Honest", "Lying", "Truthful", "Trustworthy"], answer: "Lying", type: "multiple" },
            { question: "What does the word 'magnanimous' mean?", options: ["Greedy", "Generous", "Stingy", "Selfish"], answer: "Generous", type: "multiple" },
            { question: "What does the word 'intransigent' mean?", options: ["Compliant", "Uncompromising", "Flexible", "Yielding"], answer: "Uncompromising", type: "multiple" },
            { question: "What does the word 'laconic' mean?", options: ["Wordy", "Concise", "Verbose", "Chatty"], answer: "Concise", type: "multiple" },
            { question: "What does the word 'vociferous' mean?", options: ["Silent", "Loud and noisy", "Soft-spoken", "Reserved"], answer: "Loud and noisy", type: "multiple" },
            { question: "What does the word 'torpid' mean?", options: ["Energetic", "Sluggish", "Active", "Lively"], answer: "Sluggish", type: "multiple" },
            { question: "What does the word 'truculent' mean?", options: ["Friendly", "Aggressively defiant", "Passive", "Submissive"], answer: "Aggressively defiant", type: "multiple" },
            { question: "What does the word 'pusillanimous' mean?", options: ["Courageous", "Cowardly", "Brave", "Bold"], answer: "Cowardly", type: "multiple" },
            { question: "What does the word 'sycophant' mean?", options: ["Critic", "A person who flatters for personal gain", "Leader", "Independent thinker"], answer: "A person who flatters for personal gain", type: "multiple" },
            { question: "What does the word 'perfidy' mean?", options: ["Loyalty", "Betrayal", "Trustworthiness", "Fidelity"], answer: "Betrayal", type: "multiple" },
            { question: "The word 'aplomb' refers to ___ self-confidence.", options: ["timid", "unshakeable", "insecure", "fearful"], answer: "unshakeable", type: "fill-in-the-blank" },
            { question: "What does the word 'nebulous' mean?", options: ["Clear", "Vague", "Precise", "Specific"], answer: "Vague", type: "multiple" },
            { question: "What does the word 'equivocal' mean?", options: ["Certain", "Ambiguous", "Clear", "Obvious"], answer: "Ambiguous", type: "multiple" },
            { question: "What does the word 'esoteric' mean?", options: ["Widely known", "Understood by a few", "Simple", "Popular"], answer: "Understood by a few", type: "multiple" },
            { question: "What does the word 'ignominious' mean?", options: ["Honorable", "Shameful", "Proud", "Dignified"], answer: "Shameful", type: "multiple" },
            { question: "What does the word 'idiosyncratic' mean?", options: ["Normal", "Peculiar", "Commonplace", "Boring"], answer: "Peculiar", type: "multiple" },
            { question: "The word 'pell-mell' refers to doing something in a ___ manner.", options: ["calm", "disorderly", "peaceful", "orderly"], answer: "disorderly", type: "fill-in-the-blank" },
            { question: "What does the word 'didactic' mean?", options: ["Instructive", "Entertaining", "Confusing", "Amusing"], answer: "Instructive", type: "multiple" },
            { question: "What does the word 'misanthropic' mean?", options: ["Loving humanity", "Hating humanity", "Generous", "Optimistic"], answer: "Hating humanity", type: "multiple" },
            { question: "What does the word 'penurious' mean?", options: ["Wealthy", "Extremely poor", "Generous", "Luxurious"], answer: "Extremely poor", type: "multiple" },
            { question: "What does the word 'redolent' mean?", options: ["Scentless", "Fragrant", "Foul-smelling", "Neutral"], answer: "Fragrant", type: "multiple" },
            { question: "What does the word 'ostensible' mean?", options: ["Real", "Apparent but not necessarily real", "Fake", "Proven"], answer: "Apparent but not necessarily real", type: "multiple" },
            { question: "What does the word 'tenebrous' mean?", options: ["Bright", "Dark and shadowy", "Colorful", "Radiant"], answer: "Dark and shadowy", type: "multiple" },
        ],
	};

    // useEffect to initialize questions and shuffle them
	useEffect(() => {
		// Combine questions into one array
		let tempQuestionsArr = [];

		for (let i = 0; i < questions.easy.length; i++) {
			tempQuestionsArr.push([questions.easy[i], "easy"]);
		}

		for (let i = 0; i < questions.medium.length; i++) {
			tempQuestionsArr.push([questions.medium[i], "medium"]);
		}

		for (let i = 0; i < questions.hard.length; i++) {
			tempQuestionsArr.push([questions.hard[i], "hard"]);
		}

		// Shuffle questions and limit to 8
		tempQuestionsArr = tempQuestionsArr.sort(() => Math.random() - 0.5).slice(0, 8);

		setTotalQuestionsArr(tempQuestionsArr); // Set shuffled questions
		setTotalQuestions(tempQuestionsArr.length); // Set total number of questions
		setCurrentQuestion(tempQuestionsArr[0]); // Set initial question
	}, [restart]);

	// Function to handle answers
	function handleAnswer(selectedOption) {
		if (selectedOption === currentQuestion[0].answer) {
			toast.success("Correct!");
			setProgress((prev) => Math.min(prev + 100 / totalQuestions, 100));

			// Move to the next question
			if (currentQuestionIndex < totalQuestions - 1) {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
				setCurrentQuestion(totalQuestionsArr[currentQuestionIndex + 1]);
			}
		} else {
			toast.error("Wrong answer. Try again.");
			setHearts((prev) => Math.max(prev - 1, 0)); // Decrease hearts but don't go below 0
			setShake(true); // Trigger the shake animation
			setTimeout(() => setShake(false), 500); // Stop the shake animation after 500ms

			if (hearts === 1) {
				toast.error("Game over. You've lost all your hearts.");
			}
		}
	}

	// Option button component
	function OptionButton({ text }) {
		return (
			<div>
				<button
					className="w-full bg-nord-900 border border-slate-600 text-slate-50 rounded-lg p-3 hover:bg-nord-800"
					onClick={() => handleAnswer(text)}
				>
					{text}
				</button>
			</div>
		);
	}

	// Function to determine the difficulty label color
	function getDifficultyLabel(difficulty) {
		if (difficulty === "easy") return "bg-blue-900 text-white";
		if (difficulty === "medium") return "bg-pink-900 text-white";
		if (difficulty === "hard") return "bg-red-900 text-white";
	}

	return (
		<div>
			<Navbar />

			<div className="container flex flex-row items-center justify-center gap-[1.5rem] mx-auto pl-6 pr-0 py-8">
				{/* Progress Bar */}
				<div className="w-[51rem] h-[1.5rem] rounded-full bg-nord-800">
					<div
						className="bg-sky-700 rounded-full h-full pt-[4px] transition-width duration-500 ease-out"
						style={{ width: `${progress}%` }}
					>
						<div
							className="bg-sky-400 opacity-15 rounded-full h-[5px]"
							style={{
								width: "calc(100% - 24px)", // Full width minus margins
								marginLeft: "12px", // Equal margin on the left
								marginRight: "12px", // Equal margin on the right
							}}
						></div>
					</div>
				</div>

				{/* Heart box with shake animation */}
				<div
					className={`w-[5rem] bg-red-950/50 mr-[-5rem] p-3 pr-[14px] rounded-lg gap-3 text-red-400 flex flex-row items-center justify-center ${
						shake ? "animate-shake" : ""
					}`}
				>
					<div className="text-[1.5rem] flex items-center">
						<ion-icon name="heart"></ion-icon>
					</div>
					<span className="font-semibold text-[1.2rem]">{hearts}</span>
				</div>
			</div>

			{currentQuestion && (
				<div className="pb-[3rem]">
					{/* Difficulty label */}
					<div className="flex flex-row items-center justify-center gap-4 mb-8">
						<span className="select-none font-semibold text-[1.3rem] w-fit flex justify-center">
							{currentQuestion[0].question} {/* Display current question */}
						</span>

						{/* Show difficulty */}
						<span className={`px-4 py-1 rounded-lg text-sm font-semibold ${getDifficultyLabel(currentQuestion[1])}`}>
							{currentQuestion[1].charAt(0).toUpperCase() + currentQuestion[1].slice(1)}
						</span>
					</div>

					<div className="flex flex-col gap-4 w-[40vw] mx-auto">
						{currentQuestion[0].options.map((option, index) => (
							<OptionButton key={index} text={option} />
						))}
					</div>

                    <div className="fixed flex flex-row gap-2 top-[3.5rem] left-[1rem]">
                        <button
                            className="w-fit bg-nord-900 border border-slate-600 text-slate-300 rounded-lg p-3 mt-8 hover:bg-nord-800"
                            onClick={() => {
                                setProgress(0);
                                setHearts(3);
                                setCurrentQuestionIndex(0);
                                setCurrentQuestion(totalQuestionsArr[0]);
                                setRestart(!restart);
                            }}
                        >
                            Restart
                        </button>

                        <Link
                            to="/vocab/devices"
                            className="w-fit bg-nord-900 border border-slate-600 text-slate-300 rounded-lg p-3 mt-8 hover:bg-nord-800"
                        >
                            Literary Devices
                        </Link>
                    </div>
				</div>
			)}

			<Footer />
		</div>
	);
}
