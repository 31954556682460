import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { toast } from "sonner";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Challenge from "../../components/Challenge";

import ProfilePicture from "../../components/ProfilePicture";

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
  } from "../../components/ui/hover-card"

import { Button } from "../../components/ui/button"

import {
      Dialog,
      DialogClose,
      DialogContent,
      DialogDescription,
      DialogFooter,
      DialogHeader,
      DialogTitle,
      DialogTrigger,
} from "../../components/ui/dialog"

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../components/ui/select"

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"

function badge(type, text) {
    let varType = "";

    switch (type) {
        case "success":
            varType = "border-emerald-500 text-emerald-500 bg-emerald-950/50";
            break;
        case "error":
            varType = "border-rose-500 text-rose-500 bg-rose-950/50";
            break;
        case "warning":
            varType = "border-yellow-500 text-yellow-500 bg-yellow-950/50";
            break;
        case "info":
            varType = "border-blue-500 text-blue-500 bg-blue-950/50";
            break;
        default:
            varType = "border-emerald-500 text-emerald-500 bg-emerald-950/50";
            break;
    }

    return (
        <span className={`border rounded-full px-2 py-1 ${varType}`}>
            {text}
        </span>
    )

}

export default function BookClubManageClub() {
    const { authenticated, login, logout, signup } = useAuth();
    const location = useLocation();

    if (!authenticated.authenticated) {
        window.location.href = "/login";
        alert("You must be logged in to view this page.");
    }

    const urlParams_ = new URLSearchParams(window.location.search);
    const [bookClubId, setBookClubId] = useState(urlParams_.get("id"));
    let tabSelected = urlParams_.get("tab");

    if (!tabSelected) {
        tabSelected = "club_settings";
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id");
        if (id !== bookClubId) {
            setBookClubId(id);
        }
    }, [location]);

    function changeTab(tab) {
        // change url in browser but dont reload page
        tabSelected = tab;
        console.log(tabSelected);

        window.history.pushState({}, "", `/bookclub/manage?id=${bookClubId}&tab=${tab}`);

        if (tabSelected === "club_settings") {
            clubNameRef.current.value = bookClubData.name;
            externalLinkRef.current.value = bookClubData.external_link;
            setGenreFocus(inverse_genre_focus_mappings[bookClubData.genre_focus]);
        }
    }

    const [bookClubData, setBookClubData] = useState({
        name: "Loading...",
        active_invite_codes: [
            {
                code: "Loading...",
                active: true,
                generated_by: {
                    name: "Loading...",
                    id: "Loading...",
                },
                uses: 0,
            }
        ],
        members: [
            {
                name: "Loading...",
                id: "Loading...",
                picture: "Loading...",
                role: "Loading...",
                joined: "Loading...",
                posts: 0
            }
        ]
    });

    const genre_focus_mappings = {
        "none": "No Focus",
        "fiction": "Fiction",
        "non_fiction": "Non-Fiction",
        "sci_fi": "Sci-Fi",
        "romance": "Romance",
        "mystery": "Mystery",
        "poetry": "Poetry",
        "biography": "Biography",
        "fantasy": "Fantasy",
        "horror": "Horror",
        "thriller": "Thriller",
        "historical": "Historical",
        "young_adult": "Young Adult",
        "comic": "Comic",
        "manga": "Manga"
    }

    const inverse_genre_focus_mappings = {
        "No Focus": "none",
        "Fiction": "fiction",
        "Non-Fiction": "non_fiction",
        "Sci-Fi": "sci_fi",
        "Romance": "romance",
        "Mystery": "mystery",
        "Poetry": "poetry",
        "Biography": "biography",
        "Fantasy": "fantasy",
        "Horror": "horror",
        "Thriller": "thriller",
        "Historical": "historical",
        "Young Adult": "young_adult",
        "Comic": "comic",
        "Manga": "manga"
    }

    useEffect(() => {
        async function getBookClubData() {
            const response = await fetch(`https://nerded.io/api/bookclub/get_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                }),
            });

            const data = await response.json();

            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            setBookClubData(data.book_club_info);

            try {
                clubNameRef.current.value = data.book_club_info.name;
                externalLinkRef.current.value = data.book_club_info.external_link;
                setGenreFocus(inverse_genre_focus_mappings[data.book_club_info.genre_focus]);
            } catch (e) {
                console.log(e);
            }
        }

        getBookClubData();
    }, [bookClubId, authenticated]);

    async function generateInviteCode() {
        const response = await fetch(`https://nerded.io/api/bookclub/manage/generate_invite_code`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: authenticated.userId,
                session_token: authenticated.sessionToken,
                club_id: bookClubId,
            }),
        });

        const data = await response.json();

        console.log(data);

        if (data.requestStatus === "success") {
            toast.success("Invite code generated successfully.");

            // add data.invite_code to bookClubData.active_invite_codes
            setBookClubData({
                ...bookClubData,
                active_invite_codes: [
                    ...bookClubData.active_invite_codes,
                    {
                        code: data.invite_code,
                        active: true,
                        generated_by: {
                            name: authenticated.name,
                            id: authenticated.userId,
                            picture: authenticated.picture,
                        },
                        uses: 0
                    }
                ],
            });
        } else {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
        }
    }

    function copyBookClubInviteCodeToClipboard(code) {
        const codeLink = `https://nerded.io/bookclub/join?code=${code}`;

        navigator.clipboard.writeText(codeLink).then(() => {
            toast.success("Invite code link copied to clipboard.");
        }).catch(() => {
            toast.error("Failed to copy invite code link to clipboard.");
        });
    }

    async function disableInviteCode(code) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/disable_invite_code",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    invite_code: code,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        // remove code from bookClubData.active_invite_codes

        // show it but show as inactive
        setBookClubData({
            ...bookClubData,
            active_invite_codes: bookClubData.active_invite_codes.map((item) => {
                if (item.code === code) {
                    return {
                        ...item,
                        active: false,
                    };
                }

                return item;
            }),
        });

        toast.success("Invite code disabled successfully.");
    }

    async function enableInviteCode(code) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/enable_invite_code",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    invite_code: code,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        // remove code from bookClubData.active_invite_codes
        setBookClubData({
            ...bookClubData,
            active_invite_codes: bookClubData.active_invite_codes.map((item) => {
                if (item.code === code) {
                    return {
                        ...item,
                        active: true,
                    };
                }

                return item;
            }),
        });

        toast.success("Invite code enabled successfully.");
    }

    async function deleteInviteCode(code) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/delete_invite_code",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    invite_code: code,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        // remove code from bookClubData.active_invite_codes
        setBookClubData({
            ...bookClubData,
            active_invite_codes: bookClubData.active_invite_codes.filter((item) => item.code !== code),
        });

        toast.success("Invite code deleted successfully.");
    }

    const [loadingSave, setLoadingSave] = useState(false);

    async function saveClubSettings() {
        setLoadingSave(true);

        console.log("saving")
        console.log(genreFocus)

        await fetch("https://nerded.io/api/bookclub/manage/update_club_settings",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    club_name: clubNameRef.current.value,
                    external_link: externalLinkRef.current.value,
                    genre_focus: genreFocus,
                }),
            }
        )
        .then((response) => response.json())
        .then((data) => {
            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
                setLoadingSave(false);
                return;
            }

            toast.success("Club settings updated successfully.");
            bookClubData.name = clubNameRef.current.value;
            setLoadingSave(false);
        });
    }

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function banMember(userId) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/ban_user",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    user_to_ban: userId,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        setBookClubData({
            ...bookClubData,
            members: bookClubData.members.filter((member) => member.id !== userId),
        });

        toast.success("Member banned successfully.");
    }

    // refs for inputs
    const clubNameRef = useRef();
    const externalLinkRef = useRef();

    const [genreFocus, setGenreFocus] = useState("loading");

    function updateGenreFocus(value) {
        console.log(value);
        setGenreFocus(value);
    }

    async function promoteToAdmin(userId) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/promote_to_admin",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    user_to_promote: userId,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        setBookClubData({
            ...bookClubData,
            members: bookClubData.members.map((member) => {
                if (member.id === userId) {
                    return {
                        ...member,
                        role: "admin",
                    };
                }

                return member;
            }),
        });

        toast.success("Member promoted to admin successfully.");
    }

    async function demoteToMember(userId) {
        const response = await fetch("https://nerded.io/api/bookclub/manage/demote_to_member",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                    user_to_demote: userId,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        setBookClubData({
            ...bookClubData,
            members: bookClubData.members.map((member) => {
                if (member.id === userId) {
                    return {
                        ...member,
                        role: "member",
                    };
                }

                return member;
            }),
        });

        toast.success("Admin demoted to member successfully.");

        if (userId === authenticated.userId) {
            window.location.href = "/bookclub/view?id=${bookClubId}";
        }
    }

    const [confirmDeleteText, setConfirmDeleteText] = useState("");

    function typedInConfirmDeleteBox(event) {
        setConfirmDeleteText(event.target.value);
    }

    const [showDangerZone, setShowDangerZone] = useState(false);

    function toggleDangerZone() {
        setShowDangerZone(!showDangerZone);
    }

    async function deleteBookClub() {
        if (confirmDeleteText !== "confirm") {
            toast.error("You must type 'confirm' to delete the book club.");
            return;
        }

        const response = await fetch("https://nerded.io/api/bookclub/manage/delete",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    club_id: bookClubId,
                }),
            }
        );

        const data = await response.json();

        if (data.requestStatus === "error") {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(data.error);
            return;
        }

        toast.success("Book club deleted successfully.");
        window.location.href = "/bookclub";
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-row fixed top-[6rem] gap-2 ml-[2rem]">
                <Link to={`/bookclub/view?id=${bookClubId}`} className="rounded-lg py-2 px-3 bg-nord-800 hover:bg-nord-hover transition flex flex-row items-center">
                    <div className="text-slate-300 text-[1.1rem] flex flex-row items-center">
                        <span className="material-symbols-outlined items-center">arrow_back</span>
                    </div>
                </Link>
            </div>

            <div className="w-fit mt-2 mx-auto text-[1.4rem] font-semibold">
                {bookClubData.name}
            </div>

            <Tabs defaultValue={tabSelected} className="mx-auto mt-[2rem] w-[90vw] md:w-[60vw] lg:w-[60vw] xl:w-[60vw] 2xl:w-[60vw] flex flex-col items-center mb-[4rem]">
                <TabsList className="gap-2 w-fit mb-4 flex flex-row flex-wrap h-fit">
                    <TabsTrigger onClick={() => changeTab("club_settings")} className="bg-slate-800 text-slate-400" value="club_settings">Club Settings</TabsTrigger>
                    <TabsTrigger onClick={() => changeTab("invite_codes")} className="bg-slate-800 text-slate-400" value="invite_codes">Invite Codes</TabsTrigger>
                    <TabsTrigger onClick={() => changeTab("manage_members")} className="bg-slate-800 text-slate-400" value="manage_members">Manage Members</TabsTrigger>
                    {/*<TabsTrigger onClick={() => changeTab("challenges")} className="bg-nord-800 text-slate-400" value="challenges">Challenges</TabsTrigger>*/}
                </TabsList>

                <TabsContent value="club_settings" className="mt-[2rem] w-full flex justify-start flex-row">
                    <div className="flex flex-col gap-[1.2rem] w-full bg-nord-900 shadow-xl border border-slate-700 p-[2rem] rounded-lg">

                        <div className="w-full flex flex-row flex-wrap gap-[1.2rem]">
                            <div className="flex flex-row gap-[1rem]">
                                <div className="flex flex-col gap-[14.5px]">
                                    <div className="text-slate-300 w-fit flex flex-row gap-1">
                                        <span>
                                            Club Name
                                        </span>
                                        <span className="text-blue-500 flex items-start">
                                            *
                                        </span>
                                    </div>
                                    <input type="text" className="bg-nord-800 text-slate-200 rounded-lg px-3 focus:border-slate-600 border-[1.5px] border-transparent py-[10px] w-[12rem] outline-none" ref={clubNameRef} />
                                </div>

                                {/*<div className="flex flex-col gap-[14.5px]">
                                    <div className="text-slate-300 w-fit">Club Image</div>
                                    <input type="file" className="bg-nord-800 text-slate-300 rounded-lg px-3 focus:border-slate-600 border-[1.5px] border-transparent py-[10px] w-[12rem] outline-none" />
                                </div>*/}
                            </div>

                            {/*<div>
                                <div className="text-slate-300 w-fit mt-1 mb-4">Club Description</div>
                                <textarea className="bg-nord-800 text-slate-300 rounded-lg px-3 focus:border-slate-600 border-[1.5px] border-transparent py-[10px] w-full h-[8rem] outline-none" value={bookClubData.description} placeholder="Write a bit about your club here! Expectations, book genres, events, etc!" />
                            </div>*/}

                            <div className="flex flex-row gap-[1rem]">
                                <div className="flex flex-col gap-[14.5px]">
                                    <div className="text-slate-300 w-fit">External Link</div>
                                    <input ref={externalLinkRef} type="text" className="bg-nord-800 text-slate-300 rounded-lg px-3 focus:border-slate-600 border-[1.5px] border-transparent py-[10px] w-[12rem] outline-none" placeholder="Nerded.io" />
                                </div>
                            </div>

                            <div className="flex flex-row flex-wrap gap-[1.2rem]">

                                {/*<div className="flex flex-col gap-4 w-full">

                                    <div className="border-b border-b-slate-700 pb-2 mt-2">
                                        Safety Settings
                                    </div>

                                    <div className="flex flex-row gap-4 flex-wrap">

                                        <div className="flex flex-col gap-4">
                                            <div className="text-slate-300 w-fit">
                                                New Member Approval
                                            </div>
                                            <Select>
                                                <SelectTrigger className="w-[180px] bg-nord-800">
                                                    <SelectValue placeholder={capitalize(bookClubData.privacy_setting !== undefined ? bookClubData.privacy_setting : "")} />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="public">Public</SelectItem>
                                                    <SelectItem value="invite_only">Invite Only</SelectItem>
                                                    <SelectItem value="private">Private</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>


                                        <div className="flex flex-col gap-4">
                                            <div className="text-slate-300 w-fit">
                                                Allow Members to Post
                                            </div>
                                            <Select>
                                                <SelectTrigger className="w-full bg-nord-800">
                                                    <SelectValue placeholder="Yes" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="public">Yes</SelectItem>
                                                    <SelectItem value="invite_only">No</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                        <div className="flex flex-col gap-4">
                                            <div className="text-slate-300 w-fit">
                                                Allow External Links
                                            </div>
                                            <Select>
                                                <SelectTrigger className="w-full bg-nord-800">
                                                    <SelectValue placeholder="Yes" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="public">Yes</SelectItem>
                                                    <SelectItem value="invite_only">No</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                    </div>

                                </div>*/}


                                {/*<div className="flex flex-col gap-4 w-full">

                                    <div className="border-b border-b-slate-700 pb-2 mt-2">
                                        Club Update Settings
                                    </div>

                                    <div className="flex flex-row gap-4 flex-wrap">

                                        <div className="flex flex-col gap-4">
                                            <div className="text-slate-300 w-fit">
                                                Announce Member Achievements
                                            </div>
                                            <Select>
                                                <SelectTrigger className="w-full bg-nord-800">
                                                    <SelectValue placeholder="Yes" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="public">Yes</SelectItem>
                                                    <SelectItem value="invite_only">No</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                        <div className="flex flex-col gap-4">
                                            <div className="text-slate-300 w-fit">
                                                Notification Settings
                                            </div>
                                            <Select>
                                                <SelectTrigger className="w-[250px] bg-nord-800">
                                                    <SelectValue placeholder="All Updates" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="all_updates">All Updates</SelectItem>
                                                    <SelectItem value="only_announcements">Only Announcements</SelectItem>
                                                    <SelectItem value="none">None</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                    </div>

                                </div>
                                */}


                                <div className="flex flex-col gap-4">
                                    <div className="text-slate-300 w-fit">
                                        Genre Focus
                                    </div>
                                    <Select onValueChange={updateGenreFocus}>
                                        <SelectTrigger className="w-[180px] bg-nord-800">
                                            <SelectValue placeholder={genre_focus_mappings[genreFocus]} />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[20rem]">
                                            <SelectItem value="none">
                                                <div className="flex flex-row w-full justify-between gap-2 items-center">
                                                    <div className="ml-auto bg-red-500 rounded-full h-[16px] w-[3px]"></div>

                                                    <span className="w-full">
                                                        No Focus
                                                    </span>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="fiction">Fiction</SelectItem>
                                            <SelectItem value="non_fiction">Non-Fiction</SelectItem>
                                            <SelectItem value="sci_fi">Sci-Fi</SelectItem>
                                            <SelectItem value="romance">Romance</SelectItem>
                                            <SelectItem value="mystery">Mystery</SelectItem>
                                            <SelectItem value="poetry">Poetry</SelectItem>
                                            <SelectItem value="biography">Biography</SelectItem>
                                            <SelectItem value="fantasy">Fantasy</SelectItem>
                                            <SelectItem value="horror">Horror</SelectItem>
                                            <SelectItem value="thriller">Thriller</SelectItem>
                                            <SelectItem value="historical">Historical</SelectItem>
                                            <SelectItem value="young_adult">Young Adult</SelectItem>
                                            <SelectItem value="comic">Comic</SelectItem>
                                            <SelectItem value="manga">Manga</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                            </div>
                        </div>

                        <div className="mt-1">
                            <div onClick={saveClubSettings} className="p-px w-fit font-semibold leading-6 mt-3 text-white no-underline bg-nord-800 shadow-2xl cursor-pointer group rounded-xl">
                                <div className="relative z-10 flex items-center px-6 py-3 space-x-2 rounded-xl bg-gray-950/50 ring-1 ring-white/15">
                                    <span>Save Updated Settings</span>

                                    {loadingSave ?
                                        <span className="material-symbols-outlined animate-spin">
                                            cached
                                        </span>
                                    :
                                        <span className="material-symbols-outlined">
                                            check
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-800 pt-5 flex flex-col gap-4">

                            <div onClick={toggleDangerZone} className="bg-red-950 cursor-pointer px-3 py-2 gap-2 flex items-center rounded-lg border border-red-800 select-none transition hover:border-red-700 w-fit">
                                <span className="material-symbols-outlined">
                                    emergency_home
                                </span>

                                <span>{showDangerZone ? "Hide" : "Show"} Danger Zone</span>
                            </div>

                            {showDangerZone ?
                                <div className="flex flex-col gap-3 bg-red-950 rounded-lg px-3 py-2">
                                    <span className="flex items-center text-[1.1rem] text-red-100 w-fit">
                                        Danger Zone
                                    </span>

                                    <Dialog>
                                        <DialogTrigger asChild onClick={() => setConfirmDeleteText("")}>
                                            <div className="flex flex-row justify-center w-fit gap-4 p-[8px] border-[1.5px] border-red-800 bg-red-950 transition text-red-100 hover:border-red-700 cursor-pointer rounded-lg">
                                                <div className="items-center w-fit gap-2 px-1 flex-row flex">
                                                    <span className="material-symbols-outlined">
                                                        warning
                                                    </span>

                                                    <span>
                                                        Permanently Delete Book Club
                                                    </span>
                                                </div>
                                            </div>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                                    <span>Are you sure you want to delete this book club?</span>
                                                </DialogTitle>

                                                <div className="pt-2 text-slate-400 text-md">
                                                    This action <span className="underline text-red-500">cannot be undone</span> and all <span className="underline text-red-500">data will be lost</span>.
                                                </div>

                                            </DialogHeader>

                                            <div className="flex flex-col gap-2">
                                                <span className="text-slate-300">
                                                    Type "confirm" to delete this book club.
                                                </span>

                                                <input type="text" onChange={typedInConfirmDeleteBox} className="w-full border border-slate-700 focus:z-10 bg-nord-950/30 outline-none focus:border-slate-600 p-3 rounded-lg" />
                                            </div>

                                            <div className="flex flex-row gap-4 ml-auto items-center">
                                                <DialogTrigger asChild>
                                                    <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                                </DialogTrigger>

                                                {confirmDeleteText === "confirm" ?
                                                    <DialogTrigger asChild>
                                                        <Button type="submit" onClick={deleteBookClub} className={`w-fit ml-auto ${confirmDeleteText === "confirm" ? "bg-red-700 hover:bg-red-800" : "bg-nord-800 hover:bg-nord-800 cursor-not-allowed"}`}>Delete Book Club</Button>
                                                    </DialogTrigger>
                                                :
                                                    <Button type="submit" className={`w-fit ml-auto ${confirmDeleteText === "confirm" ? "bg-red-700 hover:bg-red-800" : "bg-nord-800 hover:bg-nord-800 cursor-not-allowed"}`}>Delete Book Club</Button>
                                                }
                                            </div>

                                        </DialogContent>
                                    </Dialog>
                                </div>
                            : null}
                        </div>
                    </div>
                </TabsContent>

                <TabsContent value="invite_codes" className="mt-[0px] w-full flex justify-start flex-row">
                    <div className="w-full bg-nord-900 border shadow-xl shadow-slate-950 p-3 border-slate-700 rounded-lg">
                        <Table className="w-full">

                            <TableHeader>
                                <TableRow>
                                <TableHead className="w-[100px]">Code</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Generated By</TableHead>
                                <TableHead>Uses</TableHead>
                                <TableHead className="">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {bookClubData.active_invite_codes.map((code) => (
                                <TableRow key={code.code}>
                                    <TableCell className="font-medium">{code.code}</TableCell>
                                    <TableCell className="w-[8rem]">
                                        {code.active ? badge("success", "Active") : badge("error", "Inactive")}
                                    </TableCell>
                                    <TableCell>

                                        {code.generated_by.id === "system" ?
                                            <span className="text-slate-200">
                                                {badge("warning", "System")}
                                            </span>
                                            :
                                            <div>
                                                <HoverCard openDelay="100" closeDelay="400">


                                                    <HoverCardTrigger>
                                                        <span className="text-slate-300 underline">{code.generated_by.name}</span>
                                                    </HoverCardTrigger>

                                                    <HoverCardContent side="top" className="mb-2 p-3 w-fit">
                                                        <div className="flex flex-col gap-3">
                                                            {code.generated_by.id !== "system" ? <span className="text-slate-300">Profile:
                                                                <Link to={`/profile?id=${code.generated_by.id}`} className="text-sky-500 hover:underline ml-2">
                                                                    View Profile
                                                                </Link>
                                                            </span> : null}
                                                            <span className="text-slate-300">Role:
                                                                <span className="text-slate-400 ml-2">
                                                                    {
                                                                        code.generated_by.id === "system" ? badge("success", "System") : badge("warning", "Admin")
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </HoverCardContent>
                                                </HoverCard>
                                            </div>
                                        }

                                    </TableCell>

                                    <TableCell className="">
                                        <span className="text-[1.1rem] text-slate-300">{code.uses}</span>
                                    </TableCell>

                                    <TableCell className="flex gap-2 flex-row">

                                        <HoverCard openDelay={100} closeDelay={100}>
                                            <HoverCardTrigger>
                                            <span onClick={() => copyBookClubInviteCodeToClipboard(code.code)} className="text-[1.5rem] select-none material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                content_copy
                                            </span>
                                            </HoverCardTrigger>
                                            <HoverCardContent className="w-fit mb-1 text-slate-20" side="top">
                                                Copy
                                            </HoverCardContent>
                                        </HoverCard>

                                        {/*<span className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                            edit
                                        </span>*/}

                                        {code.active ?
                                            <HoverCard openDelay={100} closeDelay={100}>
                                                <HoverCardTrigger>
                                                <span onClick={() => disableInviteCode(code.code)} className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                    do_not_disturb
                                                </span>
                                                </HoverCardTrigger>
                                                <HoverCardContent className="w-fit mb-1 text-slate-200" side="top">
                                                    Disable
                                                </HoverCardContent>
                                            </HoverCard>
                                            :
                                            <HoverCard openDelay={100} closeDelay={100}>
                                                <HoverCardTrigger>
                                                    <span onClick={() => enableInviteCode(code.code)} className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                        check
                                                    </span>
                                                </HoverCardTrigger>
                                                <HoverCardContent className="w-fit mb-1 text-slate-200" side="top">
                                                    Enable
                                                </HoverCardContent>
                                            </HoverCard>
                                        }


                                        {bookClubData.privacy_setting !== "public" ?
                                            <Dialog>
                                                <HoverCard openDelay={100} closeDelay={100}>
                                                    <HoverCardTrigger>
                                                        <DialogTrigger asChild>
                                                            <span className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                                delete
                                                            </span>
                                                        </DialogTrigger>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="w-fit mb-1 text-slate-200" side="top">
                                                        Delete
                                                    </HoverCardContent>
                                                </HoverCard>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                                            <span>Are you sure you want to delete this invite code?</span>
                                                        </DialogTitle>

                                                        <div className="pt-1 text-slate-400 text-md">
                                                            This action will permanently delete the invite code and it cannot be undone. The code will no longer be usable.
                                                        </div>

                                                    </DialogHeader>

                                                    <div className="flex flex-row gap-4 ml-auto items-center">
                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                                        </DialogTrigger>

                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="w-fit ml-auto" onClick={() => deleteInviteCode(code.code)}>Delete</Button>
                                                        </DialogTrigger>
                                                    </div>

                                                </DialogContent>
                                            </Dialog>
                                        : null}

                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <div className="mt-4 flex flex-row gap-2">
                            {bookClubData.privacy_setting !== "public" ?
                                <div onClick={generateInviteCode} className="cursor-pointer flex flex-row items-center gap-2 bg-nord-800 text-slate-300 rounded-lg py-2 px-4 hover:bg-nord-hover transition">
                                    <span className="material-symbols-outlined">
                                        add
                                    </span>
                                    <span>
                                        Generate New Code
                                    </span>
                                </div>
                                :
                                <div className="flex flex-row items-center gap-2 text-slate-300 rounded-lg py-2 px-4 transition">
                                    <span className="material-symbols-outlined">
                                        public
                                    </span>
                                    <span>
                                        Club is Public &nbsp; - &nbsp; Cannot generate invite codes
                                    </span>
                                </div>
                            }

                            {/*<div className="cursor-pointer flex flex-row items-center gap-2 bg-nord-800 text-slate-300 rounded-lg py-2 px-4 hover:bg-slate-700 transition">
                                <span className="material-symbols-outlined mb-[-3px]">
                                    outgoing_mail
                                </span>
                                <span>
                                    Invite via Email
                                </span>
                            </div>*/}
                        </div>
                    </div>
                </TabsContent>

                <TabsContent value="manage_members" className="mt-[0px] w-full flex justify-start flex-row">
                    <div className="w-full bg-nord-900 border shadow-xl shadow-slate-950 p-3 border-slate-700 rounded-lg">
                        <Table className="w-full">

                            <TableHeader>
                                <TableRow>
                                <TableHead className="">Member Name</TableHead>
                                <TableHead>Role</TableHead>
                                <TableHead className="">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody className="">
                                {bookClubData.members.map((member) => (
                                    <TableRow className="">
                                        <TableCell className="font-medium flex flex-row gap-1 items-center">
                                            <ProfilePicture variant={member?.picture_generation_details?.variant} seed={member?.picture_generation_details?.seed} colors={member?.picture_generation_details?.colors} isDefault={member.picture_is_default} picture={member.picture} size="30" className="w-[30px] h-[30px] rounded-full mr-2" />
                                            <span>
                                                {member.name}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            {member.role === "admin" ? badge("success", "Admin") : badge("warning", "Member")}
                                        </TableCell>

                                        <TableCell className="flex gap-2 flex-row">
                                            {member.role !== "admin" ?
                                            <Dialog>
                                                <DialogTrigger asChild>
                                                    <span className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                        gavel
                                                    </span>
                                                </DialogTrigger>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                                            <span>Are you sure you want to ban</span>
                                                            <div className="flex flex-row items-center bg-nord-800/90 pr-3 h-[30px] rounded-full">
                                                            <ProfilePicture variant={member?.picture_generation_details?.variant} seed={member?.picture_generation_details?.seed} colors={member?.picture_generation_details?.colors} isDefault={member.picture_is_default} picture={member.picture} size="30" className="w-[30px] h-[30px] rounded-full mr-2" />
                                                                <span>{member.name}</span>
                                                            </div>
                                                            <span>?</span>
                                                        </DialogTitle>

                                                        <div className="pt-1 text-slate-400 text-md">
                                                            This action will remove the member from the club and they will not be able to rejoin in the future unless they are unbanned.
                                                        </div>

                                                    </DialogHeader>

                                                    <div className="flex flex-row gap-4 ml-auto items-center">
                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                                        </DialogTrigger>

                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="w-fit ml-auto" onClick={() => banMember(member.id)}>Confirm</Button>
                                                        </DialogTrigger>
                                                    </div>

                                                </DialogContent>
                                            </Dialog>
                                            : null
                                            }

                                            {member.role === "member" ?
                                            <Dialog>
                                                <DialogTrigger asChild>
                                                    <span className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                        keyboard_double_arrow_up
                                                    </span>
                                                </DialogTrigger>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                                            <span>Promote</span>
                                                            <div className="flex flex-row items-center bg-nord-800/90 pr-3 h-[30px] rounded-full">
                                                            <ProfilePicture variant={member?.picture_generation_details?.variant} seed={member?.picture_generation_details?.seed} colors={member?.picture_generation_details?.colors} isDefault={member.picture_is_default} picture={member.picture} size="30" className="w-[30px] h-[30px] rounded-full mr-2" />
                                                                <span>{member.name}</span>
                                                            </div>
                                                            <span>to admin?</span>
                                                        </DialogTitle>

                                                        <div className="pt-1 text-slate-400 text-md">
                                                            This action will allow them to manage the club settings and members and they will have the same permissions as you.
                                                        </div>

                                                    </DialogHeader>

                                                    <div className="flex flex-row gap-4 ml-auto items-center">
                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                                        </DialogTrigger>

                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="w-fit ml-auto" onClick={() => promoteToAdmin(member.id)}>Confirm</Button>
                                                        </DialogTrigger>
                                                    </div>

                                                </DialogContent>
                                            </Dialog>
                                            :
                                            <Dialog>
                                                <DialogTrigger asChild>
                                                    <span className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                        keyboard_double_arrow_down
                                                    </span>
                                                </DialogTrigger>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <DialogTitle className="flex-row flex gap-2 items-center w-full flex-wrap">
                                                            <span>Remove Admin role from</span>
                                                            <div className="flex flex-row items-center bg-nord-800/90 pr-3 h-[30px] rounded-full">
                                                            <ProfilePicture variant={member?.picture_generation_details?.variant} seed={member?.picture_generation_details?.seed} colors={member?.picture_generation_details?.colors} isDefault={member.picture_is_default} picture={member.picture} size="30" className="w-[30px] h-[30px] rounded-full mr-2" />
                                                                <span>{member.name}</span>
                                                            </div>
                                                            <span>?</span>
                                                        </DialogTitle>

                                                        <div className="pt-1 text-slate-400 text-md">
                                                            This action will remove their admin permissions and they will no longer be able to manage the club settings or members.
                                                        </div>

                                                    </DialogHeader>

                                                    <div className="flex flex-row gap-4 ml-auto items-center">
                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="bg-transparent hover:bg-nord-800 w-fit ml-auto">Cancel</Button>
                                                        </DialogTrigger>

                                                        <DialogTrigger asChild>
                                                            <Button type="submit" className="w-fit ml-auto" onClick={() => demoteToMember(member.id)}>Confirm</Button>
                                                        </DialogTrigger>
                                                    </div>

                                                </DialogContent>
                                            </Dialog>
                                            }


                                            <Link target="_blank" to={`/profile?id=${member.id}`} className="text-[1.5rem] material-symbols-outlined text-slate-300 mr-2 cursor-pointer rounded-lg px-[10px] py-[10px] hover:bg-nord-800 transition">
                                                open_in_new
                                            </Link>


                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TabsContent>

                {/*<TabsContent value="challenges" className="mt-[0px] w-full flex justify-start flex-row">
                    <div className="w-full">
                        <Challenge
                            name="2022 Reading Challenge"
                            booksRead={5}
                            challengeTotal={10}
                        />
                    </div>
                </TabsContent>*/}
            </Tabs>

            <Footer />
        </div>
    )
}
