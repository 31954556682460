import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { toast } from "sonner";

function ShapeIcon({ shape }) {
	const shapeIcons = {
		circle: "ellipse", // Circle
		square: "square", // Square
		triangle: "triangle", // Triangle
		star: "star", // Star
        newspaper: "newspaper",
        barbell: "barbell",
        library: "library",
        folder: "folder",
	};

	return (
		<ion-icon
			name={`${shapeIcons[shape]}`}
			style={{ fontSize: "1.5rem" }}
		></ion-icon>
	);
}

function BookSpine({ book, isStacked, onRemove, num }) {
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: "book",
			item: { book, isStacked },
			end: (item, monitor) => {
				const didDrop = monitor.didDrop();
				if (!didDrop && isStacked) {
					onRemove(item.book);
				}
			},
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[book, isStacked, onRemove],
	);

	const colors = [
		"bg-red-700",
		"bg-blue-700",
		"bg-green-700",
		"bg-yellow-700",
		"bg-purple-700",
		"bg-pink-700",
		"bg-indigo-700",
		"bg-teal-700",
		"bg-cyan-700",
		"bg-amber-700",
		"bg-lime-700",
		"bg-orange-700",
        "bg-emerald-700",
	];

	const randomColor = colors[book.id % colors.length];

	return (
		<div
			ref={drag}
			className={`${randomColor} ${isDragging ? "opacity-50" : ""}
                rounded-r-md shadow-md ${
					isStacked
						? "flex py-[1.5rem] w-full"
						: "flex w-[5.5rem] pr-2 flex-col"
				} justify-between
                  py-3 px-3 ${
						num % 2 === 0 && isStacked
							? "flex-row-reverse"
							: "flex-row"
					} cursor-pointer ${
						!isStacked ? "h-[8rem]" : ""
					} transform transition-all duration-200 ${
						isStacked ? "" : "hover:scale-105"
					}`}
		>
			<div className={`${isStacked ? "mt-[-40px]" : ""}`}>
				<ShapeIcon shape={book.bottomShape} />
			</div>
			<div
				className={`${
					isStacked ? "mb-[-40px] flex items-end p-0" : "mb-[-8px]"
				}`}
			>
				<ShapeIcon shape={book.topShape} />
			</div>
		</div>
	);
}

function BookShelf({ onDrop, stack, onRemove }) {
	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: "book",
			drop: (item) => {
				if (!item.isStacked) {
					onDrop(item.book);
				}
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[onDrop],
	);

	return (
		<div
			ref={drop}
			className={`p-4 bg-amber-900/80 border-4 border-amber-950/80 rounded-md ${
				isOver ? "bg-amber-800" : ""
			}
                  min-h-[900px] w-[400px] flex flex-col-reverse gap-2 items-start justify-start h-fit overflow-x-auto`}
		>
			{stack.map((book, index) => (
				<BookSpine
					key={index}
					book={book}
					isStacked={true}
					onRemove={onRemove}
					num={index + 1}
				/>
			))}
		</div>
	);
}

function SpineStacker() {
	const { authenticated, login, signup, logout } = useAuth();

	const [stack, setStack] = useState([]);
	const [points, setPoints] = useState(0);
	const [availableBooks, setAvailableBooks] = useState([]);
	const [seed, setSeed] = useState(null); // New state for seed
	const [loading, setLoading] = useState(false); // New state for loading

	const shapes = ["circle", "square", "triangle", "star", "newspaper", "barbell", "library", "folder"];

	function seededRandom(seed) {
		const x = Math.sin(seed++) * 10000;
		return x - Math.floor(x);
	}

	function getRandomShapeWithSeed(seed) {
		return shapes[Math.floor(seededRandom(seed) * shapes.length)];
	}

	function generateBookList(len, seed) {
		let isDoable = false;

		while (!isDoable) {
			let newBooks = [];
			for (let count = 0; count < len; count++) {
				newBooks.push({
					id: count + 1, // id starts from 1
					topShape: getRandomShapeWithSeed(seed + count),
					bottomShape: getRandomShapeWithSeed(seed + count + 1),
				});
			}

			isDoable = isStackable(newBooks);

			if (isDoable) {
				return newBooks;
			}
		}
	}

	function isStackable(books) {
		return canStackAllBooks(books, []);
	}

	function canStackAllBooks(books, currentStack) {
		if (books.length === 0) {
			return true;
		}

		for (let i = 0; i < books.length; i++) {
			const book = books[i];
			const remainingBooks = books.filter((_, index) => index !== i);
			const lastBookInStack = currentStack[currentStack.length - 1];

			if (
				currentStack.length === 0 ||
				lastBookInStack.bottomShape === book.topShape
			) {
				if (canStackAllBooks(remainingBooks, [...currentStack, book])) {
					return true;
				}
			}
		}

		return false;
	}

	useEffect(() => {
		if (authenticated?.userId && authenticated?.sessionToken) {
			async function fetchSeed() {
				setLoading(true); // Set loading to true before fetching
				try {
					const response = await fetch("https://nerded.io/api/games/getspinestacker", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							user_id: authenticated?.userId,
							session_token: authenticated?.sessionToken,
						}),
					});

					const data = await response.json();

                    if (data.requestStatus === "error") {
                        if (data?.relog) {
                            toast.error("Please login again!");
                            logout();
                        }

                        toast.error(data.error);
                        return;
                    }

					setSeed(data.seed);

				} catch (error) {
					console.error("Error fetching seed:", error);
				} finally {
					setLoading(false); // Set loading to false after fetching
				}
			};

			fetchSeed();
		}
	}, [authenticated]);

	useEffect(() => {
		if (seed !== null) {
			let generatedBooks;
			do {
				generatedBooks = generateBookList(16, seed);
			} while (!isStackable(generatedBooks)); // Reshuffle until the list is stackable

			setAvailableBooks(generatedBooks);
		}
	}, [seed]);

	function handleDrop(book) {
		setStack((prevStack) => {
			const lastBook =
				prevStack.length > 0 ? prevStack[prevStack.length - 1] : null;

			if (!lastBook || lastBook.bottomShape === book.topShape) {
				const newStack = [...prevStack, book];
				setPoints((points) => points + 10);
				toast.success("Great match! +10 points");
				setAvailableBooks((availableBooks) =>
					availableBooks.filter((b) => b.id !== book.id),
				);
				return newStack;
			} else {
				toast.error("Shapes don't match. Try again!");
				return prevStack;
			}
		});
	}

	function handleRemove(book) {
		setStack((prevStack) => {
			const removeIndex = prevStack.findIndex((b) => b.id === book.id);
			const newStack = prevStack.slice(0, removeIndex);
			const removedBooks = prevStack.slice(removeIndex);

			setAvailableBooks((prevBooks) => [...prevBooks, ...removedBooks]);
			setPoints((points) => points - removedBooks.length * 10);
			toast.info(
				`Removed "${book.id}" and all books on top. -${
					removedBooks.length * 10
				} points.`,
			);
			return newStack;
		});
	}

	function showCorrectAnswer() {
		const bookList = [...stack, ...availableBooks];
		if (isStackable(bookList)) {
			const correctOrder = findCorrectOrder(bookList);
			setStack(correctOrder);
			setAvailableBooks([]);
			setPoints((points) => points + correctOrder.length * 10);
			toast.info("This is the correct order!");
		} else {
			alert("It's impossible to stack all the books correctly.");
		}
	}

	function findCorrectOrder(books) {
		const correctOrder = [];

		function recursiveStack(remainingBooks, currentStack) {
			if (remainingBooks.length === 0) {
				correctOrder.push(...currentStack);
				return true;
			}

			for (let i = 0; i < remainingBooks.length; i++) {
				const book = remainingBooks[i];
				const remaining = remainingBooks.filter(
					(_, index) => index !== i,
				);
				const lastBookInStack = currentStack[currentStack.length - 1];

				if (
					currentStack.length === 0 ||
					lastBookInStack.bottomShape === book.topShape
				) {
					if (recursiveStack(remaining, [...currentStack, book])) {
						return true;
					}
				}
			}

			return false;
		}

		recursiveStack(books, []);
		return correctOrder;
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<Navbar />
			<div className={`${!loading ? "min-h-screen" : "py-[8rem]"} bg-nord-900 flex flex-col items-center justify-center p-4`}>
				{loading ? (
					<div className="flex items-center gap-3 bg-slate-800/50 px-6 py-4 rounded-lg border border-slate-700">
						<div className="w-5 h-5 border-2 border-slate-300 border-t-transparent rounded-full animate-spin"></div>
						<span className="text-slate-300 font-medium">Loading game...</span>
					</div>
				) : (
					<>
						<span className="mb-[2rem] font-semibold text-md text-slate-300">
							Spine Stacker Points: {points}
						</span>
						<div className="flex gap-8 justify-center">
							<BookShelf
								onDrop={handleDrop}
								stack={stack}
								onRemove={handleRemove}
							/>
							{availableBooks.length !== 0 ? (
								<div className="grid grid-cols-4 gap-4 my-auto">
									{availableBooks.map((book) => (
										<BookSpine
											key={book.id}
											book={book}
											isStacked={false}
											num={2}
										/>
									))}
								</div>
							) : null}
						</div>
						<button
							onClick={showCorrectAnswer}
							className="mt-8 px-4 py-2 bg-green-700 text-white rounded hover:bg-green-800"
						>
							Show Correct Answer
						</button>
					</>
				)}
			</div>
			<Footer />
		</DndProvider>
	);
}

export default SpineStacker;
