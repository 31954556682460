import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { toast } from "sonner";

import ProfilePicture from "../components/ProfilePicture";

function LoadingSpinner() {
	return (
		<div className="flex items-center mx-auto max-w-[10rem] mb-[8rem] mt-[6rem] gap-3 bg-slate-800/50 px-6 py-4 rounded-lg border border-slate-700 justify-center">
			<div className="w-5 h-5 border-2 border-slate-300 border-t-transparent rounded-full animate-spin"></div>
			<span className="text-slate-300 font-medium">Loading...</span>
		</div>
	);
}

export default function NotificationsPage() {
	const { authenticated, login } = useAuth();

	const [notifications, setNotifications] = useState([]);
	const [notificationsLoaded, setNotificationsLoaded] = useState(false);

	useEffect(() => {
		async function loadNotifications() {
			try {
				const response = await fetch(
					"https://nerded.io/api/notifications/get_notifications",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							session_token: authenticated.sessionToken,
							user_id: authenticated.userId,
						}),
					},
				);
				const data = await response.json();

				if (data.requestStatus === "error") {
					toast.error(data.message);
					return;
				}

				setNotifications(data.notifications);
				setNotificationsLoaded(true);
			} catch (error) {
				toast.error("Failed to load notifications.");
				console.error(error);
			}
		}

		loadNotifications();
	}, [authenticated]);

	async function handleFriendRequest(decision, targetId) {
		try {
			const response = await fetch(
				"https://nerded.io/api/notifications/handle_friend_request",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						session_token: authenticated.sessionToken,
						user_id: authenticated.userId,
						target_id: targetId,
						decision, // Either "accept" or "ignore"
					}),
				},
			);

			const data = await response.json();

			if (data.result === "success") {
				toast.success(data.message);
				// Remove the notification from the list
				setNotifications((prevNotifications) =>
					prevNotifications.filter(
						(notification) => notification.id !== targetId,
					),
				);
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error("Failed to process the friend request.");
			console.error(error);
		}
	}

	if (!authenticated.authenticated) {
		login();
		return (
			<div>
				<Navbar />

				<div className="text-center w-full my-[2rem]">Not logged in</div>

				<Footer />
			</div>
		);
	}

	return (
		<div>
			<Navbar />

			{!notificationsLoaded ? (
				<LoadingSpinner />
			) : (
				<div>
					<h1 className="text-4xl text-slate-300 mb-[2rem] mt-[2rem] font-bold mx-auto w-fit">
						Notifications
					</h1>

					<div className="w-full mx-auto mb-[5rem]">
						<div className="grid grid-cols-1 gap-4 w-[60vw] mx-auto">
							<h1 className="text-white font-bold">Friend Requests</h1>

							<div className="bg-nord-800 border border-nord-700/50 px-4 py-2 rounded-lg flex flex-col">
								{notifications.map((notification) => {
									if (notification.type !== "friend_request") {
										return null;
									} else {
										return (
											<div
												key={notification.id}
												className="flex flex-row justify-between items-center p-2 px-0 rounded-lg"
											>
												<div className="flex flex-row gap-4 items-center">
													<ProfilePicture
														className="w-[50px] h-[50px] rounded-lg"
														size={50}
														seed={
															notification
																.picture_generation_details
																?.seed
														}
														variant={
															notification
																.picture_generation_details
																?.variant
														}
														isDefault={
															notification?.picture_is_default
														}
														picture={notification.picture?.replace(
															"http://",
															"https://",
														)}
														colors={
															notification
																?.picture_generation_details
																?.colors
														}
													/>

													<div className="flex flex-row gap-2">
														<p className="text-white font-bold">
															{notification.username}
														</p>
													</div>
												</div>

												<p className="text-white w-fit">
													You have a new friend request
												</p>

												<div className="flex flex-row gap-2 items-center">
													<div
														className="flex flex-flex cursor-pointer bg-sky-700 p-2 px-4 rounded-lg"
														onClick={() =>
															handleFriendRequest(
																"accept",
																notification.id,
															)
														}
													>
														Accept
													</div>

													<div
														className="flex flex-flex cursor-pointer bg-slate-700 p-2 px-4 rounded-lg"
														onClick={() =>
															handleFriendRequest(
																"ignore",
																notification.id,
															)
														}
													>
														Ignore
													</div>
												</div>
											</div>
										);
									}
								})}

                                {
                                    notifications.length === 0 && (
                                        <p className="text-white py-2 mx-auto">No friend requests :/</p>
                                    )
                                }
							</div>
						</div>
					</div>
				</div>
			)}

			<Footer />
		</div>
	);
}
