import { useAuth } from '../context/AuthContext';

import Navbar from "../components/Navbar";
import Footer from "../components/Footer"
import ClickableButton from '../components/ClickableButton';

function Tester() {
    const { authenticated, login, logout } = useAuth();

    return (
        <div>
            <Navbar showAccountPrompt={false} />

            <div>
                <span>
                    Become a tester
                </span>
            </div>

            <Footer />
        </div>
    )
}

export default Tester