import { useState, useEffect, useRef } from "react";

import { useAuth } from "../../context/AuthContext";

import { toast } from "sonner";
import ProfilePicture from "../ProfilePicture";

function ShareBookModal(props) {
	const { authenticated, login, logout, signup } = useAuth();

	const [usersSharedTo, setUsersSharedTo] = useState([]);

	const emailRef = useRef(null);

	const [searchQuery, setSearchQuery] = useState("");

	const normalizeString = (str) => {
		return str
			.normalize("NFD") // Normalize to decompose accents
			.replace(/[\u0300-\u036f]/g, "") // Remove accents
			.replace(/\s+/g, "") // Remove all spaces
			.toLowerCase(); // Convert to lowercase
	};

	function clearEmail(closeModal = false) {
		emailRef.current.value = "";
		//setEmailInput("");

		if (closeModal) {
			props.setShowBookShareModal(false);
		}
	}

	let currentlySendingRequest = false;

	async function submitShare() {
		if (emailRef.current.value === "") {
			toast.error("Please add an email!");
			return;
		}

		// if has @ and . (includes)
		if (
			!emailRef.current.value.includes("@") ||
			!emailRef.current.value.includes(".")
		) {
			toast.error("Please add a valid email!");
			return;
		}

		if (currentlySendingRequest) {
			toast.error("Please wait for the current request to finish!");
			return;
		}

		let tempValue = emailRef.current.value;

		if (usersSharedTo.includes(tempValue)) {
			toast.error("Already shared with this person!");
			return;
		}

        toast.info("Sharing book...");

		currentlySendingRequest = true;

		clearEmail(true);

		await fetch(
			`https://nerded.io/api/user_actions/share_book_via_email`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
					isbn: props.bookData.industryIdentifiers[0].identifier,
					recipient: tempValue,
				}),
			},
		)
			.then((response) => response.json())
			.then((data) => {

				if (!data.requestStats === "success") {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					toast.error("Failed to share book!");
				}

                toast.success("Successfully shared book!");
				currentlySendingRequest = false;

				setUsersSharedTo([...usersSharedTo, tempValue]);
			});
	}

	async function shareBookToFriend(userId) {

		if (currentlySendingRequest) {
			toast.error("Please wait for the current request to finish!");
			return;
		}

		if (usersSharedTo.includes(userId)) {
			toast.error("Already shared with this person!");
			return;
		}

		currentlySendingRequest = true;

		toast.success("Successfully shared book!");

		setUsersSharedTo([...usersSharedTo, userId]);

		currentlySendingRequest = false;

		await fetch(`https://nerded.io/api/share_book_to_friend`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				isbn: props.bookData.industryIdentifiers[0].identifier,
				recipient_id: userId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				//console.log(data);

				if (!data.requestStats === "success") {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					toast.error("Failed to share book!");
				}

				currentlySendingRequest = false;

				toast.success(`Successfully shared book!`); //  to ${authenticated.name}
			});
	}

	const [friendsList, setFriendsList] = useState([]);

	useEffect(() => {
		async function loadFriends() {
			await fetch("https://nerded.io/api/social/get_user_friends", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
				}),
			})
				.then((response) => response.json())
				.then((data) => {

					if (data.requestStatus === "error") {
						if (data?.relog) {
							toast.error("Please login again!");
							logout();
						}

						toast.error("Failed to load friends!");

						return;
					}

					console.log(data);

					setFriendsList(data.friends);
				});
		}

		if (authenticated.authenticated) {
			//  && props.showBookShareModal
			loadFriends();
		}
	}, []);

	return (
		<>
			<div
				className={`fixed bg-nord-950 border-[1.5px] border-nord-800 gap-[0.8rem] p-[1.3rem] md:w-[40vw] lg:w-[40vw] xl:w-[40vw] 2xl:w-[40vw] w-[90vw] h-fit inset-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-start justify-start flex-col rounded-md z-20 ${
					props.showBookShareModal ? "flex" : "hidden"
				}`}
			>
				<div className="text-[1.5rem] flex flex-row justify-between w-full">
					<span className="truncate">
						Share {props?.bookData?.volumeInfo?.title}
					</span>
					<span
						className="font-semibold material-symbols-outlined hover:bg-slate-800 text-[1.5rem] mt-[-5px] p-[10px] cursor-pointer rounded-full items-center flex"
						onClick={() => props.setShowBookShareModal(false)}
					>
						close
					</span>
				</div>

				<div className="flex flex-col gap-3 w-full border-t border-t-slate-800 pt-[20px]">
					<div className="flex flex-row gap-2 mb-2 items-center w-full justify-between">
						<span className="text-slate-300 text-[1.2rem]">
							Share to Friend
						</span>
						<input
							className="w-[16rem] h-[2.8rem] text-[1.2rem] bg-nord-950 rounded-lg border-nord-700/40 p-[12px] text-slate-300 focus:border-nord-700/70 border-[1.5px] outline-none"
							placeholder="Search..."
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>

					{friendsList.length > 0 ? (
						friendsList
							.filter((friend) =>
								normalizeString(friend.username).includes(
									normalizeString(searchQuery),
								),
							)
							.map((friend) => (
								<div
									key={friend.id}
									className="flex flex-row items-center gap-2"
								>
									<span className="text-slate-300 items-center flex flex-row gap-3 text-xl">
										<ProfilePicture
											variant={
												friend
													?.picture_generation_details
													?.variant
											}
											seed={
												friend
													?.picture_generation_details
													?.seed
											}
											colors={
												friend
													?.picture_generation_details
													?.colors
											}
											isDefault={
												friend.picture_is_default
											}
											picture={friend.picture}
											size="40"
											className="rounded-full w-[40px] h-[40px] ml-[3px]"
										/>
										<span className="text-slate-50">{friend?.username}</span>
									</span>

									<div
										onClick={() =>
											shareBookToFriend(friend.id)
										}
										className={`flex hover:bg-nord-700/30 select-none ml-auto items-center justify-center text-slate-100 rounded-md w-fit px-4 py-2 ${
											currentlySendingRequest
												? "bg-nord-900 cursor-not-allowed"
												: "bg-nord-800"
										}
            ${
				usersSharedTo.includes(friend.id)
					? "bg-nord-900 hover:bg-nord-900 cursor-not-allowed"
					: "bg-nord-800 cursor-pointer"
			}
            `}
									>
										Share
									</div>
								</div>
							))

					) : (
						<div className="text-slate-400 text-center py-4">
							No friends found...
						</div>
					)}

{friendsList
							.filter((friend) =>
								normalizeString(friend.username).includes(
									normalizeString(searchQuery),
								),
							).length === 0 && friendsList.length !== 0 ? (
                            <div className="text-slate-400 text-center py-4">
                                No friends found...
                            </div>
                        ) : null}
				</div>

				<div className="flex flex-col border-t border-t-slate-800 pt-5 mt-2 md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-3 w-full items-center">
					<input
						ref={emailRef}
						className="w-full h-[3rem] text-[1.2rem] bg-nord-900 rounded-lg border-nord-700/40 p-[10px] text-slate-300 focus:border-nord-700/70 border-[1.5px] outline-none"
						placeholder="Email to share book to"
					></input>

					<div
						onClick={submitShare}
						className="select-none p-2 h-[3rem] items-center flex bg-nord-800 text-slate-100 rounded-md md:w-fit lg:w-fit xl:w-fit 2xl:w-fit justify-center md:justify-left lg:justify-left xl:justify-left 2xl:justify-left w-full px-5 cursor-pointer hover:bg-nord-700/30"
					>
						Submit
					</div>
				</div>
			</div>

			<div
				onClick={() => props.setShowBookShareModal(false)}
				className={`fixed z-10 inset-0 cursor-pointer bg-black bg-opacity-70 ${
					props.showBookShareModal ? "flex" : "hidden"
				}`}
			></div>
		</>
	);
}

export default ShareBookModal;
