import { useState, useRef } from "react";

import { useAuth } from "../context/AuthContext";

//import Toast from "./Toast";

import { toast } from "sonner"

function BookReviewModal(props) {

    const { authenticated, login, logout, signup } = useAuth();

    const [rating, setRating] = useState(0);

    const reviewText = useRef(null);

    function clearReview(closeModal=false) {
        reviewText.current.value = "";
        setRating(0);

        if (closeModal) {
            props.setShowReviewModal(false);
        }
    }

    //console.log(props.bookData);

    const handleStarClick = (selectedRating) => {
          setRating(selectedRating);
    }

    async function submitReview() {
        if (rating === 0) {
            toast.error("Please select a rating!");
            return;
        }

        await fetch(`https://nerded.io/api/user_actions/submit_review`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user_id: authenticated.userId,
                session_token: authenticated.sessionToken,
                isbn: props.bookData.industryIdentifiers[0].identifier,
                rating: rating,
                review: reviewText.current.value,
            })
        })
        .then(response => response.json())
        .then(data => {
            //console.log(data);

            if (data.requestStatus === "success") {
                toast.success("Successfully submitted review!");
                clearReview(true);
                // refresh reviews
                // add review to reviews
                if (Object.keys(props).includes("refreshReviewsList")) {
                    props.refreshReviewsList();
                }

                try {
                    if (Object.keys(props).includes("updateHomeFeedStats")) {
                        props.updateHomeFeedStats();
                    }
                } catch (error) {
                    console.log(error);
                }

            } else if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error); //"Error submitting review!"
            } else {
                toast.error("Something went wrong!");
            }
        })
    }

    return (
        <div className="">
            <div className={`fixed bg-nord-950/80 backdrop-blur-lg border-[1.5px] shadow-xl shadow-slate-950 border-slate-800 gap-[0.8rem] p-[1.3rem] w-[60vw] h-[60vh] inset-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-start justify-start flex-col rounded-lg z-[20] ${props.showReviewModal ? "flex" : "hidden"}`}>
                <div className="text-[1.5rem] flex flex-row justify-between w-full">
                    <span className="">
                        Review: {props?.bookData?.volumeInfo?.title}
                    </span>
                    <span className="font-semibold material-symbols-outlined hover:bg-slate-800 text-[1.5rem] p-[10px] cursor-pointer rounded-full items-center flex transition" onClick={() => props.setShowReviewModal(false)}>close</span>
                </div>

                <div className="flex flex-row items-center gap-3">
                <div className="flex items-center p-2 rounded-lg border-2 border-slate-700 pl-3">
                    {[...Array(5)].map((_, index) => {
                        const starValue = index + 1;

                        return (
                            <button
                                key={index}
                                onClick={() => handleStarClick(starValue)}
                                className={`text-3xl w-[2.2rem] focus:outline-none items-center flex ${starValue <= rating ? 'text-yellow-500' : (starValue - 0.5) <= rating ? 'text-yellow-500' : 'text-gray-400'}`}                    >
                                {starValue <= rating ? <ion-icon name="star"></ion-icon> : (starValue - 0.5) <= rating ? <ion-icon name="star-half-outline"></ion-icon> : <ion-icon name="star-outline"></ion-icon>}
                            </button>
                        );
                    })}

                    <div className="ml-[0.5rem] select-none bg-slate-700 hover:bg-slate-600 p-2 rounded cursor-pointer" onClick={() => setRating(((rating - 0.5) >= 0.5) ? rating - 0.5 : rating)}>
                        <span className="material-symbols-outlined items-center flex">
                        remove
                        </span>
                    </div>

                    <div className="ml-[0.5rem] select-none bg-slate-700 hover:bg-slate-600 p-2 rounded cursor-pointer" onClick={() => setRating(((rating + 0.5) <= 5) ? rating + 0.5 : rating)}>
                        <span className="material-symbols-outlined items-center flex">
                        add
                        </span>
                    </div>
                </div>

                <p className="p-4 rounded-lg border-[1.5px] border-slate-700">
                    {rating} star{rating !== 1 ? "s" : null}</p>
                </div>

                <textarea ref={reviewText} className="w-full h-full text-[1.2rem] bg-transparent backdrop-blur-sm rounded-lg border-slate-700 p-[10px] text-slate-300 focus:border-slate-600 border-[1.5px] outline-none" placeholder="Write your review here..."></textarea>

                <div onClick={submitReview} className="py-3 bg-slate-800 text-slate-100 rounded-md w-fit px-5 cursor-pointer hover:bg-slate-700">
                    Submit
                </div>
            </div>

            <div onClick={() => props.setShowReviewModal(false)} className={`fixed z-[10] inset-0 cursor-pointer bg-black bg-opacity-80 ${props.showReviewModal ? "flex" : "hidden"}`}></div>
        </div>
    )
}

export default BookReviewModal;
