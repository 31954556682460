import { useNavigate } from "react-router-dom";
import BookActionButtons from "../BookActionButtons";

function BookCard(props) {
	const usingnavigate = useNavigate();

	function navigate(url) {
		// reload when navigating to the same page
		usingnavigate(url);
		window.location.reload();
	}

	if (!props.cover) {
		return null;
	}

	const year = props.year?.split("-")[0] || props.year;
	let author;

	if (props.author === undefined) {
		author = "Unknown";
	} else {
		// add commas between authors
		author = props.author;
	}

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const mapLangs = {
		en: "english",
		es: "spanish",
		fr: "french",
		de: "german",
		it: "italian",
		pt: "portuguese",
		nl: "dutch",
		sv: "swedish",
		no: "norwegian",
		da: "danish",
		fi: "finnish",
		ru: "russian",
		ja: "japanese",
		ar: "arabic",
		zh: "chinese",
		el: "greek",
		he: "hebrew",
		hi: "hindi",
		ko: "korean",
		tr: "turkish",
		pl: "polish",
		cs: "czech",
		hu: "hungarian",
		th: "thai",
		ca: "catalan",
		is: "icelandic",
		ga: "irish",
		la: "latin",
		lv: "latvian",
		lt: "lithuanian",
		ms: "malay",
		mt: "maltese",
		fa: "persian",
		ro: "romanian",
		sk: "slovak",
		sl: "slovenian",
		sw: "swahili",
		uk: "ukrainian",
		vi: "vietnamese",
		cy: "welsh",
        ta: "Tamil"
	};

	function mapLangHandler(lang) {
        console.log(lang);

		if (!lang) {
			return "Unknown";
		}

		if (lang.length > 2) {
			lang = lang.split("-")[0];
		}

		// if not in mapLangs, return the original lang
		if (!mapLangs[lang.toLowerCase().replace(/[^a-z]/g, "")]) {
			return lang;
		}

		return mapLangs[lang.toLowerCase().replace(/[^a-z]/g, "")];
	}

	return (
		<div className="border-[2px] border-transparent flex flex-col overflow-hidden gap-2 bg-nord-800 rounded-xl p-4 w-[90vw] sm:w-[90vw] md:w-[44vw] lg:w-[29vw] h-[12rem] hover:border-emerald-500">
			<div className="flex flex-row gap-4">
				{props.cover ? (
					<img
						src={props.cover
							.replace("edge=curl", "edge=natural")
							.replace("http://", "https://")}
						onClick={() => navigate(`/book?isbn=${props.isbn}`)}
						className="cursor-pointer rounded-lg w-[6.6rem] overflow-y-clip max-h-[10rem]"
					/>
				) : null}
				<div className="flex flex-col h-full justify-between w-full">
					<div className="flex flex-col gap-1">
						<div className="flex flex-row w-full gap-2 justify-between items-center">
							<span
								onClick={() =>
									navigate(`/book?isbn=${props.isbn}`)
								}
								className="cursor-pointer hover:underline text-wrap break-words text-xl font-semibold line-clamp-2"
							>
								{props.title}
							</span>

							{props.lang !== "en" ? (
								<div className="border border-slate-500 px-[5px] py-[2px] rounded-lg text-slate-200">
									{capitalize(mapLangHandler(props.lang))}
								</div>
							) : null}

                            {props.mature === "MATURE" ? (
								<div className="border border-slate-400 px-[5px] py-[2px] rounded-lg text-slate-200">
									18+
								</div>
							) : null}
						</div>

						<div className="flex flex-row gap-1 flex-wrap overflow-hidden max-h-[1.5rem]">
							{author.length === 1 ||
							typeof author === "string" ? (
								author !== "Unknown" ? (
									<div
										onClick={() =>
											navigate(`/search?q=${author} [author]`)
										}
										className="cursor-pointer hover:underline text-lg w-fit text-slate-300 line-clamp-1"
									>
										{author}
									</div>
								) : (
									<div className="text-lg w-fit text-slate-300 line-clamp-1">
										{author}
									</div>
								)
							) : (
								author.map((authorName, index) => (
									<div className="flex flex-row gap-0 text-lg text-slate-300 line-clamp-1">
										<div
											onClick={() =>
												navigate(
													`/search?q=${authorName}`,
												)
											}
											className="cursor-pointer hover:underline text-lg w-fit text-slate-300 line-clamp-1"
										>
											{authorName}
										</div>
										{index === author.length - 1 ? "" : ","}
									</div>
								))
							)}
						</div>

						<span className="text-lg text-slate-400">{year}</span>
					</div>

					<BookActionButtons
						isbn={props.isbn}
						bookData={props.bookData}
						showSendButton={false}
						readList={props.readList}
						bookmarkedList={props.bookmarkedList}
						booksReviewed={props.reviewedList}
						refreshReviewsList={props.refreshReviewsList}
					/>
				</div>
			</div>
		</div>
	);
}

export default BookCard;
