import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import ShowMoreReview from "./ShowMoreReview";

import StarRating from "../StarRating";

import { toast } from "sonner"

import ProfilePicture from "../ProfilePicture";

import RankBadge from "../../components/RankBadge";

export default function ViewReview() {
    const { authenticated, login, signup, logout } = useAuth();

    // ?id search
    //const reviewId = window.location.search.split("?isbn&id=")[1];
    // https://nerded.io/review?isbn=9781250221711&id=7b231a8f-b7a8-4d3a-937e-ea712c0c30b4

    let isbn;
    let reviewId;

    try {
        isbn = window.location.search.split("?isbn=")[1].split("&id=")[0];
        reviewId = window.location.search.split("?isbn=")[1].split("&id=")[1];
    } catch (error) {
        toast.error("Review not found");

    }

    const reviewReply = useRef();

    const [review, setReview] = useState({});
    const [book, setBook] = useState({});

    //const timeAgo = new Date(review.date_posted);
    // get relative time ago

    const timeAgoString = formatTimeAgo(review.timestamp);

    function formatTimeAgo(datePosted) {
        if (!datePosted) {
            return "Unknown";
        }

        // datePosted is time.time() from python
        const timeAgo = new Date(datePosted * 1000);
        console.log(timeAgo);
        const now = new Date();

        const timeDifferenceInSeconds = Math.floor((now - timeAgo) / 1000);

        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

        if (timeDifferenceInSeconds < 60) {
            return rtf.format(-timeDifferenceInSeconds, 'second');
        } else if (timeDifferenceInSeconds < 3600) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 60), 'minute');
        } else if (timeDifferenceInSeconds < 86400) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 3600), 'hour');
        } else if (timeDifferenceInSeconds < 2592000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 86400), 'day');
        } else if (timeDifferenceInSeconds < 31536000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 2592000), 'month');
        } else {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 31536000), 'year');
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        async function getReviewData() {
            // get the review data from the backend
            const res = await fetch(`https://nerded.io/api/reviews/get_review`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isbn: isbn,
                    review_id: reviewId
                })
            });

            const data = await res.json();

            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.message);
                return;
            }

            setReview(data.review);
            setBook(data.book);
        }

        getReviewData();
    }, [reviewId, isbn]);

    // on click of post, send a request to the backend to post the review reply
    function postReviewReply() {
        const reviewReplyText = reviewReply.current.value;

        if (reviewReplyText.length < 1) {
            toast.error("Review reply cannot be empty");
            return;
        }
    }

    function handleCopyReviewLink() {
        navigator.clipboard.writeText(window.location.href);
        toast.success("Copied review link to clipboard");
    }

    const [likedReview, setLikedReview] = useState(false);

    function likeReview() {
        // send a request to the backend to like the review

        setLikedReview(!likedReview);
    }

    // report review
    async function reportReview() {
        console.log(isbn)

        await fetch(`https://nerded.io/api/reviews/report_review`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user_id: authenticated?.userId,
                session_token: authenticated?.sessionToken,
                review_id: reviewId,
                isbn: isbn
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.message);
                return;
            }

            toast.success("Successfully reported review");
        });
    }

    if (review.user_name === undefined) {
        return (
            <div className="mx-auto text-slate-200 bg-nord-900 h-fit p-[2rem] py-[1rem] rounded-lg border border-nord-700/50 text-[1.2rem] font-semibold my-[5rem] w-full flex justify-center">
                Loading...
            </div>
        )
    }

    return (
        <div className="p-[1.5rem] flex flex-col flex-wrap gap-4 rounded-lg bg-nord-900 border border-nord-700/50 w-[60vw] ml-auto mr-auto mt-[4rem] mb-[5rem]">
            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row flex-wrap justify-between items-center">
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row flex-wrap text-slate-600 text-[1.2rem] items-center gap-[2rem]">
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center gap-3">
                        <Link to={`/profile?id=${review?.user_id}`} className="text-slate-100 text-[1.3rem] items-center flex flex-row cursor-pointer gap-3 hover:underline">
                            <ProfilePicture variant={review?.picture_generation_details?.variant} seed={review?.picture_generation_details?.seed} colors={review?.picture_generation_details?.colors} isDefault={review.picture_is_default} picture={review.user_picture} size="48" className="w-[3rem] h-[3rem] rounded-full" />
                            {review?.user_name}
                        </Link>
                        {review.user_rank ?
                            <RankBadge rank={review.user_rank} />
                        : null}
                        <span className="text-sm md:flex lg:flex xl:flex 2xl:flex items-center text-gray-600 hidden">
                            •
                        </span>
                        <div className="text-gray-500 text-[1.1rem] flex items-center">
                            {capitalizeFirstLetter(timeAgoString)}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row items-center mt-4 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 gap-2 border-2 border-slate-800 pl-4 rounded-xl">
                    <StarRating type="slate" rating={review.rating} />
                    <div className="ml-2 text-slate-200 text-lg bg-slate-800 p-3 rounded-r-[0.60rem]">{review.rating}{Number(review.rating) % 1 !== 0 ? "" : ".0"}</div>
                </div>
            </div>

            <div className="flex flex-row rounded-lg mt-2 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 w-fit items-center">
                <div className="text-[1.2rem] flex items-center flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row mb-[-2px]">
                    {book.volumeInfo?.title}
                    <span className="mx-[0.25rem] hidden md:block lg:block xl:block 2xl:block">
                        by
                    </span>
                    {book.volumeInfo?.authors.map((author, index) => {
                        return (
                            <span key={index} className="hidden md:block lg:block xl:block 2xl:block">
                                {author}
                            </span>
                        )
                    })}
                </div>
            </div>

            {review.review !== "" ? <div className="flex items-center flex-row mt-[-0.4rem]">
                <ShowMoreReview text={review.review} />
            </div> : <span className="px-2 py-1 rounded-lg bg-slate-700 mb-[-8px] w-fit">No review</span>}

            <div className="flex flex-row gap-2 items-center mt-3">

                {/*<div onClick={likeReview} className={`flex flex-row justify-between select-none items-center py-[0.7rem] px-3 cursor-pointer ${!likedReview ? "bg-slate-800 hover:bg-slate-800/70 hover:text-slate-500 text-slate-400" : "bg-emerald-900/70 hover:bg-emerald-900/55 hover:text-emerald-500 text-emerald-400"} rounded-lg w-fit`}>
                    <span className="material-symbols-outlined">
                        favorite
                    </span>
                </div>*/}

                {/*<div className="flex flex-row justify-between select-none items-center bg-slate-800 py-[0.7rem] px-3 cursor-pointer hover:bg-slate-800/70 hover:text-slate-500 rounded-lg w-fit text-slate-400">
                    <span className="font-normal material-symbols-outlined">
                        sticky_note_2
                    </span>
                </div>*/}

                <div onClick={handleCopyReviewLink} className="flex flex-row justify-between select-none items-center bg-slate-800 py-[0.7rem] px-3 cursor-pointer hover:bg-slate-800/70 hover:text-slate-500 rounded-lg w-fit text-slate-400">
                    <span className="material-symbols-outlined">
                        link
                    </span>
                </div>

                {/*<div className="flex flex-row justify-between items-center select-none bg-emerald-950 py-[0.7rem] px-3 cursor-pointer hover:bg-emerald-950/70 hover:text-emerald-600 rounded-lg w-fit text-emerald-500">*/}
                <div onClick={reportReview} className="flex flex-row justify-between select-none items-center bg-slate-800 py-[0.7rem] px-3 cursor-pointer hover:bg-slate-800/70 hover:text-slate-500 rounded-lg w-fit text-slate-400">
                    <span className="material-symbols-outlined">
                        flag
                    </span>
                </div>

            </div>

            {/*<textarea ref={reviewReply} className="w-full h-min bg-transparent border border-slate-700 focus:text-emerald-500 focus:placeholder:text-emerald-500 focus:border-emerald-900 focus:bg-emerald-950/40 rounded-lg p-4 outline-none text-slate-300 text-lg" placeholder={`Reply to ${review?.user_name}'s review...`}></textarea>

            <div className="flex flex-row justify-between items-center">
                <div onClick={postReviewReply} className="select-none flex flex-row bg-transparent border w-fit cursor-pointer px-6 hover:bg-emerald-950/40 hover:text-emerald-500 text-slate-300 hover:border-emerald-900 border-slate-700 rounded-lg p-3">
                    Reply
                </div>
            </div>*/}

            <div onClick={() => window.history.back()} className={`fixed left-[1rem] ${authenticated.authenticated ? "top-[6rem]" : "top-[9rem]"} w-fit cursor-pointer flex flex-row bg-slate-900 z-50 items-center gap-[10px] rounded-lg px-3 py-2 border-[1.5px] border-slate-800 text-slate-400 hover:bg-slate-800`}>

                <span className="material-symbols-outlined">
                    arrow_back
                </span>
                Go Back
            </div>
        </div>
    )
}
