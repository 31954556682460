import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { GoogleOAuthProvider } from "@react-oauth/google";

// Contexts
import { AuthProvider } from "./context/AuthContext";

// React Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Streak from "./pages/Streak";
import Error from "./pages/Error";
import Shelves from "./pages/Shelves";
import ShelfCategory from "./pages/ShelfCategory";
import Discover from "./pages/Discover";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import Tester from "./pages/Tester";
import Settings from "./pages/Settings";
import BookView from "./pages/BookView";
import BookRequestPage from "./pages/BookRequestPage";
import TermsOfService from "./pages/Legal/TermsOfService";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import Landing from "./pages/Landing";
import StatusPage from "./pages/StatusPage";
import NotificationsPage from "./pages/NotificationsPage";

// User Management Components
import LoginPage from "./pages/Authentication/LoginPage";
import SignupPage from "./pages/Authentication/SignupPage";

// Challenge Components
import ChallengePage from "./pages/ChallengePage";
import ViewChallengesPage from "./pages/ViewChallengesPage";

// Book Club Components
import BookClubView from "./pages/BookClub/BookClubView";
import CreateBookClub from "./pages/BookClub/CreateBookClub";
import BookClubListMenu from "./pages/BookClub/BookClubListMenu";
import BookClubJoinPage from "./pages/BookClub/BookClubJoinPage";
import BookClubManageClub from "./pages/BookClub/BookClubManageClub";

// Game Components
import WordSearch from "./pages/games/WordSearch";
import SpineStacker from "./pages/games/SpineStacker";
import CoverPuzzle from "./pages/games/CoverPuzzle";
import GamesList from "./pages/games/GamesList";

// Review Components
import ViewReviewPage from "./pages/Review/ViewReviewPage";

// Admin Components
import ReviewReports from "./pages/Admin/ReviewReports";
import Admin from "./pages/Admin/Admin";

// Form Components
import SuggestFeaturePage from "./pages/Forms/SuggestFeaturePage";

// Vocab
import PracticeVocab from "./pages/Vocab/PracticeVocab";
import LiteraryDevices from "./pages/Vocab/LiteraryDevices";
import LearnVocab from "./pages/Vocab/LearnVocab";

import EmailBlocked from "./pages/EmailBlocked";
import EmailBlockedInvalidKey from "./pages/EmailBlockedInvalidKey";

// Components
import { Toaster } from "./components/ui/sonner";

// Router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <Error />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/streak",
    element: <Streak />,
    errorElement: <Error />,
  },
  {
    path: "/bookshelves",
    element: <Shelves />,
    errorElement: <Error />,
  },
  {
    path: "/bookshelf/:category",
    element: <ShelfCategory />,
    errorElement: <Error />,
  },
  {
    path: "/book",
    element: <BookView />,
    errorElement: <Error />,
  },
  {
    path: "/status",
    element: <StatusPage />,
    errorElement: <Error />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <Error />,
  },
  {
    path: "/search",
    element: <Search />,
    errorElement: <Error />,
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <Error />,
  },
  {
    path: "/settings",
    element: <Settings />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/create",
    element: <CreateBookClub />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/view",
    element: <BookClubView />,
    errorElement: <Error />,
  },
  {
    path: "/notifications",
    element: <NotificationsPage />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub",
    element: <BookClubListMenu />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/join",
    element: <BookClubJoinPage />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/manage",
    element: <BookClubManageClub />,
    errorElement: <Error />,
  },
  {
    path: "/bookrequest",
    element: <BookRequestPage />,
    errorElement: <Error />,
  },
  {
    path: "/legal/terms-of-service",
    element: <TermsOfService />,
    errorElement: <Error />,
  },
  {
    path: "/legal/privacy",
    element: <PrivacyPolicy />,
    errorElement: <Error />,
  },
  {
    path: "/challenge/create",
    element: <ChallengePage />,
    errorElement: <Error />,
  },
  {
    path: "/challenge",
    element: <ViewChallengesPage />,
    errorElement: <Error />,
  },
  {
    path: "/review",
    element: <ViewReviewPage />,
    errorElement: <Error />,
  },
  {
    path: "/games/wordsearch",
    element: <WordSearch />,
    errorElement: <Error />,
  },
  {
    path: "/games/spinestacker",
    element: <SpineStacker />,
    errorElement: <Error />,
  },
  {
    path: "/games/coverpuzzle",
    element: <CoverPuzzle />,
    errorElement: <Error />,
  },
  {
    path: "/forms/feature",
    element: <SuggestFeaturePage />,
    errorElement: <Error />,
  },
  {
    path: "/games",
    element: <GamesList />,
    errorElement: <Error />,
  },
  {
    path: "/admin/reviewReports",
    element: <ReviewReports />,
    errorElement: <Error />,
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <Error />,
  },
  {
    path: "/vocab/practice",
    element: <PracticeVocab />,
    errorElement: <Error />,
  },
//   {
//     path: "/vocab/learn",
//     element: <LearnVocab />,
//     errorElement: <Error />,
//   },
  {
    path: "/vocab/devices",
    element: <LiteraryDevices />,
    errorElement: <Error />,
  },
  {
    path: "/emailblocked",
    element: <EmailBlocked />,
    errorElement: <Error />,
  },
  {
    path: "/emailblockedinvalidkey",
    element: <EmailBlockedInvalidKey />,
    errorElement: <Error />,
  },
  {
    path: "*",
    element: <Error />,
  },
]);

// Render the application
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="bg-nord-900 min-h-screen h-full text-slate-50">
    <GoogleOAuthProvider clientId="337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com">
      <AuthProvider>
        <Toaster richColors expand={true} />
        <RouterProvider router={router} />
      </AuthProvider>
    </GoogleOAuthProvider>
  </div>
);
