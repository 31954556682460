import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

import Viewer from "./Viewer";
import Chip from "./Chip";
import Input from "./Input";

import WordSearchIcon from "../assets/wordsearch.png";
import bgImg from "../assets/bg.png";

import RecommendedCarousel from "./Feed/RecommendedCarousel";
import FeedStyledCard from "./Feed/FeedStyledCard";

import ProfilePicture from "./ProfilePicture";

import ChallengeCardSm from "./ChallengeCardSm";

import { toast } from "sonner";

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "./ui/carousel";

function Feed() {
	const { authenticated, login, logout, signup } = useAuth();

	const [booksReadNum, setBooksReadNum] = useState("...");
	const [bookmarksNum, setBookmarksNum] = useState("...");
	const [reviewsNum, setReviewsNum] = useState("...");

	//const [bookData, setBookData] = useState([]);
	const [readList, setReadList] = useState([]);
	const [bookmarkedList, setBookmarkedList] = useState([]);
	const [booksReviewed, setBooksReviewed] = useState([]);

	const [updateHomeFeedStatsState, setUpdateHomeFeedStatsState] =
		useState(false);

	function updateHomeFeedStats() {
		// check if the user is on the home page
		if (window.location.pathname === "/home") {
			// if so, update the home feed stats
			setUpdateHomeFeedStatsState(!updateHomeFeedStatsState);
		}
	}

	useEffect(() => {
		async function getUserBookStats() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					//console.log("user stats");
					//console.log(data);
					setBooksReadNum(data.books_read.length);
					setBookmarksNum(data.books_bookmarked.length);
					setReviewsNum(data.books_reviewed.length);

					setReadList(data.books_read);
					setBookmarkedList(data.books_bookmarked);
					setBooksReviewed(data.books_reviewed);

					console.log(data.books_reviewed);
				})
				.catch((err) => console.log(err));
		}

		if (authenticated.authenticated) {
			//console.log(authenticated);
			getUserBookStats();
		}
	}, [authenticated.authenticated, updateHomeFeedStatsState]);

	// trending books

	const [trendingBooks, setTrendingBooks] = useState([]);
	const [trendingReviews, setTrendingReviews] = useState([]);

	useEffect(() => {
		const getTrendingBooksAndReviews = async () => {
			try {
				const response = await fetch(
					`https://nerded.io/api/trending/get`,
				);
				const data = await response.json();

				setTrendingBooks(data.trending_books);
				setTrendingReviews(data.trending_reviews);
			} catch (err) {
				console.error(
					"Failed to fetch trending books and reviews:",
					err,
				);
			}
		};

		getTrendingBooksAndReviews();
	}, []);

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const [streakInfo, setStreakInfo] = useState({
		current_streak: 0,
		longest_streak: 0,
		last_updated: 0,
		date_longest_streak_started: 0,
		date_current_streak_started: 0,
	});

	const [levelingData, setLevelingData] = useState({
		level: 0,
		experience: 0,
		experience_to_next_level: 0,
		current_league: "unranked",
		highest_league: "unranked",
		current_rank: 0,
	});

	useEffect(() => {
		async function getStreakData() {
			await fetch("https://nerded.io/api/streaks/view_user_streak", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.requestStatus === "error") {
						if (data?.relog) {
							toast.error("Please login again!");
							logout();
						}

						//toast.error(data.error);
						console.log(data.error);
						return;
					}

					setStreakInfo(data.streaks);
					setLevelingData(data.leveling);
				});
		}

		if (authenticated.authenticated) {
			getStreakData();
		}
	}, [authenticated.userId, authenticated.sessionToken]);

	const [activeChallenges, setActiveChallenges] = useState([]);
	const [completedChallenges, setCompletedChallenges] = useState([]);

	const [anyActiveChallenges, setAnyActiveChallenges] = useState(false);

	useEffect(() => {
		async function fetchChallenges() {
			await fetch("https://nerded.io/api/challenges/view", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: authenticated.userId,
					session_token: authenticated.sessionToken,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					setActiveChallenges(data.challenges_active);
					setCompletedChallenges(data.challenges_ended);

					if (data.challenges_active.length > 0) {
						if (
							data.challenges_active[0].challenge_due_date *
								1000 <
							Date.now()
						) {
							setAnyActiveChallenges(false);
						} else {
							setAnyActiveChallenges(true);
						}
					}
				})
				.catch((error) => {
					toast.error("Failed to fetch challenges");
				});
		}

		if (authenticated.authenticated) {
			fetchChallenges();
		}
	}, [authenticated]);

	const streakChecks = [];
	const today = new Date();
	const streakStartDate = new Date(
		streakInfo.date_current_streak_started * 1000,
	);
	const streakDays = streakInfo.current_streak;

	// Calculate the start of the current week (Sunday)
	const currentWeekStart = new Date(today);
	const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
	currentWeekStart.setDate(today.getDate() - dayOfWeek);
	currentWeekStart.setHours(0, 0, 0, 0);

	// Calculate the days from the start of the current week to today
	const daysFromStartOfWeek = dayOfWeek + 1; // Today is included

	let sundayYes = false;
	let mondayYes = false;
	let tuesdayYes = false;
	let wednesdayYes = false;
	let thursdayYes = false;
	let fridayYes = false;
	let saturdayYes = false;

	// Fill the streakChecks array
	for (let i = 0; i < 7; i++) {
		const checkDate = new Date(currentWeekStart);
		checkDate.setDate(currentWeekStart.getDate() + i);

		if (i < daysFromStartOfWeek) {
			const dayIndexFromStart = i - (daysFromStartOfWeek - streakDays);
			if (dayIndexFromStart >= 0 && dayIndexFromStart < streakDays) {
				streakChecks.push(
					<div
						className="text-amber-500 h-fit w-fit rounded-full border-[2px] bg-amber-900 p-3 border-amber-500 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
						key={i}
					>
						<span className="material-symbols-outlined text-amber-400 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
							local_fire_department
						</span>
					</div>,
				);

				switch (i) {
					case 0:
						sundayYes = true;
						break;
					case 1:
						mondayYes = true;
						break;
					case 2:
						tuesdayYes = true;
						break;
					case 3:
						wednesdayYes = true;
						break;
					case 4:
						thursdayYes = true;
						break;
					case 5:
						fridayYes = true;
						break;
					case 6:
						saturdayYes = true;
						break;
				}
			} else {
				streakChecks.push(
					<div
						className="text-sky-500 h-fit w-fit rounded-full border-[2px] bg-nord-900/30 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
						key={i}
					>
						<span className="material-symbols-outlined text-sky-500 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
							local_fire_department
						</span>
					</div>,
				);

				switch (i) {
					case 0:
						sundayYes = false;
						break;
					case 1:
						mondayYes = false;
						break;
					case 2:
						tuesdayYes = false;
						break;
					case 3:
						wednesdayYes = false;
						break;
					case 4:
						thursdayYes = false;
						break;
					case 5:
						fridayYes = false;
						break;
					case 6:
						saturdayYes = false;
						break;
				}
			}
		} else {
			streakChecks.push(
				<div
					className="text-gray-500 h-fit w-fit rounded-full border-[2px] bg-nord-900/30 p-3 border-sky-600 text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold flex items-center"
					key={i}
				>
					<span className="material-symbols-outlined text-sky-500 text-[1.5rem] lg:text-[3rem] rounded-full font-normal">
						local_fire_department
					</span>
				</div>,
			);

			switch (i) {
				case 0:
					sundayYes = false;
					break;
				case 1:
					mondayYes = false;
					break;
				case 2:
					tuesdayYes = false;
					break;
				case 3:
					wednesdayYes = false;
					break;
				case 4:
					thursdayYes = false;
					break;
				case 5:
					fridayYes = false;
					break;
				case 6:
					saturdayYes = false;
					break;
			}
		}
	}

	const allYes =
		sundayYes &&
		mondayYes &&
		tuesdayYes &&
		wednesdayYes &&
		thursdayYes &&
		fridayYes &&
		saturdayYes;

	return (
		<div className="flex items-center flex-col gap-[1.5rem] pt-[1rem] bg-nord-900">
			<div className="flex flex-row overflow-y-scroll w-[90vw] mb-[1rem] h-fit">
				<Carousel className="lg:w-[80%] md:w-[80%] w-[70%] ml-auto mr-auto">
					<CarouselContent className="">
						<CarouselItem className="basis-1/10">
							<Chip name="Fiction" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Non-fiction" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Poetry" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Short Stories" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Fantasy" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Sci-fi" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Romance" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Horror" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Mystery" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Thriller" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Biography" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Autobiography" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="History" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Philosophy" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Self-help" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Cooking" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Travel" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Children" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Young Adult" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Comics" />
						</CarouselItem>

						<CarouselItem className="basis-1/10">
							<Chip name="Manga" />
						</CarouselItem>
					</CarouselContent>

					<CarouselPrevious className="rounded mt-[-12px] bg-nord-800 border-none hover:bg-nord-700/40" />
					<CarouselNext className="rounded bg-nord-800 border-none hover:bg-nord-700/40" />
				</Carousel>
			</div>

			<div className="md:grid lg:grid xl:grid xl:grid-cols-7 mx-[1.2rem] lg:grid-cols-7 md:grid-cols-7 sm:grid-cols-1 gap-[2rem] md:gap-[1rem] lg:gap-[2rem] xl:gap-[2rem] 2xl:gap-[2rem] max-w-[75rem]">
				<div className="w-full col-span-2">
					<div className="flex items-center flex-col">

						<div className="flex flex-col gap-[0.8rem] p-[10px] bg-nord-800 rounded-lg w-full border-[1.5px] border-nord-700/50">
							<div className="flex flex-row justify-around gap-2 w-full flex-nowrap">
								{booksReadNum !== "..." ? (
									<Link
										to="/profile?tab=read"
										className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center"
									>
										<span className="w-max">
											Books Read
										</span>
										<span className="text-[1.3rem]">
											{booksReadNum}
										</span>
									</Link>
								) : (
									<div className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
										<span className="w-max">
											Books Read
										</span>
										<span className="text-[1.3rem]">0</span>
									</div>
								)}

								{bookmarksNum !== "..." ? (
									<Link
										to="/profile?tab=bookmarked"
										className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center"
									>
										<span className="w-max">
											To Be Read
										</span>
										<span className="text-[1.3rem]">
											{bookmarksNum}
										</span>
									</Link>
								) : (
									<div className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
										<span className="w-max">
											To Be Read
										</span>
										<span className="text-[1.3rem]">0</span>
									</div>
								)}

								{reviewsNum !== "..." ? (
									<Link
										to="/profile?tab=reviewed"
										className="md:hidden lg:flex sm:flex xl:flex flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center"
									>
										<span className="w-max">Reviews</span>
										<span className="text-[1.3rem]">
											{reviewsNum}
										</span>
									</Link>
								) : (
									<div className="md:hidden lg:flex sm:flex xl:flex flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
										<span className="w-max">Reviews</span>
										<span className="text-[1.3rem]">0</span>
									</div>
								)}
							</div>

							{authenticated.authenticated ? (
								<div className="flex flex-row gap-2 border-t border-t-nord-700/50 pt-[1rem]">
									<Link
										to="/streak"
										className="flex flex-row items-center gap-2 md:hidden lg:flex xl:flex 2xl:flex w-min text-[1.15rem] text-slate-400 bg-nord-800/50 border-2 border-nord-700/80 px-[14px] p-3 rounded-lg"
									>
										<span className="material-symbols-outlined text-[1.5rem] mt-[-3px]">
											local_fire_department
										</span>
									</Link>

									<Link
										to="/streak"
										className="flex flex-row items-center w-full gap-3 text-[1.15rem] text-slate-400 bg-nord-800/50 border-2 border-nord-700/80 p-3 rounded-lg"
									>
										<div className="flex flex-row justify-between w-full">
											<span className="decoration-wavy decoration-2">
												My Streak
											</span>
										</div>

										<span className="flex min-w-fit break-words">
											{streakInfo.current_streak} days
										</span>
									</Link>
								</div>
							) : null}
						</div>

                        <Link
                            to="/vocab/practice"
                            className="w-full mt-4 hover:bg-pink-700/35 group transition-all justify-between flex-row flex items-center border-[1.5px] rounded-lg bg-pink-800/30 pl-3 pr-3 py-3 border-pink-700/50"
                        >
                            <span className="w-full gap-[14px] h-full flex items-center mb-[-1px] text-[1.05rem]">

                                <span className="material-symbols-outlined border border-pink-800 text-[1.6rem] text-white rounded-lg p-2 bg-pink-900">
                                    book
                                </span>

                                <span className="flex items-center gap-2 text-[1.1rem]">
                                    <span>Language Practice</span>
                                </span>

                                <div className="rounded-full ml-auto px-3 py-[2px] text-[0.9rem] bg-pink-900 flex items-center justify-center">
                                    New
                                </div>
                            </span>

                            {/* <div className="flex justify-center items-center flex-row gap-2 bg-pink-950 text-pink-400 border-pink-700 border rounded-lg h-[40px] w-[40px] transform transition-transform duration-300 ml-[5px] group-hover:scale-105">
                                <span className="material-symbols-outlined">bolt</span>
                            </div> */}
                        </Link>

                        <Link
                            to="/games"
                            className="w-full mt-4 hover:bg-green-700/35 group transition-all justify-between flex-row flex items-center border-[1.5px] rounded-lg bg-green-800/30 pl-3 pr-3 py-3 border-green-700/50"
                        >
                            <span className="w-full gap-[14px] h-full flex items-center mb-[-1px] text-[1.05rem]">

                                <span className="material-symbols-outlined border border-green-800 text-[1.6rem] text-white rounded-lg p-2 bg-green-900">
                                    extension
                                </span>

                                <span className="flex items-center gap-2 text-[1.1rem]">
                                    <span>Nerded Games</span>
                                </span>

                                <div className="rounded-full ml-auto px-3 py-[2px] text-[0.9rem] bg-green-800 flex items-center justify-center">
                                    New
                                </div>
                            </span>

                            {/* <div className="flex justify-center items-center flex-row gap-2 bg-pink-950 text-pink-400 border-pink-700 border rounded-lg h-[40px] w-[40px] transform transition-transform duration-300 ml-[5px] group-hover:scale-105">
                                <span className="material-symbols-outlined">bolt</span>
                            </div> */}
                        </Link>

						{/*{authenticated.authenticated ? <div className="flex flex-col gap-[0.8rem] p-[10px] mt-6 bg-slate-800 rounded-lg w-full border-[2px] border-nord-700">
                            <div className="flex rounded-md flex-row w-full justify-between text-[1rem] items-center">

                                <span className="font-semibold text-[1.1rem] ml-2">
                                    Current Streak
                                </span>

                                <div className="flex flex-row items-center gap-2 bg-slate-950 rounded-lg px-3 py-2">
                                    <span className="material-symbols-outlined text-[1.8rem] text-emerald-500">
                                        local_fire_department
                                    </span>

                                    <span className="text-[1.3rem] mr-1">
                                        {streakInfo.current_streak}
                                    </span>
                                </div>
                            </div>
                        </div> : null}*/}

                        <div className="bg-nord-700/30 h-[1px] w-full mt-4"></div>

						{authenticated.authenticated ? (
							<div className="w-full">
								{anyActiveChallenges ? (
									<div className="mt-4 w-full">
										{activeChallenges.map((challenge) => {
											let jsEndDate = new Date(
												challenge.challenge_due_date *
													1000,
											);
											if (jsEndDate >= Date.now()) {
												return (
													<ChallengeCardSm
														name={
															challenge.challenge_name
														}
														booksRead={
															challenge.books_read
														}
														challengeTotal={
															challenge.num_books
														}
														endDate={
															challenge.challenge_due_date
														}
														id={challenge._id}
													/>
												);
											}
										})}
									</div>
								) : (
									<div className="flex text-center items-center flex-row gap-[0.4rem] p-[10px] mt-4 bg-nord-800 rounded-lg w-full justify-center border-[1.5px] border-nord-700/50">
										<span className="material-symbols-outlined mb-[-1px] text-[1.4rem] text-slate-200">
											info
										</span>

										<span className="text-zinc-100">
											No active challenges
										</span>
									</div>
								)}
							</div>
						) : null}

						<div className="hidden md:flex lg:flex xl:flex flex-col gap-4 items-center mt-[2rem] w-full">
							<span>Trending Reviews</span>

							{trendingReviews.map((item) => {
								console.log(item);

								return (
									<Link
										to={`/review?isbn=${item.data.volumeInfo.industryIdentifiers[0].identifier}&id=${item.review.review_id}`}
										key={item.review.review_id}
										className="flex flex-col p-4 gap-3 bg-nord-800 transition hover:border-nord-700 border-[1.5px] border-nord-700/50 rounded-lg w-full"
									>
										<span className="flex flex-row gap-3 items-center">
											{/*<img src={item.review.user_picture.replace("http://", "https://")} referrerPolicy="no-referrer" className="w-[2rem] h-[2rem] rounded-full" />*/}
											<ProfilePicture
												className="w-[2rem] h-[2rem] rounded-full"
												size={32}
												seed={
													item.review
														.picture_generation_details
														?.seed
												}
												variant={
													item.review
														.picture_generation_details
														?.variant
												}
												isDefault={
													item.review
														?.picture_is_default
												}
												picture={item.review.user_picture?.replace(
													"http://",
													"https://",
												)}
												colors={
													item.review
														?.picture_generation_details
														?.colors
												}
											/>

											<span className="text-[1.1rem]">
												{console.log(item.review)}
												{item.review.user_name}
											</span>

											<span className="flex items-center gap-1 font-normal ml-auto bg-nord-700/25 rounded-lg text-slate-300 py-1 px-2">
												{item.review.rating}{" "}
												<span className="mt-[-2px] flex items-center">
													<ion-icon name="star"></ion-icon>
												</span>
											</span>
										</span>

										<div className="flex flex-row w-full justify-between items-center gap-4">
											<span className="gap-3 text-[1rem] truncate font-normal">
												{item.data.volumeInfo.title} by{" "}
												{item.data.volumeInfo.authors.join(
													", ",
												)}
											</span>
										</div>

										{/*{item.review.review ?
                                        <div className="line-clamp-4 pl-3 bg-slate-950/20 rounded-md p-2">
                                            {item.review.review}
                                        </div>
                                    : null}*/}
									</Link>
								);
							})}
						</div>
					</div>
				</div>

				<div
					className={`flex flex-col space-y-4 items-center col-span-5 mt-8 md:mt-0 lg:mt-0 xl:mt-0`}
				>
					{authenticated.authenticated ? <div
						className={`w-full flex flex-col ${
							allYes
								? "bg-amber-950/90 border-amber-700/50"
								: "bg-sky-950/70 border-sky-700/50"
						} rounded-xl border-2 p-4 relative`}
					>
						<div
							className={`absolute inset-0 ${
								allYes ? "bg-amber-900" : "bg-sky-950"
							} pointer-events-none`}
							style={{
								backgroundImage: `url(${bgImg})`,
								backgroundBlendMode: "overlay", // Ensures the background color blends with the image
								zoom: "1.5",
								opacity: "0.12",
							}}
						/>
						<div className="relative z-[1]">
							<div className="flex flex-col gap-3 px-3 py-2 rounded-lg">
								<div className="flex flex-row flex-wrap mx-0 lg:mx-[0.6rem] gap-1 justify-between items-center">
									{streakChecks}
								</div>

								<div className="flex flex-row text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg gap-0 md:gap-4 items-center w-full justify-between">
									<span className="flex justify-center w-full">
										<span
											className={`${
												sundayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Sun
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												mondayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Mon
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												tuesdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Tue
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												wednesdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Wed
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												thursdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Thu
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												fridayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Fri
										</span>
									</span>

									<span className="w-full flex justify-center">
										<span
											className={`${
												saturdayYes
													? "bg-amber-700"
													: "bg-sky-700"
											} px-3 py-[2px] rounded-xl w-full lg:w-fit text-center mb-[-5px]`}
										>
											Sat
										</span>
									</span>
								</div>
							</div>
						</div>
					</div> : null}

					{trendingBooks.length > 0 ? (
						trendingBooks.map((book) => {
							//console.log(book);
							//console.log(book.data.volumeInfo.title);
							return (
								<Viewer
									key={
										book?.data?.volumeInfo
											?.industryIdentifiers[0]?.identifier
									}
									bookData={book?.data?.volumeInfo}
									reviews={book?.reviews}
									title={book?.data?.volumeInfo?.title}
									description={
										book?.data?.volumeInfo?.description
									}
									author={book?.data?.volumeInfo?.authors}
									cover={
										book?.data?.volumeInfo?.imageLinks
											?.thumbnail
									}
									isbn={
										book.data.volumeInfo
											.industryIdentifiers[0].identifier
									}
									rating={book.data.volumeInfo.averageRating}
									readList={readList}
									bookmarkedList={bookmarkedList}
									booksReviewed={booksReviewed}
									updateHomeFeedStats={updateHomeFeedStats}
								/>
							);
						})
					) : (
						<div className="w-full bg-nord-800 border-2 border-nord-700/50 p-3 rounded-lg text-[1.2rem] flex justify-center">
							Loading...
						</div>
					)}
				</div>
			</div>

			<div className="grid-new w-full">
				<div
					className="w-full h-[5rem]"
					style={{
						background:
							"linear-gradient(to bottom, rgba(6, 14, 29, 1), rgba(6, 14, 29, 0))",
					}}
				></div>

				<div className="">
					<div>
						<h1 className="text-4xl text-slate-300 pb-[0.5rem] font-bold mx-auto w-fit bg-gradient-to-b from-blue-100 to-blue-300 bg-clip-text text-transparent">
							Games
						</h1>
					</div>

					<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-[95vw] md:max-w-[95vw] lg:max-w-[90vw] xl:max-w-[75vw] 2xl:max-w-[75vw] w-fit rounded-lg mx-auto mt-[2rem] mb-[4rem] gap-[2rem]">
						<div className="flex flex-row gap-4 bg-nord-900 justify-center p-3 w-[17rem] rounded-lg border border-nord-700/50 shadow-xl">
							<div className="flex flex-col gap-2 items-center w-full">
								<h2 className="text-xl text-slate-100 mt-1">
									Word Search
								</h2>
								<img
									src={WordSearchIcon}
									alt="Word Search"
									className="w-[12rem] h-[12rem]"
								/>
								<Link
									to="/games/wordsearch"
									className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-nord-700/50 transition hover:bg-nord-700/15 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>

						<div className="flex flex-row gap-4 bg-nord-900 justify-center p-3 w-[17rem] rounded-lg border border-nord-700/50 shadow-xl">
							<div className="flex flex-col gap-2 items-center w-full">
								<h2 className="text-xl text-slate-100 mt-1">
									Cover Jigsaw
								</h2>

								<div className="grid grid-cols-3 gap-0">
									<div></div>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mb-[-1.9rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mb-[-1.9rem] ml-[-3.7rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mr-[-3.8rem] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] flex justify-center">
										extension
									</span>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] ml-[-3.8rem] flex justify-center">
										extension
									</span>
									<div></div>
									<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mt-[-1.9rem] flex justify-center">
										extension
									</span>
									<div></div>
									<div></div>
								</div>

								<Link
									to="/games/coverpuzzle"
									className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-nord-700/50 transition hover:bg-nord-700/15 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>

						<div className="flex flex-row col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mx-auto gap-4 bg-nord-900 justify-center p-3 w-[17rem] rounded-lg border border-nord-700/50 shadow-xl">
							<div className="flex flex-col gap-2 items-center w-full">
								<h2 className="text-xl text-slate-100 mt-1">
									Spine Stacker
								</h2>

								<div className="flex flex-col gap-[4px] w-[85%] justify-end h-full mb-2 items-center">
									<div className="flex flex-row gap-1 bg-blue-600/70 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-yellow-600/70 h-[2rem] p-2 w-[90%] ml-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-green-600/70 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
									<div className="flex flex-row gap-1 bg-red-600/70 h-[2rem] p-2 w-[90%] ml-[10%] rounded py-3"></div>
								</div>

								<Link
									to="/games/spinestacker"
									className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-nord-700/50 transition hover:bg-nord-700/15 cursor-pointer"
								>
									Play
								</Link>
							</div>
						</div>
					</div>

					<div className="mt-[-1.2rem] mb-[2.5rem] justify-center flex mx-[2rem] text-center text-wrap break-words flex-row text-[2rem] font-semibold bg-gradient-to-b from-purple-100 to-purple-300 bg-clip-text text-transparent">
						Recommended Reads
					</div>

					<div className="w-[70vw] ml-auto mr-auto mb-[2rem]">
						<RecommendedCarousel />
					</div>

					<div className="mt-[2.7rem] mb-[3rem] w-full justify-center flex flex-row text-[2rem] font-semibold bg-gradient-to-b from-rose-100 to-rose-300 bg-clip-text text-transparent">
						Quick Links
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 items-center gap-[1rem] max-w-[92vw] w-fit ml-auto mr-auto">
						<FeedStyledCard
							title="Discover Book Clubs"
							description="Discover and create book clubs with your friends and family and share your favorite books with each other."
							colorL="from-green-300"
							colorM="via-blue-500"
							colorR="to-purple-600"
						>
							<Link
								to="/bookclub"
								className="relative inline-block p-px font-semibold leading-6 mt-3 text-white no-underline bg-nord-950 shadow-2xl cursor-pointer group rounded-xl shadow-zinc-900"
							>
								<span className="absolute inset-0 overflow-hidden rounded-xl">
									<span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
								</span>
								<div className="relative flex items-center px-6 py-3 space-x-2 rounded-xl bg-gray-950/50 ring-1 ring-white/15">
									<span>See Your Book Clubs</span>
									<svg
										xmlns="https://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
										data-slot="icon"
										className="w-6 h-6"
									>
										<path
											fillRule="evenodd"
											d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
											clipRule="evenodd"
										></path>
									</svg>
								</div>
								<span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-gray-400/90 to-emerald-400/0 transition-opacity duration-300 group-hover:opacity-40"></span>
							</Link>
						</FeedStyledCard>

						<FeedStyledCard
							title="Explore Bookshelves"
							description="Browse through our wide selection of book genres and discover your next literary adventure."
							colorL="from-yellow-300"
							colorM="via-orange-500"
							colorR="to-red-600"
						>
							<Link
								to="/bookshelves"
								className="relative inline-block p-px font-semibold leading-6 mt-3 text-white no-underline bg-nord-950 shadow-2xl cursor-pointer group rounded-xl shadow-zinc-900"
							>
								<span className="absolute inset-0 overflow-hidden rounded-xl">
									<span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(238,125,2,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
								</span>
								<div className="relative flex items-center px-6 py-3 space-x-2 rounded-xl bg-gray-950/50 ring-1 ring-white/15 ">
									<span>Explore Bookshelves</span>
									<svg
										xmlns="https://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
										data-slot="icon"
										className="w-6 h-6"
									>
										<path
											fillRule="evenodd"
											d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
											clipRule="evenodd"
										></path>
									</svg>
								</div>
								<span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-gray-400/90 to-emerald-400/0 transition-opacity duration-300 group-hover:opacity-40"></span>
							</Link>
						</FeedStyledCard>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Feed;
