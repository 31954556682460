import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { toast } from "sonner";

import { useEffect, useRef, useState } from "react";

import Avatar from "boring-avatars";

import EditPrivacySheet from "../components/Settings/EditPrivacySheet";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../components/ui/select";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../components/ui/dialog";

import ProfilePicture from "../components/ProfilePicture";
import Cookies from "js-cookie";

import RankBadge from "../components/RankBadge";

function Settings() {
	const { authenticated, login, logout } = useAuth();
	const Navigate = useNavigate();

	if (!authenticated.authenticated) {
		login();
	}

	function goBack() {
		window.history.back();
	}

	const [username, setUsername] = useState(authenticated.name);

	const [officialUsername, setOfficialUsername] = useState(
		authenticated.name,
	);

	function openDialog() {
		setUsername(authenticated.name);
	}

	function setAuthenticatedCookie(data) {
		const cookieName = "authenticated";
		const cookieValue = data;
		const daysToExpire = 21; // Set cookie to expire in 7 days

		const date = new Date();
		date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
		const expires = "expires=" + date.toUTCString();

		document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
	}

	async function updateUsername() {
		if (username === "") {
			toast.error("Username cannot be empty");
			return;
		}

		if (username.length > 20) {
			toast.error("Username cannot be longer than 20 characters");
			return;
		}

		setOfficialUsername("Loading...");

		await fetch("https://nerded.io/api/settings/editusername", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				session_token: authenticated.sessionToken,
				user_id: authenticated.userId,
				username: username,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.requestStatus === "error") {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					toast.error(data.error);
					setOfficialUsername(authenticated.name);
					return;
				}

				toast.success(data.message);

				authenticated.name = username;
				setOfficialUsername(username);

				setAuthenticatedCookie(JSON.stringify(authenticated));
			});
	}

	const [selectedOption, setSelectedOption] = useState("custom");

	useEffect(() => {
		setSelectedOption(
			authenticated.profilePictureIsDefault ? "custom" : "generated",
		);
	}, []);

	const handleOptionChange = (e) => {
		setSelectedOption(e.target.value);
	};

	const [variant, setVariant] = useState(
		authenticated?.picture_generation_details?.variant,
	);
	const [seed, setSeed] = useState(
		authenticated?.picture_generation_details?.seed,
	);
	const [colors, setColors] = useState(
		authenticated?.picture_generation_details?.colors,
	);

	function pickVariant(variant) {
		setVariant(variant);
	}

	function pickColor(color, index) {
		let newColors = [...colors]; // Create a new array by copying the existing one
		newColors[index] = color; // Update the color at the specific index
		setColors(newColors); // Set the new array as the state
	}

	function editSeed(seed) {
		setSeed(seed.target.value);
	}

	const variantMapping = {
		avatar: "beam",
		pixel: "pixel",
		sunset: "sunset",
		rings: "ring",
		abstract: "bauhaus",
	};

	const [profilePicture, setProfilePicture] = useState(authenticated.picture);
	const [profilePictureIsDefault, setProfilePictureIsDefault] = useState(
		authenticated.picture_is_default,
	);
	const [
		profilePictureGenerationDetails,
		setProfilePictureGenerationDetails,
	] = useState(authenticated.picture_generation_details);

	async function updateProfilePicture() {
		await fetch("https://nerded.io/api/users/edit_picture", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				session_token: authenticated.sessionToken,
				user_id: authenticated.userId,
				picture: authenticated.picture,
				picture_is_default: selectedOption === "custom" ? true : false,
				variant: variant,
				seed: seed,
				colors: colors,
				new_picture_type:
					selectedOption === "custom" ? "custom" : "generated",
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.requestStatus === "error") {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					toast.error(data.error);
					return;
				}

				authenticated.picture = data.picture;
				authenticated.picture_is_default =
					selectedOption === "custom" ? true : false;
				authenticated.picture_generation_details = {
					variant: variant,
					seed: seed,
					colors: colors,
				};

				if (selectedOption === "custom") {
					setProfilePictureIsDefault(true);
					authenticated.picture = data.picture_url;
				}

				setProfilePicture(data.picture_url);
				setProfilePictureIsDefault(
					selectedOption === "custom" ? true : false,
				);
				setProfilePictureGenerationDetails({
					variant: variant,
					seed: seed,
					colors: colors,
				});

				Cookies.set("authenticated", JSON.stringify(authenticated), {
					expires: 21,
				});

				toast.success(data.message);
			});
	}

	function randomizeSeed() {
		const seed = Math.floor(Math.random() * 1000000000).toString();
		setSeed(seed);
	}

	function hexColorGenerator() {
		// 6 chars not 5
		return (
			"#" +
			Math.floor(Math.random() * 16777215)
				.toString(16)
				.padStart(6, "0")
		);
	}

	function randomizeColors() {
		const randomColor1 = hexColorGenerator();
		const randomColor2 = hexColorGenerator();
		const randomColor3 = hexColorGenerator();
		const randomColor4 = hexColorGenerator();
		const randomColor5 = hexColorGenerator();

		setColors([
			randomColor1,
			randomColor2,
			randomColor3,
			randomColor4,
			randomColor5,
		]);
	}

	function capitalize(s) {
		if (typeof s !== "string") return "";
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

    const [currentProfilePrivacy, setCurrentProfilePrivacy] = useState(authenticated.profile_privacy);

    useEffect(() => {
        async function loadCurrentPrivacySetting() {
            await fetch("https://nerded.io/api/users/get_profile_privacy", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_token: authenticated.sessionToken,
                    user_id: authenticated.userId,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.requestStatus === "error") {
                        if (data?.relog) {
                            toast.error("Please login again!");
                            logout();
                        }

                        toast.error(data.error);
                        return;
                    }

                    setCurrentProfilePrivacy(data.profile_privacy);
                });
            }

            loadCurrentPrivacySetting();
    }, []);

    async function updateProfilePrivacy() {
        await fetch("https://nerded.io/api/users/edit_profile_privacy", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_token: authenticated.sessionToken,
                user_id: authenticated.userId,
                profile_privacy: currentProfilePrivacy,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                    toast.error(data.error);
                    return;
                }

                authenticated.profile_privacy = currentProfilePrivacy;

                setAuthenticatedCookie(JSON.stringify(authenticated));

                toast.success(data.message);
            });
    }

	return (
		<div className="grid-new">
			<Navbar
				showAccountPrompt={false}
				profilePictureGenerationDetails={
					profilePictureGenerationDetails
				}
				profilePicture={profilePicture}
				profilePictureIsDefault={profilePictureIsDefault}
			/>

			<div className="flex flex-row gap-2 ml-[2rem] cursor-pointer">
				<div
					onClick={goBack}
					className="rounded-lg py-2 px-3 bg-nord-800 hover:bg-nord-hover transition flex flex-row items-center"
				>
					<div className="text-slate-300 text-[1.1rem] flex flex-row items-center">
						<span className="material-symbols-outlined items-center">
							arrow_back
						</span>
					</div>
				</div>
			</div>

			<div className="flex mt-[2rem] mb-[4rem] flex-col gap-2 p-4 bg-nord-900 border-2 border-slate-700 rounded-lg w-[95vw] md:w-[32rem] lg:w-[32rem] xl:w-[32rem] 2xl:w-[32rem] ml-auto mr-auto">
				<div className="flex flex-row items-center">
					<div className="flex flex-row gap-4 w-fit h-fit items-center">
						<ProfilePicture
							variant={profilePictureGenerationDetails?.variant}
							seed={profilePictureGenerationDetails?.seed}
							colors={profilePictureGenerationDetails?.colors}
							isDefault={profilePictureIsDefault}
							picture={profilePicture}
							size="60"
							className="rounded-full w-[60px] h-[60px]"
						/>

						<span className="text-2xl">{officialUsername}</span>

						{authenticated.rank ? (
							<RankBadge rank={authenticated.rank} />
						) : null}
					</div>
				</div>

				<div className="flex flex-row gap-2 pt-3 border-t mt-1 border-t-slate-800">
					<Dialog>
						<DialogTrigger>
							<div
								onClick={openDialog}
								className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-2 px-3 items-center transition hover:bg-nord-hover"
							>
								<span className="material-symbols-outlined text-slate-300 text-[1.2rem]">
									edit
								</span>
								<span className="text-slate-300 text-[1.1rem]">
									Username
								</span>
							</div>
						</DialogTrigger>
						<DialogContent
							aria-describedby={""}
							className="max-w-[95vw] w-[32rem] rounded-lg"
						>
							<DialogHeader>
								<DialogTitle>Edit your Username</DialogTitle>
							</DialogHeader>

							<div className="pt-[0.4rem]">
								<input
									type="text"
									placeholder={username}
									onChange={(e) =>
										setUsername(e.target.value)
									}
									className="w-full border border-slate-700 focus:z-10 bg-nord-950/30 outline-none focus:border-slate-600 p-3 rounded-lg"
								/>
							</div>

							<DialogTrigger asChild onClick={updateUsername}>
								<div className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-nord-hover">
									Save
								</div>
							</DialogTrigger>
						</DialogContent>
					</Dialog>

					<Dialog>
						<DialogTrigger>
							<div className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-2 px-3 items-center transition hover:bg-nord-hover">
								<span className="material-symbols-outlined text-slate-300 text-[1.2rem]">
									badge
								</span>
								<span className="text-slate-300 text-[1.1rem]">
									Picture
								</span>
							</div>
						</DialogTrigger>
						<DialogContent
							aria-describedby={""}
							className="max-w-[95vw] w-[32rem] rounded-lg"
						>
							<DialogHeader>
								<DialogTitle>
									Edit your profile picture
								</DialogTitle>
							</DialogHeader>

							<div className="pt-[0.4rem]">
								<div className="flex flex-row w-full gap-2">
									<label
										className={`cursor-pointer w-1/2 px-4 flex justify-center py-5 rounded-lg transition ${
											selectedOption === "custom"
												? "border-2 border-blue-600 bg-blue-950/50 text-white"
												: "border-2 border-transparent bg-nord-800 text-white"
										}`}
									>
										<input
											type="radio"
											value="custom"
											checked={
												selectedOption === "custom"
											}
											onChange={handleOptionChange}
											className="hidden"
										/>
										Google
									</label>
									<label
										className={`cursor-pointer w-1/2 px-4 py-5 flex justify-center rounded-lg transition ${
											selectedOption === "generated"
												? "border-2 border-blue-600 bg-blue-950/50 text-white"
												: "border-2 border-transparent bg-nord-800 text-white"
										}`}
									>
										<input
											type="radio"
											value="generated"
											checked={
												selectedOption === "generated"
											}
											onChange={handleOptionChange}
											className="hidden"
										/>
										Generated
									</label>
								</div>
							</div>

							{selectedOption === "generated" ? (
								<div className="border-t border-t-slate-800 pt-4">
									<span className="ml-1 text-slate-300 border-l-[3px] border-l-blue-600 pl-2">
										Generator Settings
									</span>

									<div className="md:grid lg:grid xl:grid 2xl:grid flex grid-cols-2 flex-col gap-2 mt-3">
										<input
											type="text"
											onChange={editSeed}
											value={seed}
											placeholder="Image Seed [any]"
											className="w-full border border-slate-700 focus:z-10 bg-nord-950/30 outline-none focus:border-nord-700 p-3 rounded-lg"
										/>

										<Select
											onValueChange={pickVariant}
											className="w-full"
										>
											<SelectTrigger className="w-full h-[3.25rem]">
												<SelectValue
													placeholder={capitalize(
														variant,
													)}
												></SelectValue>
											</SelectTrigger>
											<SelectContent>
												<SelectGroup>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="marble"
													>
														Marble
													</SelectItem>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="abstract"
													>
														Abstract
													</SelectItem>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="avatar"
													>
														Avatar
													</SelectItem>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="pixel"
													>
														Pixel
													</SelectItem>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="sunset"
													>
														Sunset
													</SelectItem>
													<SelectItem
														className="hover:bg-nord-800 cursor-pointer"
														value="rings"
													>
														Rings
													</SelectItem>
												</SelectGroup>
											</SelectContent>
										</Select>

										<div className="flex flex-row gap-2 border rounded-lg p-2 border-slate-700">
											<input
												type="color"
												onChange={(e) =>
													pickColor(e.target.value, 0)
												}
												value={colors[0]}
												className="rounded-lg w-[50px] h-[36px] cursor-pointer bg-transparent"
											/>
											<input
												type="color"
												onChange={(e) =>
													pickColor(e.target.value, 1)
												}
												value={colors[1]}
												className="rounded-lg w-[50px] h-[36px] cursor-pointer bg-transparent"
											/>
											<input
												type="color"
												onChange={(e) =>
													pickColor(e.target.value, 2)
												}
												value={colors[2]}
												className="rounded-lg w-[50px] h-[36px] cursor-pointer bg-transparent"
											/>
											<input
												type="color"
												onChange={(e) =>
													pickColor(e.target.value, 3)
												}
												value={colors[3]}
												className="rounded-lg w-[50px] h-[36px] cursor-pointer bg-transparent"
											/>
											<input
												type="color"
												onChange={(e) =>
													pickColor(e.target.value, 4)
												}
												value={colors[4]}
												className="rounded-lg w-[50px] h-[36px] cursor-pointer bg-transparent"
											/>
										</div>
									</div>

									<div></div>

									<div className="pt-3 flex flex-row gap-3 justify-between items-center">
										<div className="flex flex-col gap-1 border-l-[3px] border-l-slate-800 pl-4">
											<div>
												<Avatar
													size={60}
													name={seed}
													variant={
														variantMapping[variant]
													}
													colors={colors}
												/>
											</div>
										</div>

										<div className="flex flex-row gap-2 items-center">
											<div
												onClick={randomizeSeed}
												className="flex h-fit select-none flex-row bg-transparent w-fit rounded-lg cursor-pointer gap-2 py-[10px] pr-5 pl-4 items-center transition border border-transparent hover:border-slate-700 hover:bg-slate-900"
											>
												<span className="material-symbols-outlined">
													blur_on
												</span>
												<span>Seed</span>
											</div>

											<div
												onClick={randomizeColors}
												className="flex h-fit select-none flex-row bg-transparent w-fit rounded-lg cursor-pointer gap-2 py-[10px] pr-5 pl-4 items-center transition border border-transparent hover:border-slate-700 hover:bg-slate-900"
											>
												<span className="material-symbols-outlined">
													flare
												</span>
												<span>Colors</span>
											</div>
										</div>
									</div>
								</div>
							) : null}

							<DialogTrigger asChild>
								<div
									onClick={updateProfilePicture}
									className="flex select-none flex-row bg-nord-700/30 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-nord-700/50"
								>
									Save
								</div>
							</DialogTrigger>
						</DialogContent>
					</Dialog>

					<Dialog>
						<DialogTrigger>
							<div className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-2 px-3 items-center transition hover:bg-nord-hover">
								<span className="material-symbols-outlined text-slate-300 text-[1.2rem]">
									lock
								</span>
								<span className="text-slate-300 text-[1.1rem]">
									Privacy
								</span>
							</div>
						</DialogTrigger>

						<DialogContent
							aria-describedby={""}
							className="max-w-[95vw] w-[32rem] rounded-lg"
						>
							<DialogHeader>
								<DialogTitle className="mb-1">Who can see your profile?</DialogTitle>
							</DialogHeader>

							<div className="flex flex-row gap-2">
								<div onClick={() => setCurrentProfilePrivacy("everybody")} className={`border-2 ${currentProfilePrivacy === "everybody" ? "border-blue-600 bg-blue-950/50" : "border-slate-700"} text-md text-slate-100 cursor-pointer select-none justify-center rounded-lg p-2 flex items-center w-full h-[4rem]`}>
									Everyone
								</div>

								<div onClick={() => setCurrentProfilePrivacy("nobody")} className={`border-2 ${currentProfilePrivacy === "nobody" ? "border-blue-600 bg-blue-950/50" : "border-slate-700"} text-md text-slate-100 justify-center cursor-pointer select-none rounded-lg p-2 flex items-center w-full h-[4rem]`}>
									Nobody
								</div>
							</div>

							<DialogTrigger asChild>
								<div onClick={updateProfilePrivacy} className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-nord-hover">
									Save
								</div>
							</DialogTrigger>
						</DialogContent>
					</Dialog>
				</div>

				{/* <h3 className="mt-5 text-lg font-medium text-slate-200">Profile Privacy</h3>

                <EditPrivacySheet /> */}
			</div>

			<Footer />
		</div>
	);
}

export default Settings;
