"use client"

import * as React from "react"
import { useState } from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "../ui/button"
import { Calendar } from "../ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover"

import { toast } from "sonner"

export default function ChallengeDatePicker(props) {

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  const [date, setDate] = useState(tomorrow)

    function handleChallengeDeadlineChange(date) {
        if (date < new Date()) {
            toast(
              "Please select a date in the future!",
              {
                duration: 3000,
                type: "error",
              }
            )
            return;
        }

        setDate(date);
        props.setDateChallengeDue(date);
        //props.calculatePoints();
    }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] h-[3rem] justify-start border-[1.5px] border-nord-700 flex bg-nord-800 hover:bg-nord-hover items-center text-[1rem] font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-[0.8rem] h-4 w-4" />
          <span className="flex mt-[2px] items-center">
          {date ? format(date, "PPP") : <span>Pick a date</span>}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 m-[8px] border-none">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleChallengeDeadlineChange}
          className="bg-nord-900 border-[1.5px] border-nord-700 rounded-lg"
          disabled={(date) =>
            date < today
          }
        />
      </PopoverContent>
    </Popover>
  )
}
