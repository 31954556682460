import { useNavigate } from "react-router-dom";

function Chip(props) {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/bookshelf/${props.name}`)} className="bg-nord-800 transition select-none hover:bg-nord-700/25 text-nord-100 text-[1.2rem] px-[1rem] cursor-pointer rounded-xl p-2 min-w-fit max-h-[2.3rem] flex items-center justify-center">{props.name}</div>
    )
}

export default Chip;
