import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";

import ClickableButton from "./ClickableButton";
import Input from "./Input";
import InputCenter from "./InputCenter";
import ClickableButtonNavBar from "./ClickableButtonNavbar";

import { toast } from "sonner";

import ProfilePicture from "./ProfilePicture";

import ScrollToTop from "./scrollToTop";

function Navbar(props) {
	const { authenticated, login, logout, signup } = useAuth();

	//let showNavbarPrompt = (!authenticated.authenticated) ? "flex" : "hidden"
	let showNavbarPrompt = !authenticated.authenticated ? true : false;

	if (showNavbarPrompt) {
		// check if user in on the login or signup page
		if (
			window.location.pathname === "/login" ||
			window.location.pathname === "/signup"
		) {
			showNavbarPrompt = false;
		}
	}

	let pbShowAccountPrompt = showNavbarPrompt ? "pb-[9rem]" : "pb-[6rem]";
	let mtShowAccountPromptNavbarPush = showNavbarPrompt ? "top-[3rem]" : null;

	const [usernameDropdown, setUsernameDropdown] = useState(false);
	const [appsDropdown, setAppsDropdown] = useState(false);

	function setAuthenticatedCookie(data) {
		const cookieName = "authenticated";
		const cookieValue = data;
		const daysToExpire = 21; // Set cookie to expire in 7 days

		const date = new Date();
		date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
		const expires = "expires=" + date.toUTCString();

		document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
	}

	//console.log(authenticated);

	// on click outside of the dropdown, close the dropdown
	// make sure that person clicks even in an element inside the dropdown, it closes

	//window.addEventListener('click', function(e) {
	// id profileDropdown or appsDropdown

	useEffect(() => {
		window.addEventListener("click", function (e) {
			if (document.getElementById("profileDropdown") !== null) {
				if (
					!document
						.getElementById("profileDropdown")
						.contains(e.target)
				) {
					setUsernameDropdown(false);
				}
			}

			if (document.getElementById("appsDropdown") !== null) {
				if (
					!document.getElementById("appsDropdown").contains(e.target)
				) {
					setAppsDropdown(false);
				}
			}
		});
	}, []);

	const [pictureDetails, setPictureDetails] = useState({
		picture_is_default: authenticated?.picture_is_default,
		picture: authenticated?.picture,
		picture_generation_details: {
			variant: authenticated?.picture_generation_details?.variant,
			seed: authenticated?.picture_generation_details?.seed,
			colors: authenticated?.picture_generation_details?.colors,
		},
	});

	/*useEffect(() => {
        async function get_simple_user_info() {
            await fetch(`https://nerded.io/api/users/get_simple_user_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken
                })
            }).then(res => res.json()).then(data => {
                if (data.requestStatus === "error") {
                    //toast.error(data.error);
                    return
                }

                setPictureDetails(data.user_info);

                authenticated.picture_is_default = data.user_info.picture_is_default;
                authenticated.picture = data.user_info.picture;
                authenticated.picture_generation_details = {
                    variant: data.user_info.picture_generation_details.variant,
                    seed: data.user_info.picture_generation_details.seed,
                    colors: data.user_info.picture_generation_details.colors
                };

                setAuthenticatedCookie(JSON.stringify(authenticated));
            });
        }

        if (authenticated.authenticated) {
            get_simple_user_info();
        }
    }, [authenticated, props?.authenticated]);*/

	useEffect(() => {
		setPictureDetails({
			picture_is_default: authenticated?.picture_is_default,
			picture: authenticated?.picture,
			picture_generation_details: {
				variant: authenticated?.picture_generation_details?.variant,
				seed: authenticated?.picture_generation_details?.seed,
				colors: authenticated?.picture_generation_details?.colors,
			},
		});
	}, [authenticated]);

	useEffect(() => {
		if (Object.keys(props).includes("profilePictureGenerationDetails")) {
			setPictureDetails({
				picture_is_default: props?.profilePictureIsDefault,
				picture: props?.profilePicture,
				picture_generation_details: {
					variant: props?.profilePictureGenerationDetails?.variant,
					seed: props?.profilePictureGenerationDetails?.seed,
					colors: props?.profilePictureGenerationDetails?.colors,
				},
			});
		}
	}, [props?.profilePictureGenerationDetails]);

	//const [searchInput, setSearchInput] = useState("");

	function logoutFunction() {
		logout();
		setUsernameDropdown(false);
	}

	return (
		<div className={`flex flex-col ${pbShowAccountPrompt} gap-0`}>
			<ScrollToTop />

			{showNavbarPrompt ? (
				<div className="flex flex-row w-screen p-2 gap-1 bg-[#1d283d] text-zinc-100 fixed z-[5] justify-center items-center text-lg h-[3rem] border-none outline-none">
					<span className="hidden md:block lg:block xl:block 2xl:block">
						You're not signed in!
					</span>
					&nbsp;
					<div className="flex flex-row items-center gap-[2px]">
						<ClickableButtonNavBar
							text="Create an Account"
							onClick={signup}
						/>
						&nbsp; <span>or</span> &nbsp;
						<ClickableButtonNavBar text="Log in" onClick={login} />
					</div>
					&nbsp;
				</div>
			) : null}

			<nav
				className={`flex z-[5] flex-row w-screen justify-between bg-nord-800 text-slate-50 gap-3 px-6 py-3 text-xl items-center fixed ${mtShowAccountPromptNavbarPush} border-none`}
			>
				<Link
					to="/home"
					className="flex flex-row gap-2 items-center text-[1.7rem]"
				>
					{/* <span className="material-symbols-outlined text-[2.3rem] text-emerald-400">
                        local_library
                    </span>

                    <span className="hidden md:flex lg:flex xl:flex 2xl:flex">
                        nerded
                    </span> */}

                    <div className="bg-emerald-900/40 mr-1 h-[50px] w-[50px] flex items-center justify-center rounded-lg p-2">
                        <span className="material-symbols-outlined leading-none text-[2rem] text-emerald-400">
                            local_library
                        </span>
                    </div>

                    <span className="text-zinc-50 text-[1.6rem] hidden md:flex lg:flex xl:flex 2xl:flex">
                        nerded.io
                    </span>
				</Link>

				<div className="flex flex-row flex-nowrap gap-3 items-center cursor-default">
					<InputCenter
						type="text"
						placeholder="Search..."
						searchWithQuery={props.searchWithQuery}
					/>

					{/*<ClickableButton text="Discover" />
                    <ClickableButton text="My Bookshelf" />
                    <ClickableButton text="Friends" />*/}
					{/*data-title="Daily Streak"*/}
					{/*<div className="pl-1 pr-1 py-1 rounded bg-nord-800 flex flex-row items-center gap-1 justify-center">
                        <span className="material-symbols-outlined text-4xl text-emerald-400">
                            local_fire_department
                        </span>

                        <span>5</span>
                    </div>*/}

					<div id="appsDropdown">
						<div
							className="flex justify-center items-center select-none cursor-pointer rounded-xl transition border-2 border-nord-700/60 hover:bg-nord-700/25"
							onClick={() => {
								appsDropdown
									? setAppsDropdown(false)
									: setAppsDropdown(true);
								setUsernameDropdown(false);
							}}
						>
							<span className="m-[8px] material-symbols-outlined text-[2rem] flex justify-center items-center">
								apps
							</span>
						</div>

						<div
							className={`${
								appsDropdown ? "grid" : "hidden"
							} absolute top-[5.2rem] right-[0.7rem] grid-cols-2 gap-y-2 gap-x-2 p-2 bg-nord-800 rounded-md w-[95vw] md:w-fit lg:w-fit xl:w-fit 2xl:w-fit`}
						>
							{/*<Link to="/discover" className="flex w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800">
                                <span className="material-symbols-outlined text-3xl">
                                    travel_explore
                                </span>

                                <span>
                                    Discover
                                </span>
                            </Link>*/}

							<Link
								to="/home"
								className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
							>
								<span className="material-symbols-outlined text-3xl">
									flag
								</span>

								<span>Home</span>
							</Link>

							{authenticated.authenticated ? (
								<Link
									to="/bookclub"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										book
									</span>

									<span>Book Clubs</span>
								</Link>
							) : null}

							{/*<Link to="#" className="flex w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800">
                                <span className="material-symbols-outlined text-3xl">
                                    reviews
                                </span>

                                <span>
                                    Reviews
                                </span>
                            </Link>*/}

							{authenticated.authenticated ? (
								<Link
									to="/profile"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										person
									</span>

									<span>Profile</span>
								</Link>
							) : null}

							{/*<Link to="/bookshelves" className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800">
                                <span className="material-symbols-outlined text-3xl">
                                    beenhere
                                </span>

                                <span>
                                    Shelves
                                </span>
                            </Link>*/}

							{authenticated.authenticated ? (
								<Link
									to="/challenge"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										gesture
									</span>

									<span>Challenges</span>
								</Link>
							) : null}

							{authenticated.authenticated ? (
								<Link
									to="/streak"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										local_fire_department
									</span>

									<span>Streak</span>
								</Link>
							) : null}

							<Link
								to="/games"
								className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
							>
								<span className="material-symbols-outlined text-3xl">
									joystick
								</span>

								<span>Games</span>
							</Link>

							{!authenticated.authenticated ? (
								<div
									onClick={login}
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										login
									</span>

									<span>Log In</span>
								</div>
							) : null}

							{!authenticated.authenticated ? (
								<div
									onClick={signup}
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl">
										person_add
									</span>

									<span>Sign Up</span>
								</div>
							) : null}

							{/*<Link to="/tester" className="flex w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800">
                                <span className="material-symbols-outlined text-3xl">
                                    experiment
                                </span>

                                <span>
                                    Labs
                                </span>
                            </Link>*/}

							{authenticated.rank === "admin" ? (
								<Link
									to="/admin"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl text-emerald-500">
										security
									</span>

									<span>Admin</span>
								</Link>
							) : null}

							{authenticated.rank === "admin" ? (
								<Link
									to="/admin/reviewreports"
									className="flex w-full md:w-[15rem] lg:w-[15rem] xl:w-[15rem] 2xl:w-[15rem] flex-row items-center justify-center gap-2 gap-y-2 py-4 px-3 rounded bg-nord-800 cursor-pointer border-2 border-slate-700 hover:border-emerald-500 hover:bg-emerald-800"
								>
									<span className="material-symbols-outlined text-3xl text-emerald-500">
										bug_report
									</span>

									<span>Reports</span>
								</Link>
							) : null}
						</div>
					</div>

					<div id="profileDropdown" className="">
						{authenticated.authenticated ? (
							<div
								onClick={() => {
									usernameDropdown
										? setUsernameDropdown(false)
										: setUsernameDropdown(true);
									setAppsDropdown(false);
								}}
								className="rounded-full"
							>
								<ProfilePicture
									variant={
										pictureDetails
											?.picture_generation_details
											?.variant
									}
									seed={
										pictureDetails
											?.picture_generation_details?.seed
									}
									colors={
										pictureDetails
											?.picture_generation_details?.colors
									}
									isDefault={
										pictureDetails.picture_is_default
									}
									picture={pictureDetails.picture}
									size="50"
									className="cursor-pointer rounded-full w-[50px] h-[50px] ml-[3px]"
								/>
							</div>
						) : null}

						<div
							className={`${
								usernameDropdown ? "absolute" : "hidden"
							} flex top-[5.2rem] right-[0.7rem] flex-col gap-y-2 p-2 bg-nord-800 rounded-md w-fit`}
						>
							{/*<Link to="/profile" className="flex flex-row gap-x-2 p-2 py-2 px-3 rounded bg-nord-800 items-center cursor-pointer border-2 border-slate-700 hover:border-slate-600 transition duration-75 w-[100%]">
                                <span className="material-symbols-outlined">
                                    person
                                </span>

                                <span className='text-center w-full'>
                                    Profile
                                </span>
                            </Link>*/}

							<Link
								to="/notifications"
								className="flex flex-row gap-x-2 p-2 py-2 px-3 rounded bg-nord-800 items-center cursor-pointer border-2 border-slate-700 hover:border-slate-600 transition duration-75 w-[100%]"
							>
								<span className="material-symbols-outlined">
									notifications_active
								</span>

								<span className="text-center w-full">
									Updates
								</span>
							</Link>

							<Link
								to="/settings"
								className="flex flex-row gap-x-2 p-2 py-2 px-3 rounded bg-nord-800 items-center cursor-pointer border-2 border-slate-700 hover:border-slate-600 transition duration-75 w-[100%]"
							>
								<span className="material-symbols-outlined">
									settings
								</span>

								<span className="text-center w-full">
									Settings
								</span>
							</Link>

							<div
								className="flex flex-row gap-x-2 p-2 px-3 rounded py-2 bg-nord-800 items-center cursor-pointer border-2 border-slate-700 hover:border-slate-600 transition duration-75 w-[100%]"
								onClick={logoutFunction}
							>
								<span className="material-symbols-outlined">
									logout
								</span>

								<span className="text-center w-full">
									Log out
								</span>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Navbar;
