import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

import { toast } from "sonner";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function EmailBlocked() {
    return (
        <div>
            <Navbar />

            <div className="p-[1.5rem] mt-[3rem] mb-[4rem] border border-slate-700 rounded-lg bg-nord-900 mx-auto min-w-[30rem] w-[40vw]">
                <div className="flex flex-col gap-4 items-center">
                    <div className="bg-emerald-500/30 rounded-full w-fit h-fit p-3 mx-auto">
                        <span className="material-symbols-outlined rounded-full p-3 bg-emerald-300/50">
                            check
                        </span>
                    </div>

                    <h1 className="text-2xl font-bold">Email Notifications Blocked</h1>

                    <p className="text-center">
                        Your email notifications have been blocked. You can always enable them in your account settings (in the button below).
                    </p>

                    <div className="flex justify-center hover:border-nord-700 transition bg-nord-800 border border-nord-700/50 mt-3 cursor-pointer px-4 py-3 rounded-lg">
                        <Link to="/account/settings" className="btn btn-primary">
                            Enable Email Notifications
                        </Link>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
