import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "sonner";
import { useAuth } from "../context/AuthContext";
import BookGrid from "../components/Search/BookGrid";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { Card, CardContent } from "../components/ui/card";
// import { Search } from "lucide-react";
import { Search as SearchIcon } from "lucide-react";

function Search() {
	const { authenticated } = useAuth();
	const location = useLocation();

	const [searchResults, setSearchResults] = useState([]);
	const [failed, setFailed] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isRetrying, setIsRetrying] = useState(false);

	const searchTerm = useMemo(() => {
		const params = new URLSearchParams(location.search);
		return params.get("q") || "";
	}, [location.search]);

	const requestHeaders = {
		"Content-Type": "application/json",
	};

	const requestBody = authenticated.authenticated
		? JSON.stringify({
				user_id: authenticated?.userId,
				session_token: authenticated?.sessionToken,
				language: authenticated.searchLanguages,
		  })
		: JSON.stringify({});

	const fetchSearchResults = async (retry = false) => {
		if (searchTerm === "") {
			setSearchResults([]);
			setIsLoading(false);
			return;
		}

		if (!retry) {
			setIsLoading(true); // Set loading state only when not retrying
		}

		await fetch(
			`https://nerded.io/api/search?q=${encodeURIComponent(
				searchTerm,
			)}`,
			{
				method: "POST",
				headers: requestHeaders,
				body: requestBody,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.requestStatus === "error") {
					toast.error(data.message);
					setSearchResults([]);
					setIsLoading(false);
					return;
				}

				if (data.retry && !retry) {
					setIsRetrying(true); // Indicate that we are retrying
					fetchSearchResults(true); // Retry the search
				} else {
					setSearchResults(data.items);
					// Store the result in sessionStorage
					sessionStorage.setItem(
						`searchResults-${searchTerm}`,
						JSON.stringify(data.items),
					);
					if (data.items.length === 0) {
						toast.error(
							"We didn't find any books matching your search.",
						);
						setFailed(true);
					}
					setIsLoading(false);
					setIsRetrying(false); // No need to retry anymore
				}
			})
			.catch((error) => {
				console.error(error);
				setSearchResults([]);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchSearchResults();
	}, [searchTerm]);

	const [readList, setReadList] = useState([]);
	const [bookmarkedList, setBookmarkedList] = useState([]);
	const [reviewedList, setReviewedList] = useState([]);
	const [refreshBookListState, setRefreshBookListState] = useState(false);

	const refreshBookList = () => {
		setRefreshBookListState(!refreshBookListState);
	};

	useEffect(() => {
		const getBookData = async () => {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: requestHeaders,
					body: requestBody,
				},
			)
				.then((response) => response.json())
				.then((data) => {
					try {
						setReadList(data.books_read);
						setBookmarkedList(data.books_bookmarked);
						setReviewedList(data.books_reviewed);
					} catch (error) {
						console.log(error);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		};

		if (authenticated.authenticated) {
			getBookData();
		}
	}, [refreshBookListState, searchTerm, authenticated.authenticated]);

	function searchByAuthor() {
		return () => {
			let newSearchTerm = searchTerm;

			// replace all [...]
			newSearchTerm = newSearchTerm.replace(/\[.*?\]/g, "");
			// strip ending whitespace
			newSearchTerm = newSearchTerm.trim();

			window.location.href = `/search?q=${newSearchTerm} [author]`;
		};
	}

	function searchByTitle() {
		return () => {
			let newSearchTerm = searchTerm;

			// replace all [] and everything inside them
			newSearchTerm = newSearchTerm.replace(/\[.*?\]/g, "");

			newSearchTerm = newSearchTerm.trim();

			window.location.href = `/search?q=${newSearchTerm} [title]`;
		};
	}

	function searchByMeta() {
		return () => {
			let newSearchTerm = searchTerm;

			// replace all [...]
			newSearchTerm = newSearchTerm.replace(/\[.*?\]/g, "");

			newSearchTerm = newSearchTerm.trim();

			window.location.href = `/search?q=${newSearchTerm} [meta]`;
		};
	}

    function searchByClear() {
        return () => {
            let newSearchTerm = searchTerm;

			// replace all [...]
			newSearchTerm = newSearchTerm.replace(/\[.*?\]/g, "");

			newSearchTerm = newSearchTerm.trim();

			window.location.href = `/search?q=${newSearchTerm}`;
        }
    }


	function NoResults({ searchByAuthor, searchByTitle, searchByMeta }) {
		return (
			<div className="bg-nord-900 border border-nord-700/50 py-3 rounded-lg pl-[1rem] pr-[1rem] gap-2 w-[89.5vw] flex flex-row mx-auto items-center justify-between">
				<div className="font-normal max-h-fit w-full gap-2 flex-row items-center text-slate-200 flex flex-wrap text-lg">
					<div className="rounded-full bg-nord-700/20 p-3 mr-2">
						<SearchIcon className="h-5 w-5 text-zinc-300" />
					</div>

					<span>Didn't find what you're looking for?</span>

					<div className="flex flex-row gap-2 ml-2 mr-[-1px] bg-sky-700/20 border-l-[3px] border-l-sky-700/50 rounded-r-lg pl-3 py-1 pr-3">
						<span>You can search by</span>

                        <div
							onClick={searchByTitle()}
							className="text-sky-400 hover:underline cursor-pointer"
						>
							[title]
						</div>

						<div
							onClick={searchByAuthor()}
							className="text-sky-400 hover:underline cursor-pointer"
						>
							[author]
						</div>

						<div
							onClick={searchByMeta()}
							className="text-sky-400 hover:underline cursor-pointer"
						>
							[meta]
						</div>

                        <div
							onClick={searchByClear()}
							className="text-sky-400 hover:underline cursor-pointer"
						>
							clear
						</div>
					</div>

                    <div
						className="ml-auto text-sky-400 p-[3px] mx-[2px] rounded-md px-[8px] hover:bg-sky-900/60 bg-sky-900/30 cursor-pointer"
						onClick={() => window.location.reload()}
					>
						Search Again
					</div>

					<Link
						to="/bookrequest"
						className="hidden lg:flex text-sky-400 p-[3px] ml-[1px] rounded-md px-[8px] hover:bg-sky-900/60 bg-sky-900/30 cursor-pointer"
					>
						Make a Request
					</Link>
				</div>
			</div>
		);
	}

	return (
		<div>
			<Navbar
				showAccountPrompt={true}
				searchWithQuery={fetchSearchResults}
			/>

			<NoResults
				searchByAuthor={searchByAuthor}
				searchByTitle={searchByTitle}
				searchByMeta={searchByMeta}
			/>

			{/* <div className="font-normal items-center text-slate-200 flex flex-row flex-wrap gap-[4px] max-w-[90vw] lg:w-fit lg:ml-[5rem] mx-[2rem] text-lg mt-[18px] lg:mb-[1rem] mb-[0.7rem]">
				<span>Didn't find what you're looking for?</span>
				<div
					className="font-semibold text-sky-400 p-[3px] mx-[2px] rounded-md px-[8px] hover:bg-sky-900/60 bg-sky-900/30 cursor-pointer"
					onClick={() => window.location.reload()}
				>
					Search Again
				</div>

				<div className="ml-2 flex flex-row border-l-[2px] border-l-nord-700/50 pl-3 gap-2">
					<span>You can search by</span>

					<div onClick={searchByAuthor()} className="text-sky-400 hover:underline cursor-pointer">author</div>

					<div onClick={searchByTitle()} className="text-sky-400 hover:underline cursor-pointer">title</div>

					<div onClick={searchByMeta()} className="text-sky-400 hover:underline cursor-pointer">meta</div>
				</div>

                <span className="ml-1">
                    or
                </span>

                <Link
					to="/bookrequest"
					className="hidden lg:flex ml-1 text-sky-400 hover:underline cursor-pointer"
				>
					make a request
				</Link>
			</div> */}

			{isLoading ? (
				<div className="flex flex-col items-center justify-center gap-4 mt-16 mb-[5rem]">
					<p className="text-slate-200 text-center text-[1.3rem] flex flex-row gap-3 bg-slate-800 p-3 pr-[1.2rem] rounded-lg">
						<div className="p-2 rounded-lg bg-sky-900 flex items-center justify-center">
							<span className="material-symbols-outlined animate-spin text-sky-400">
								autorenew
							</span>
						</div>
						<span className="flex items-center">
							We're processing your search request...
						</span>
					</p>
				</div>
			) : searchResults.length > 0 ? (
				<BookGrid
					readList={readList}
					bookmarkedList={bookmarkedList}
					books={searchResults}
					reviewedList={reviewedList}
					refreshReviewsList={refreshBookList}
				/>
			) : (
				<div className="flex flex-col items-center justify-center gap-4 mt-10">
					{isRetrying ? (
						// Show a message during retry
						<p className="text-slate-200 text-center text-[1.3rem] flex flex-row gap-3 bg-slate-800 p-3 pr-[1.2rem] rounded-lg">
							<span className="material-symbols-outlined text-sky-400 p-2 rounded-lg bg-sky-900">
								search
							</span>
							<span className="flex items-center">
								We're still searching for books matching your
								query...
							</span>
						</p>
					) : (
						<p className="text-slate-200 text-center text-[1.3rem] flex flex-row gap-3 bg-slate-800 p-3 pr-[1.2rem] rounded-lg">
							<span className="material-symbols-outlined text-sky-400 p-2 rounded-lg bg-sky-900">
								local_fire_department
							</span>
							<span className="flex items-center">
								We didn't find any books matching your search.
							</span>
						</p>
					)}

					<Link
						className="text-sky-400 text-[1.2rem] py-3 text-center hover:underline"
						to="/home"
					>
						Back to home
					</Link>
				</div>
			)}

			<Footer />
		</div>
	);
}

export default Search;
