import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "./ui/dialog";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";

export default function Challenge(props) {
	const progress = (props.booksRead / props.challengeTotal) * 100;

	const calculateDaysLeft = (endDate) => {
		const currentDate = new Date();
		const end = new Date(endDate * 1000); // Convert Unix timestamp to JavaScript Date object
		const timeDifference = end - currentDate;
		const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
		return daysLeft;
	};

	const daysLeft = calculateDaysLeft(props.endDate);

	function deleteChallengeInner() {
		// if in props
		if (props.deleteChallenge) {
			props.deleteChallenge(props.id);
		}
	}

	return (
		<div
			className={`flex flex-col w-full border-2 ${
				progress >= 100
					? "border-sky-600 bg-nord-900"
					: "border-slate-700 bg-nord-900"
			} rounded-lg`}
		>
			<div className="flex flex-col p-4">
				<span className="flex flex-row justify-between items-center mb-2">
					<div className="flex flex-row items-center gap-4">
						<span className="text-[1.3rem] px-1 ml-1">
							{props.name}
						</span>

						{progress >= 100 ? (
							<span className="px-3 py-1 rounded-full bg-sky-600/40 text-slate-200 flex items-center">
								Completed
							</span>
						) : null}
					</div>

					<div className="flex flex-row gap-4 flex-wrap items-center">
						{daysLeft >= 0 ? (
							<span
								className={`${
									progress >= 100
										? "bg-sky-600/40 text-slate-200"
										: "bg-nord-700/30 text-slate-200"
								} px-4 py-1 h-[2.5rem] rounded-full flex items-center`}
							>
								{daysLeft} day{daysLeft > 1 ? "s" : ""} left
							</span>
						) : (
							<div className="px-4 py-1 h-[2.5rem] rounded-full gap-2 bg-nord-700/30 text-slate-200 flex flex-row flex-wrap items-center">
								<span>
                                    Challenge Over
                                </span>
							</div>
						)}

						<span className={`px-4 py-1 h-[2.5rem] rounded-full ${progress >= 100 ? "bg-sky-600/40" : "bg-nord-700/30"} text-slate-200 flex flex-row flex-wrap items-center`}>
							<span className="mr-2">
								{props.booksRead}/{props.challengeTotal}
							</span>
							|
							<span className="ml-2">
								{Math.round(progress)}%
							</span>
						</span>

						<div className="flex flex-row flex-wrap gap-3 border-t-slate-700">
							{/*<div className="gap-3 cursor-pointer flex flex-row items-center duration-200 material-symbols-outlined p-3 rounded-lg hover:bg-slate-800 transition">
                                <span className="material-symbols-outlined">
                                    edit
                                </span>
                            </div>*/}

							<Dialog>
								<DialogTrigger className="gap-3 cursor-pointer flex flex-row items-center duration-200 material-symbols-outlined p-3 rounded-lg hover:bg-nord-700/30 transition">
									<span className="material-symbols-outlined">
										delete
									</span>
								</DialogTrigger>
								<DialogContent className="border border-slate-700 bg-slate-950 px-[1.5rem] py-[1.8rem]">
									<DialogHeader className="flex flex-col gap-2">
										<DialogTitle>
											Delete Challenge
										</DialogTitle>
										<DialogDescription className="text-md">
											This action cannot be undone and
											your progress will be lost. Are you
											sure you want to delete this
											challenge?
										</DialogDescription>
									</DialogHeader>

									<div className="flex flex-row gap-2">
										<DialogTrigger asChild>
											<div className="flex w-fit flex-row gap-4 bg-slate-900 rounded-lg hover:bg-slate-800 cursor-pointer select-none transition px-4 py-2">
												Cancel
											</div>
										</DialogTrigger>

										<DialogTrigger asChild>
											<div
												onClick={deleteChallengeInner}
												className="flex w-fit flex-row gap-4 bg-red-800 rounded-lg hover:bg-red-700 cursor-pointer select-none transition px-4 py-2"
											>
												Delete
											</div>
										</DialogTrigger>
									</div>
								</DialogContent>
							</Dialog>
						</div>
					</div>
				</span>

				<div className="flex flex-col gap-2 mb-1">
					<div className="w-full my-2 mt-4 flex items-center flex-row rounded-full bg-nord-800">
						<span
							className="bg-slate-700 select-none font-medium h-[1.25rem] rounded-full"
							style={{
								width: `calc(${Math.min(progress, 100)}% ${
									progress > 50
										? "- 20px"
										: progress < 50
										? "+ 20px"
										: ""
								})`,
							}}
						>
							<span
								className={`material-symbols-outlined flex items-center w-[40px] h-[40px] mr-[-20px] ml-auto mt-[-10px] justify-center p-1 bg-nord-hover border-[1.5px] border-nord-700 text-white rounded-lg`}
							>
								{progress >= 100 ? "check" : "calendar_month"}
							</span>
							{/*<HoverCard openDelay="100" closeDelay="200">
                                <HoverCardTrigger>

                                </HoverCardTrigger>
                                <HoverCardContent side="top" className="cursor-default bg-slate-950 outline outline-slate-800 flex flex-row flex-wrap rounded-lg gap-2 font-normal m-2">
                                    <span className="">
                                        {props.booksRead}/{props.challengeTotal} books read
                                    </span>

                                    <span className="">
                                        {Math.round(progress)}% complete
                                    </span>

                                    <span className="">
                                        {daysLeft} day{daysLeft > 1 ? "s" : ""} left
                                    </span>
                                </HoverCardContent>
                            </HoverCard>*/}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
