import { Link } from "react-router-dom";

export default function ChallengeCardSm(props) {

    const progress = (props.booksRead / props.challengeTotal) * 100;

    const calculateDaysLeft = (endDate) => {
        const currentDate = new Date();
        const end = new Date(endDate * 1000); // Convert Unix timestamp to JavaScript Date object
        const timeDifference = end - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysLeft;
    };

    const daysLeft = calculateDaysLeft(props.endDate);

    return (
        <Link to={"/challenge"} className={`flex flex-col transition w-full border-[1.5px] ${progress >= 100 ? "border-nord-700/50 bg-nord-800 hover:border-nord-700" : "border-nord-700/50 bg-nord-800 hover:border-nord-700"} rounded-lg`}>
            <div className="flex flex-col px-[14px] py-[16px] pb-[10px]">
                <span className="flex flex-row justify-between items-center mb-3">
                    <div className="flex flex-row items-center gap-4">
                        <span className="text-[1.2rem] px-1 ml-1 truncate">{props.name}</span>
                    </div>

                    <div className="flex flex-row flex-wrap gap-2 items-center">
                        <span className={`${progress >= 100 ? "bg-nord-700/40 text-white" : "bg-nord-700/40 text-slate-300"} px-4 py-1 h-[2.5rem] rounded-full gap-2 flex sm:flex md:hidden lg:hidden xl:flex 2xl:flex items-center`}>
                            {daysLeft >= 1 ?
                                <div className="flex flex-row items-center gap-2">
                                    <span className="material-symbols-outlined">
                                        calendar_clock
                                    </span>

                                    <span>{daysLeft}</span>
                                </div>
                            :
                                <div>
                                    <span className="material-symbols-outlined">
                                        check
                                    </span>

                                    <span>
                                        Over
                                    </span>
                                </div>
                            }
                        </span>

                        {/* <span className={`px-4 py-1 h-[2.5rem] rounded-full ${progress >= 100 ? "bg-nord-700/40 text-white" : "bg-nord-700/40 text-slate-300"} flex flex-row items-center`}>
                            <span className="">
                                {props.booksRead}/{props.challengeTotal}
                            </span>
                        </span> */}
                    </div>
                </span>

                <div className="flex flex-col gap-2">
                    <div className="w-full my-4 flex items-center flex-row rounded-full bg-gray-700">
                        <span
                            className="bg-slate-700 select-none font-medium text-blue-100 h-[1.25rem] rounded-full"
                            style={{
                                width: `calc(${Math.min(progress, 100)}% ${progress > 50 ? '- 20px' : progress < 50 ? '+ 20px' : ''})`
                            }}
                        > {/* - 20px */}
                            <span className="material-symbols-outlined flex items-center w-[40px] h-[40px] mr-[-20px] ml-auto mt-[-10px] justify-center p-1 bg-slate-700 text-white rounded-lg">
                                {progress >= 100 ? "check" : "calendar_month"}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
