// ShowMoreReview.js

import { useState } from "react";
import DOMPurify from "dompurify";

export default function ShowMoreReview(props) {
  const [showMore, setShowMore] = useState(false);

  const chars = props?.chars || 700;
  const text = props.text || "";

  // Function to add space after punctuation if needed
  const addSpaceAfterPunctuation = (str) => {
    return str.replace(/([.,!?;:])(?=[^\s])/g, "$1 ");
  };

  // Function to highlight special features and add badges

//   const highlightSpecialFeatures = (str) => {
//     let highlighted = str;

//     // Define patterns for accolades and stylistic elements
//     const accoladesPattern =
//       /(NOW A MAJOR MOTION PICTURE|NEW YORK TIMES BESTSELLER|SUNDAY TIMES BESTSELLER|—{3,}|Praise for \w+)/gi;

//     // Define patterns for praise elements to be converted into badges
//     const badgePatterns = [
//       /NOW A MAJOR MOTION PICTURE/gi,
//       /TO BE ADAPTED INTO AN [A-Z\s]+ SERIES/gi,
//       /SOON TO BE A (?:MAJOR )?MOVIE/gi,
//       /THE (?:SUNDAY TIMES|NEW YORK TIMES) BESTSELLER/gi,
//     ];

//     // Highlight accolades and stylistic elements
//     highlighted = highlighted.replace(
//       accoladesPattern,
//       '<span class="text-slate-300 font-bold">$1</span>'
//     );

//     // Convert praise elements into badges
//     badgePatterns.forEach((pattern) => {
//       highlighted = highlighted.replace(pattern, (match) => {
//         return `<span class="inline-block bg-amber-500 text-white px-2 py-1 m-1 rounded text-sm font-bold">${match}</span>`;
//       });
//     });

//     // Convert quotes and praises to blockquotes
//     highlighted = highlighted.replace(
//       /‘([^’]+)’/g,
//       '<blockquote class="italic ml-4 my-2 border-l-4 border-sky-800 bg-sky-950/50 pr-4 pl-4 text-slate-300">$1</blockquote>'
//     );
//     highlighted = highlighted.replace(
//       /“([^”]+)”/g,
//       '<blockquote class="italic ml-4 my-2 border-l-4 border-sky-800 bg-sky-950/50 pr-4 py-2 pl-4 text-slate-300">$1</blockquote>'
//     );

//     return highlighted;
//   };

  // Clean up the text
  const cleanedText = addSpaceAfterPunctuation(text);

  // Highlight special features
  const processedText = cleanedText; //highlightSpecialFeatures(cleanedText);

  // Truncate to the desired length and snap to the end of the nearest sentence
  let substring =
    processedText.length > chars ? processedText.slice(0, chars) : processedText;
  const lastPeriodIndex = substring.lastIndexOf(".");
  if (lastPeriodIndex !== -1 && lastPeriodIndex > chars - 100) {
    substring = substring.slice(0, lastPeriodIndex + 1);
  }

  // Split by newline and cap the paragraphs if needed
  const subParagraphs = substring.split("\n").slice(0, 10);
  const fullParagraphs = processedText.split("\n");

  // Set the color based on props
  const color = props.color ? props.color : "text-slate-300";

  // Determine the current text to show
  const current = showMore ? fullParagraphs : subParagraphs;

  // Function to split the text into paragraphs with a max word count
  const splitIntoParagraphsBySentences = (text, maxWords) => {
    const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [text];
    let paragraphs = [];
    let currentParagraph = "";

    sentences.forEach((sentence) => {
      const sentenceWords = sentence.trim().split(" ").length;
      const currentWords = currentParagraph.trim().split(" ").length;

      if (currentWords + sentenceWords <= maxWords) {
        currentParagraph += sentence + " ";
      } else {
        paragraphs.push(currentParagraph.trim());
        currentParagraph = sentence + " ";
      }
    });

    if (currentParagraph.trim().length > 0) {
      paragraphs.push(currentParagraph.trim());
    }

    return paragraphs;
  };

  const maxWords = 80;
  const displayedParagraphs = splitIntoParagraphsBySentences(
    current.join(" "),
    maxWords
  );

  return (
    <div className={`${color} flex flex-col items-start`}>
      <div>
        {displayedParagraphs.map((paragraph, index) => (
          <div
            key={index}
            className="mt-3 text-[1.1rem]"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph) }}
          ></div>
        ))}

        {(text.length > chars || showMore) && (
          <button
            className="font-semibold text-[1.1rem] text-emerald-500 hover:underline mt-3 rounded"
            onClick={() => setShowMore(!showMore)}
            aria-expanded={showMore}
          >
            {showMore ? "Show less" : "Show more"}
          </button>
        )}
      </div>
    </div>
  );
}
