import { useState, useEffect, useRef } from "react";
import { useAuth } from "../context/AuthContext";
// history
import { useNavigate, Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BookActionButtons from "../components/BookActionButtons";

import { toast } from "sonner";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "../components/ui/tabs-new";

import StarRating from "../components/StarRating";
import ProfilePicture from "../components/ProfilePicture";

import RankBadge from "../components/RankBadge";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../components/ui/dialog";

function Profile() {
	const { authenticated, login, logout, signup } = useAuth();
	const Navigate = useNavigate();

	const [readingListNameInput, setReadingListNameInput] = useState("");

	function handleReadingListNameInput(e) {
		setReadingListNameInput(e.target.value);
	}

	// get user from ?id= query param
	const urlParams = new URLSearchParams(window.location.search);

	let userId = urlParams.get("id");

	if (userId === null || userId === undefined) {
		if (!authenticated.authenticated) {
			Navigate("/login");
		} else {
			userId = authenticated.userId;
		}
	}

	let currentTab = urlParams.get("tab") || "read";

	function changeCurrentTab(newTab) {
		const tabList = ["read", "bookmarked", "reviewed", "lists"];

		if (!tabList.includes(newTab)) {
			return;
		}

		// push to url but don't reload page
		//Navigate(`/profile?id=${userId}&tab=${newTab}`);

		window.history.pushState({}, "", `/profile?id=${userId}&tab=${newTab}`);

		currentTab = newTab;
	}

	const [readList, setReadList] = useState([]);
	const [bookmarkedList, setBookmarkedList] = useState([]);
	const [booksReviewed, setBooksReviewed] = useState([]);
	const [bookLists, setBookLists] = useState([]);

	const [privateProfile, setPrivateProfile] = useState(false);
	const [privateIndicator, setPrivateIndicator] = useState(false);

	//let readList = [];
	//let bookmarkedList = [];

	const [bookData, setBookData] = useState([]);

	const [userData, setUserData] = useState({});

	//const LSData = JSON.parse(localStorage.getItem("authenticated"));

	useEffect(() => {
		async function getBookData() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: userId,
						session_token: authenticated?.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					if (data?.private === "true") {
						setPrivateProfile(true);
						return;
					}

					if (data?.privateAllowed === true) {
						setPrivateIndicator(true);
					}

					if (data.success) {
						// make sure none isbns are repeated
						let seen = new Set();
						let filteredBookData = data.book_data.filter((el) => {
							const duplicate = seen.has(el.isbn);
							seen.add(el.isbn);
							return !duplicate;
						});

						setReadList(data.books_read);
						setBookmarkedList(data.books_bookmarked);
						setBooksReviewed(data.books_reviewed);
						setBookLists(data.book_lists);

						//console.log("books reviewed by user");
						//console.log(data.books_reviewed);

						setUserData(data.user);
						//console.log(data.user);
						//readList = data.books_read;
						//console.log("GETTING READLIST FROM API");
						//console.log(data.books_read);

						try {
							setBookData([...filteredBookData]);
						} catch (error) {
							console.log(error);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});

			/*await fetch(`https://nerded.io/api/user_actions/view_bookmarked_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data.books_bookmarked);
                    setBookmarkedList(data.books_bookmarked);
                    //bookmarkedList = data.books_bookmarked;
                }

                setBookData([...data.book_data])
            })
            .catch(error => {
                console.log(error);
            });*/
		}

		getBookData();
	}, [window.location.href]);

	const [usersReadList, setUsersReadList] = useState([]);
	const [usersBookmarkedList, setUsersBookmarkedList] = useState([]);
	const [usersBooksReviewed, setUsersBooksReviewed] = useState([]);
	const [usersBookReviews, setUsersBookReviews] = useState([]);

	// USER's INFO, not profile's...
	useEffect(() => {
		async function getUsersBookData() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						// make sure none isbns are repeated
						let seen = new Set();
						let filteredBookData = data.book_data.filter((el) => {
							const duplicate = seen.has(el.isbn);
							seen.add(el.isbn);
							return !duplicate;
						});

						//console.log(data.books_read);
						setUsersReadList(data.books_read);
						setUsersBookmarkedList(data.books_bookmarked);
						setUsersBooksReviewed(data.books_reviewed);
						setUsersBookReviews(data.books_reviewed);

						//console.log("books reviewed");
						//console.log(data.books_reviewed);
						//console.log(usersBookReviews);

						//readList = data.books_read;
						//console.log("GETTING READLIST FROM API");
						//console.log(data.books_read);
					}
				})
				.catch((error) => {
					console.log("failed getting users book data");
					console.log(error);
				});

			/*await fetch(`https://nerded.io/api/user_actions/view_bookmarked_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data.books_bookmarked);
                    setBookmarkedList(data.books_bookmarked);
                    //bookmarkedList = data.books_bookmarked;
                }

                setBookData([...data.book_data])
            })
            .catch(error => {
                console.log(error);
            });*/
		}

		if (authenticated.authenticated) {
			getUsersBookData();
		}
	}, [window.location.href]);

	//console.log(bookData);
	//console.log(readList);
	//console.log(bookmarkedList);

	const [friend, setFriend] = useState(false);
	const [isPendingFriend, setIsPendingFriend] = useState(false);

	async function addFriend() {
		//console.log(userData.id);

		await fetch(`https://nerded.io/api/social/friend_user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				user_to_friend_id: userId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data?.relog) {
					toast.error("Please login again!");
					logout();
				}

				if (data.status === "success") {
					toast.success("Successfully sent friend request");
				} else {
					toast.error(data.message);
				}
			})
			.catch((error) => {
				return false;
			});
	}

	async function removeFriend() {
		//console.log(userData.id);

		await fetch(`https://nerded.io/api/social/unfriend_user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				user_to_friend_id: userId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data?.relog) {
					toast.error("Please login again!");
					logout();
				}

				if (data.status === "success") {
					return true;
				}
			})
			.catch((error) => {
				return false;
			});
	}

	const [friendCount, setFriendCount] = useState(0);

	useEffect(() => {
		setFriend(false);
		setFriendCount(0);
	}, [window.location.href]);

	async function handleAddFriend() {
		if (!friend) {
			//setFriend(true);
			await addFriend();
			//toast.success("Successfully sent friend request");

			//setFriendCount(friendCount + 1);
		} else {
			// unfollow user
			setFriend(false);
			await removeFriend();
			toast.success("Successfully removed friend");

			setFriendCount(friendCount - 1);
		}
	}

	useEffect(() => {
		async function loadUserSocialStats() {
			await fetch(
				`https://nerded.io/api/social/get_user_social_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						target_id: userId,
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data?.relog) {
						toast.error("Please login again!");
						logout();
					}

					console.log(data);
					setFriendCount(data.data.friendCount);

					setFriend(data.data.isFriends);
					setIsPendingFriend(data.data.isPendingFriends);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		loadUserSocialStats();
	}, [window.location.pathname, userId]);

	function copyReviewLink(link) {
		navigator.clipboard.writeText(link);
		toast.success("Copied review link to clipboard");
	}

	async function createBookList() {
		if (readingListNameInput === "") {
			toast.error("Please enter a name for the book list");
			return;
		}

		await fetch(`https://nerded.io/api/books/create_book_list`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				list_name: readingListNameInput,
				//list_description: readingListDescriptionRef.current.value,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.requestStatus === "success") {
					toast.success("Successfully created book list");

					setBookLists([
						...bookLists,
						{
							id: data.list_id,
							name: readingListNameInput,
							books: [],
						},
					]);

					setReadingListNameInput("");
				} else {
					toast.error("Failed to create book list");

					if (data.error) {
						toast.error(data.error);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async function deleteBookList(listId) {
		console.log(listId);

		try {
			const response = await fetch(
				"https://nerded.io/api/books/delete_book_list",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
						list_id: listId,
					}),
				},
			);

			const data = await response.json();

			if (data.requestStatus === "success") {
				toast.success("Successfully deleted book list");

				// Update state without reloading
				setBookLists((prevLists) =>
					prevLists.filter((list) => list.id !== listId),
				);
			} else {
				toast.error("Failed to delete book list");
			}
		} catch (error) {
			console.error("Error deleting book list:", error);
			toast.error("An error occurred while deleting the book list");
		}
	}

	//console.log("user data");
	//console.log(userData);

	const leagueMappings = {
		unranked: "Unranked",
		bronze: "Bronze",
		silver: "Silver",
		gold: "Gold",
		platinum: "Platinum",
		diamond: "Diamond",
		master: "Master",
		legend: "Legend",
	};

	if (privateProfile) {
		return (
			<div>
				<Navbar showAccountPrompt={false} />

				<div className="flex flex-col gap-2 w-[75vw] ml-auto mr-auto h-fit my-[4rem]">
					<div className="flex flex-row text-xl items-center w-fit gap-3 font-semibold mx-auto mb-4">
						This profile is private
					</div>

					<Link
						to="/home"
						className="flex flex-row items-center gap-3 text-lg font-semibold text-sky-400 hover:underline cursor-pointer mx-auto"
					>
						Go back to home
					</Link>
				</div>

				<Footer />
			</div>
		);
	}

	return (
		<div>
			<Navbar showAccountPrompt={false} />

			<div className="flex flex-col gap-2 w-[75vw] ml-auto mr-auto h-fit mb-[2rem] mt-[2rem]">
				<div className="flex flex-row items-center gap-3">
					{userData.name ? (
						<div className="flex flex-row gap-[16px] w-fit h-fit items-center bg-nord-800 p-2 pr-[1.35rem] rounded-full">
							{/*<img
							src={userData.picture}
							referrerPolicy="no-referrer"
							className="rounded-full w-[40px] h-[40px]"
						/>*/}

							<ProfilePicture
								className={"rounded-full w-[40px] h-[40px]"}
								size={40}
								seed={
									userData?.picture_generation_details?.seed
								}
								variant={
									userData?.picture_generation_details
										?.variant
								}
								isDefault={userData?.picture_is_default}
								picture={userData?.picture}
								colors={
									userData?.picture_generation_details?.colors
								}
							/>

							<div className="flex flex-row gap-[1rem] items-center">
								<span className="text-[1.4rem] w-fit">
									{userData.name}
								</span>

								{userData.rank ? (
									<RankBadge rank={userData.rank} />
								) : null}
							</div>
						</div>
					) : null}

					{privateIndicator ? (
						<div className="flex flex-row items-center gap-1 text-slate-400 bg-nord-800/80 select-none rounded-lg px-3 py-2">
							{/* <span className="material-symbols-outlined font-[500] text-[1.2rem]">
                                lock
                            </span> */}
							<span className="font-semibold text-[0.9rem]">
								Private
							</span>
						</div>
					) : null}

					{userId !== authenticated.userId && (
						<div
							onClick={
								friend && !isPendingFriend
									? handleAddFriend // Remove friend if they're already friends and not pending
									: !friend && !isPendingFriend
									? handleAddFriend // Add friend if they're not friends and not pending
									: undefined // No action if pending
							}
							className={`px-4 select-none hidden md:flex lg:flex xl:flex 2xl:flex transition rounded-md cursor-pointer py-2 ${
								friend
									? !isPendingFriend
										? "bg-emerald-900/60 hover:bg-emerald-900 text-emerald-400"
										: "bg-emerald-900/60 text-emerald-400 cursor-default"
									: isPendingFriend
									? "bg-yellow-800/60 text-yellow-500 cursor-default"
									: "bg-nord-800 hover:bg-slate-700/70"
							}`}
						>
							{friend
								? isPendingFriend
									? "Request Pending"
									: "Friend"
								: isPendingFriend
								? "Request Pending"
								: "Add as Friend"}
						</div>
					)}

					{/*<div className="">
                        <ReadyDialog />
                    </div>*/}

					{/*<span className="px-[12px] py-[8px] bg-nord-900 hover:text-emerald-400 rounded-lg cursor-pointer">
                        Level -1
                    </span>

                    <span className="ml-auto cursor-pointer text-lg h-fit text-emerald-400 hover:border-indigo-500 hover:text-indigo-400 rounded-full border-2 bg-nord-900 border-emerald-500 w-fit p-2 px-4">
                        @professionalbozo
                    </span>*/}
				</div>
			</div>

			<div className="w-[75vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] ml-auto mr-auto mb-[2rem]">
				<div className="gap-[2rem] w-full flex-wrap grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
					<div className="flex flex-row gap-6 items-center bg-nord-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							collections_bookmark
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Books Read</span>
							<span className="text-2xl">{readList.length}</span>
						</span>
					</div>

					{/*<div className="flex flex-col gap-2 bg-nord-800 rounded-lg w-full py-4 px-6">

                        <span className="flex flex-col gap-y-2">
                            <span className="text-slate-400">Favorite Genre</span>
                            <span className="text-2xl">
                                Fiction
                            </span>
                        </span>

                    </div>*/}

					{/*<div className="flex flex-col gap-2 bg-nord-800 rounded-lg w-full py-4 px-6">

                        <span className="flex flex-col gap-y-2">
                            <span className="text-slate-400">Favorite Author</span>
                            <span className="text-2xl">
                                Marie Lu
                            </span>
                        </span>

                    </div>*/}

					{/* <div className="flex flex-row gap-6 items-center bg-nord-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							demography
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Friends</span>
							<span className="text-2xl">{friendCount}</span>
						</span>
					</div> */}

					<div className="flex flex-row gap-6 items-center bg-nord-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							bookmarks
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Bookmarks</span>
							<span className="text-2xl">
								{bookmarkedList.length}
							</span>
						</span>
					</div>

					<div className="flex flex-row gap-6 items-center bg-nord-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							contacts
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Friends</span>
							<span className="text-2xl">{friendCount}</span>
						</span>
					</div>

					<div className="flex flex-row gap-5 items-center bg-nord-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							emoji_events
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">League</span>
							<span className="text-2xl flex flex-row items-center truncate">
								{userData.league
									? leagueMappings[userData.league]
									: "N/A"}
							</span>
						</span>
					</div>
				</div>
			</div>

			{/*<div className="p-5 w-[75vw] rounded-lg bg-nord-800 ml-auto mr-auto overflow-scroll mb-[2rem]">

                <div className="flex flex-row flex-wrap w-fit gap-3 justify-center items-center">
                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Duet Dueler
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img4.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Fiction Fanatic
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Dystopian Devotee
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Romance Rookie
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img2.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Mystery Master
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img2.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Manga Maniac
                        </span>
                    </div>

                    <div className="flex flex-row bg-nord-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Legendary Librarian
                        </span>
                    </div>

                </div>
            </div>*/}

			<div className="flex justify-center flex-row mb-[2rem] md:w-[75vw] mx-auto lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] min-w-[95vw]">
				<Tabs
					defaultValue={currentTab}
					className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] mx-auto"
				>
					<TabsList className="mb-4">
						<TabsTrigger
							onClick={() => changeCurrentTab("read")}
							value="read"
							className="text-[1rem]"
						>
							Read
						</TabsTrigger>

						<TabsTrigger
							value="bookmarked"
							className="text-[1rem]"
							onClick={() => changeCurrentTab("bookmarked")}
						>
							Bookmarks
						</TabsTrigger>

						<TabsTrigger
							value="reviewed"
							className="text-[1rem]"
							onClick={() => changeCurrentTab("reviewed")}
						>
							Reviews
						</TabsTrigger>

						{/* <TabsTrigger
							value="books_not_finished"
							className="text-[1rem]"
						>
							Books Not Finished
						</TabsTrigger> */}

						<TabsTrigger
							value="lists"
							className="text-[1rem]"
							onClick={() => changeCurrentTab("lists")}
						>
							Lists
						</TabsTrigger>
					</TabsList>

					<TabsContent value="read">
						{readList.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-nord-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books read yet
								</span>
							</div>
						) : (
							<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-nord-800 mb-[5rem]">
								<span className="text-xl pt-2 pl-3 text-slate-200">
									Read Books
								</span>

								<div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1  p-2 gap-[1rem] flex-wrap">
									{bookData.map((book, index) => {
										if (!readList.includes(book.isbn)) {
											return null;
										}

										return (
											<div
												key={index}
												className="flex flex-row gap-[1rem] p-3 bg-nord-900 w-full rounded-lg"
											>
												<img
													src={book.cover
														.replace(
															"edge=curl",
															"edge=natural",
														)
														.replace(
															"http://",
															"https://",
														)}
													className="w-[8rem] rounded-lg cursor-pointer"
													onClick={() =>
														(window.location = `/book?isbn=${book.isbn}`)
													}
												/>

												<div className="flex flex-col w-full gap-1">
													<span
														className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
														onClick={() =>
															(window.location = `/book?isbn=${book.isbn}`)
														}
													>
														{book.title}
													</span>

													<span
														className="text-lg cursor-pointer hover:underline w-fit"
														onClick={() =>
															(window.location = `/search?q=${book.author}`)
														}
													>
														{book.author}
													</span>

													<div className="flex justify-end h-full items-end">
														<BookActionButtons
															isbn={book.isbn}
															showSendButton={
																true
															}
															readList={
																usersReadList
															}
															bookmarkedList={
																usersBookmarkedList
															}
															booksReviewed={
																usersBooksReviewed
															}
															bookData={{
																title: book.title,
																industryIdentifiers:
																	[
																		{
																			identifier:
																				book.isbn,
																		},
																	],
															}}
														/>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</TabsContent>

					<TabsContent value="bookmarked">
						{bookmarkedList.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-nord-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books bookmarked yet
								</span>
							</div>
						) : (
							<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-nord-800 mb-[5rem]">
								<span className="text-xl pt-2 pl-3 text-slate-200">
									Bookmarked Books
								</span>

								<div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 p-2 gap-[1rem] flex-wrap">
									{bookData.map((book, index) => {
										if (
											!bookmarkedList.includes(book.isbn)
										) {
											return null;
										}

										return (
											<div
												key={index}
												className="flex flex-row gap-[1rem] p-3 bg-nord-900 w-full rounded-lg"
											>
												<img
													src={book.cover
														.replace(
															"edge=curl",
															"edge=natural",
														)
														.replace(
															"http://",
															"https://",
														)}
													className="w-[8rem] rounded-lg cursor-pointer"
													onClick={() =>
														(window.location = `/book?isbn=${book.isbn}`)
													}
												/>

												<div className="flex flex-col w-full gap-1">
													<span
														className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
														onClick={() =>
															(window.location = `/book?isbn=${book.isbn}`)
														}
													>
														{book.title}
													</span>
													<span
														className="text-lg cursor-pointer hover:underline w-fit"
														onClick={() =>
															(window.location = `/search?q=${book.author}`)
														}
													>
														{book.author}
													</span>

													<div className="flex justify-end h-full items-end">
														<BookActionButtons
															isbn={book.isbn}
															showSendButton={
																true
															}
															readList={
																usersReadList
															}
															bookmarkedList={
																usersBookmarkedList
															}
															booksReviewed={
																usersBooksReviewed
															}
															bookData={{
																title: book.title,
																industryIdentifiers:
																	[
																		{
																			identifier:
																				book.isbn,
																		},
																	],
															}}
														/>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</TabsContent>

					<TabsContent value="reviewed">
						{booksReviewed.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-nord-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books reviewed yet
								</span>
							</div>
						) : (
							<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-nord-800 mb-[5rem]">
								<span className="text-xl pt-2 pl-3 text-slate-200">
									Reviewed Books
								</span>

								<div className="grid md:grid-cols-1 lg:grid-cols-1 grid-cols-1 p-2 gap-[1rem] flex-wrap">
									{booksReviewed.map((book, index) => {
										return (
											<div
												key={index}
												className="flex flex-col gap-[1rem] p-3 bg-nord-900 w-full rounded-lg"
											>
												<div className="flex-row gap-[1rem] w-full">
													<div className="flex flex-row flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap gap-4 w-full">
														<div className="flex gap-2 flex-col max-w-full lg:max-w-[50%] xl:max-w-[50%] 2xl:max-w-[50%] w-full">
															<div className="flex flex-row ml-2 gap-[6px] items-center">
																<span
																	className="text-[1.3rem] truncate font-semibold py-1 text-slate-200 max-w-[66%] hover:underline cursor-pointer"
																	onClick={() =>
																		(window.location = `/book?isbn=${book.isbn}`)
																	}
																>
																	{
																		book.book_title
																	}
																</span>

																<span className="text-[1.3rem] truncate mb-[-1px] w-fit">
																	{
																		book.book_author
																	}
																</span>
															</div>

															<span className="text-slate-200 border-2 border-slate-700 w-full h-full bg-nord-800/50 rounded-lg p-3">
																{book.review ? (
																	book.review
																) : (
																	<span className="px-2 py-1 rounded-lg bg-slate-700 mb-[-8px] w-fit ml-[-2px]">
																		No
																		review
																	</span>
																)}
															</span>

															<div className="flex mt-auto select-none flex-row gap-[8px] rounded-lg p-2">
																{/*<div
																className="flex items-center gap-[7px] -mr-[5px] justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
															>
																<span className="material-symbols-outlined ml-[-1px] mr-[1px] mt-[1px]">
																	favorite
																</span>
																<span className="flex items-center align-middle mt-[1px] mr-[2px]">
																	1
																</span>
															</div>*/}

																<Link
																	to={`/review?isbn=${book.isbn}&id=${book.review_id}`}
																	className="flex items-center justify-center p-2 px-[9px] cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
																>
																	<span className="material-symbols-outlined">
																		expand_all
																	</span>
																</Link>

																<div
																	onClick={() =>
																		copyReviewLink(
																			`https://nerded.io/review?isbn=${book.isbn}&id=${book.review_id}`,
																		)
																	}
																	className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg"
																>
																	<span className="material-symbols-outlined">
																		link
																	</span>
																</div>

																{/*<div className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-nord-800 rounded-lg">
																<span className="material-symbols-outlined">
																	flag
																</span>
															</div>*/}

																<div className="ml-auto flex items-center rounded-lg w-fit mr-1">
																	<StarRating
																		type="yellow-non-viewer"
																		rating={
																			book.rating
																		}
																	/>
																</div>
															</div>
														</div>

														<div className="bg-nord-900 h-fit w-full border-2 border-slate-700 rounded-lg p-3 flex flex-row gap-[1.5rem]">
															<img
																src={book.book_cover
																	.replace(
																		"edge=curl",
																		"edge=natural",
																	)
																	.replace(
																		"http://",
																		"https://",
																	)}
																className="w-[8rem] h-[12rem] rounded-lg cursor-pointer"
																onClick={() =>
																	(window.location = `/book?isbn=${book.isbn}`)
																}
															/>

															<div className="flex flex-col w-full">
																<span
																	className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
																	onClick={() =>
																		(window.location = `/book?isbn=${book.isbn}`)
																	}
																>
																	{
																		book.book_title
																	}
																</span>
																<span
																	className="text-lg mt-1 cursor-pointer hover:underline w-fit"
																	onClick={() =>
																		(window.location = `/search?q=${book.book_author}`)
																	}
																>
																	{
																		book.book_author
																	}
																</span>

																<div className="flex justify-end h-full items-end">
																	<BookActionButtons
																		isbn={
																			book.isbn
																		}
																		showSendButton={
																			true
																		}
																		readList={
																			usersReadList
																		}
																		bookmarkedList={
																			usersBookmarkedList
																		}
																		booksReviewed={
																			usersBooksReviewed
																		}
																		bookData={{
																			title: book.title,
																			industryIdentifiers:
																				[
																					{
																						identifier:
																							book.isbn,
																					},
																				],
																		}}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</TabsContent>

					<TabsContent value="lists">
						{bookLists.length === 0 && bookLists ? (
							<div className="flex flex-col gap-[2rem] mb-[2rem]">
								<div className="px-3 py-5 rounded-lg gap-2 bg-nord-900 border-2 border-slate-700 flex items-center justify-center">
									<span className="material-symbols-outlined text-slate-300">
										error
									</span>

									<span className="text-slate-300">
										No book lists created yet
									</span>
								</div>

								{authenticated.userId === userId ? (
									<Dialog>
										<DialogTrigger className="w-fit mx-auto">
											<div className="bg-nord-800 w-fit flex flex-row gap-2 items-center rounded-lg mx-auto px-4 transition py-3 cursor-pointer hover:bg-slate-700/80">
												<span className="material-symbols-outlined text-slate-300">
													add
												</span>
												<span className="text-slate-300">
													Create book list
												</span>
											</div>
										</DialogTrigger>
										<DialogContent
											aria-describedby={""}
											className="max-w-[95vw] w-[32rem] rounded-lg"
										>
											<DialogHeader>
												<DialogTitle>
													Create Book List
												</DialogTitle>
											</DialogHeader>

											<div className="pt-[0.4rem] flex flex-col">
												<input
													type="text"
													placeholder="List Name"
													onChange={
														handleReadingListNameInput
													}
													value={readingListNameInput}
													className="w-full border border-slate-700 focus:z-10 bg-slate-950/30 outline-none focus:border-slate-600 p-3 rounded-lg"
												/>

												{/* <textarea
                                                placeholder="Description"
                                                ref={readingListDescriptionRef}
                                                className="w-full border border-slate-700 focus:z-10 bg-slate-950/30 outline-none focus:border-slate-600 p-3 rounded-lg mt-2"
                                            /> */}
											</div>

											<DialogTrigger
												asChild
												onClick={createBookList}
											>
												<div className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-slate-700/50">
													Create
												</div>
											</DialogTrigger>
										</DialogContent>
									</Dialog>
								) : null}
							</div>
						) : (
							<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] gap-2 p-4 py-5 rounded-lg bg-nord-800">
								<span className="text-xl pl-2 text-slate-200">
									Book Lists
								</span>

								<div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-3 mt-4">
									{bookLists.map((list, index) => {
										return (
											<div
												key={index}
												className="flex flex-col gap-2 p-4 px-4 rounded-lg bg-nord-900"
											>
												<div className="flex flex-row w-full mb-2 items-center justify-between gap-2">
													<span className="text-xl w-fit font-semibold items-center flex text-slate-200">
														{list.name}

														<span className="material-symbols-outlined text-slate-300 bg-nord-800/70 p-[2px] rounded ml-3 text-[1.3rem] cursor-pointer">
															keep
														</span>
													</span>

													{/* <span className="text-lg text-slate-300">
                                                            {list.description}
                                                        </span> */}
													{authenticated.userId ===
													userId ? (
														<div className="flex flex-row w-fit items-center gap-[1rem]">
															{/* <span
															className="material-symbols-outlined text-slate-300 cursor-pointer"
															onClick={() =>
																(window.location = `/list?id=${list.id}`)
															}
														>
															list
														</span>

														<span className="material-symbols-outlined text-slate-300 cursor-pointer">
															edit
														</span> */}

															<span
																onClick={() =>
																	deleteBookList(
																		list.id,
																	)
																}
																className="material-symbols-outlined text-slate-300 cursor-pointer"
															>
																delete
															</span>
														</div>
													) : null}
												</div>

												<div className="p-0 gap-[1rem] flex flex-wrap w-full">
													{list.books.length > 0 ? (
														list.books.map(
															(book, index) => {
																return (
																	<div
																		key={
																			index
																		}
																		className="flex flex-row gap-[1rem] p-3 bg-nord-900 border border-slate-700 w-full rounded-lg"
																	>
																		<img
																			src={book.cover
																				.replace(
																					"edge=curl",
																					"edge=natural",
																				)
																				.replace(
																					"http://",
																					"https://",
																				)}
																			className="w-[8rem] rounded-lg cursor-pointer"
																			onClick={() =>
																				(window.location = `/book?isbn=${book.isbn}`)
																			}
																		/>

																		<div className="flex flex-col w-full gap-1">
																			<span
																				className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
																				onClick={() =>
																					(window.location = `/book?isbn=${book.isbn}`)
																				}
																			>
																				{
																					book.title
																				}
																			</span>
																			<span
																				className="text-lg cursor-pointer hover:underline w-fit"
																				onClick={() =>
																					(window.location = `/search?q=${book.author}`)
																				}
																			>
																				{
																					book.authors
																				}
																			</span>

																			<div className="flex justify-end h-full items-end">
																				<BookActionButtons
																					isbn={
																						book.isbn
																					}
																					showSendButton={
																						true
																					}
																					readList={
																						usersReadList
																					}
																					bookmarkedList={
																						usersBookmarkedList
																					}
																					booksReviewed={
																						usersBooksReviewed
																					}
																					bookData={{
																						title: book.title,
																						industryIdentifiers:
																							[
																								{
																									identifier:
																										book.isbn,
																								},
																							],
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																);
															},
														)
													) : (
														<div className="border border-dashed border-slate-700 p-3 rounded-lg">
															No books in this
															list yet!
														</div>
													)}
												</div>
											</div>
										);
									})}
								</div>

								{authenticated.userId === userId ? (
									<Dialog>
										<DialogTrigger>
											<div className="bg-nord-800 mx-auto mt-4 flex flex-row gap-2 items-center rounded-lg border-[1.5px] border-nord-700/40 px-4 w-fit transition py-3 cursor-pointer hover:bg-nord-700/15">
												<span className="material-symbols-outlined text-slate-300">
													add
												</span>
												<span className="text-slate-200">
													Create a Book list
												</span>
											</div>
										</DialogTrigger>
										<DialogContent
											aria-describedby={""}
											className="max-w-[95vw] w-[32rem] rounded-lg"
										>
											<DialogHeader>
												<DialogTitle className="font-[500]">
													Create Book List
												</DialogTitle>
											</DialogHeader>

											<div className="pt-[0.4rem] flex flex-col">
												<input
													type="text"
													placeholder="Book List Name"
													onChange={
														handleReadingListNameInput
													}
													value={readingListNameInput}
													className="w-full border-slate-700 focus:z-10 bg-slate-950 border outline-none focus:border-slate-600 p-3 rounded-lg"
												/>

												{/* <textarea
                                                    placeholder="Description"
                                                    ref={readingListDescriptionRef}
                                                    className="w-full border border-slate-700 focus:z-10 bg-slate-950/30 outline-none focus:border-slate-600 p-3 rounded-lg mt-2"
                                                /> */}
											</div>

											<DialogTrigger
												asChild
												onClick={createBookList}
											>
												<div className="flex select-none flex-row bg-nord-800 w-fit rounded-lg cursor-pointer gap-2 py-[10px] px-5 items-center transition hover:bg-slate-700">
													Create
												</div>
											</DialogTrigger>
										</DialogContent>
									</Dialog>
								) : null}
							</div>
						)}
					</TabsContent>
				</Tabs>
			</div>

			<Footer />
		</div>
	);
}

export default Profile;
