import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Posthead from "./Posthead";

import { toast } from "sonner";
import { Link } from "react-router-dom";

import BookClubSelector from "./BookClubSelector";

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
  } from "../../components/ui/hover-card"
import { Scroll } from "lucide-react";

export default function BookClubListMenu() {
    const { authenticated, login, logout, signup } = useAuth();

    const [bookClubsDataLoaded, setBookClubsDataLoaded] = useState(false)
    const [bookClubsData, setBookClubsData] = useState([]);

    useEffect(() => {
        async function fetchBookClubs() {
            const response = await fetch(`https://nerded.io/api/bookclub/bookclubs_user_in`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            });

            const data = await response.json();

            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
                return;
            }

            setBookClubsData(data.data);
            setBookClubsDataLoaded(true);
        }

        fetchBookClubs();
    }, [authenticated]);

    if (!authenticated.authenticated) {
        login();
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="mt-4 mb-[4rem]">
                <div className="container mx-auto flex flex-col gap-4">
                    <div className="flex flex-row items-center gap-4">
                        <Link to="/bookclub" className="text-slate-300 hover:text-emerald
                        -300 transition">Book Club</Link>
                        <span className="text-slate-400">/</span>
                        <span className="text-slate-400">Book Club List</span>
                    </div>
                </div>

                {bookClubsDataLoaded ?
                    bookClubsData.length === 0 ?
                        <div className="flex flex-row mx-auto w-[18rem] mt-[3rem] mb-[-3rem] items-center justify-center transition border-nord-700 border-2 px-4 py-3 text-lg rounded-lg bg-nord-800">
                            <div className="rounded flex flex-row items-center gap-2 text-slate-200">
                                <span className="material-symbols-outlined">info</span>
                                <span className="">No book clubs found</span>
                            </div>
                        </div>
                    : null
                :
                <div className="flex flex-row mx-auto w-[18rem] mt-[3rem] mb-[-3rem] items-center justify-center transition border-slate-700 border-2 px-4 py-3 text-lg rounded-lg bg-nord-800">
                    <div className="rounded flex flex-row items-center gap-2 text-slate-200">
                        <span className="material-symbols-outlined animate-spin">cached</span>
                        <span className="">Loading...</span>
                    </div>
                </div>
                }

                <div className="container mx-auto mt-[3rem] md:w-[80vw] lg:w-[80vw] xl:w-[80vw] 2xl:w-[80vw] w-[95vw] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-3">
                    {
                        bookClubsData.map((bookClub) => {
                            return <Link key={bookClub.id} to={`/bookclub/view?id=${bookClub.id}`} className="flex flex-row items-center justify-between transition hover:border-slate-700 border-2 border-transparent px-4 py-3 text-lg rounded-lg bg-nord-800">
                                <div>{bookClub.name}</div>
                                <div className="bg-nord-900 py-1 px-3 rounded hidden md:flex lg:flex xl:flex 2xl:flex">{bookClub.member_count} members</div>
                            </Link>
                        })
                    }
                </div>

                <div className="flex flex-row gap-3 justify-center mt-[3rem] mx-4">
                        <Link to="/bookclub/create" className="bg-nord-800 w-[14rem] justify-center rounded-lg flex items-center gap-2 text-slate-200 hover:bg-nord-hover transition p-4">
                            <span className="material-symbols-outlined">add</span>
                            <span>Create Book Club</span>
                        </Link>

                        <Link to="/bookclub/join" className="bg-nord-800 w-[14rem] justify-center rounded-lg flex items-center gap-2 text-slate-200 hover:bg-nord-hover transition p-4">
                            <span className="material-symbols-outlined">keyboard_command_key</span>
                            <span>Join Book Club</span>
                        </Link>
                    </div>

            </div>

            <Footer />
        </div>
    )
}
