import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import DropdownMenuDemo from "../../components/UserDropdown";

import {
    HoverCard, HoverCardTrigger, HoverCardContent
} from "../../components/ui/hover-card"

import { Link } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

export default function ReportAbusePage() {
	const { authenticated, login, logout } = useAuth();

	return (
		<div className="bg-nord-900 min-h-screen m-0 p-0 inset-0 top-0">
			{/* <Navbar /> */}

			<div className="w-screen mt-[-1px] ml-[-1px] border-b border-b-nord-700 flex justify-center items-center">
				<nav className="w-screen lg:max-w-[75vw] flex flex-row justify-between text-zinc-50 gap-3 px-6 py-[1rem] text-xl items-center">
					<Link
						to="/home"
						className="flex flex-row gap-3 cursor-pointer items-center"
					>
						<div className="bg-emerald-900 h-[50px] w-[50px] flex items-center justify-center rounded-lg p-2">
							<span className="material-symbols-outlined leading-none text-[2rem] text-emerald-300">
								local_library
							</span>
						</div>

						<span className="text-zinc-50 text-[1.8rem] hidden md:flex">
							nerded
						</span>
					</Link>

					<div className="absolute w-fit left-1/2 gap-2 flex-row translate-x-[-50%] flex justify-center mx-auto items-center">
                    <HoverCard openDelay={30} closeDelay={100}>
						<HoverCardTrigger className="rounded-full flex cursor-help border-b-[4px] border-b-sky-900 flex-row gap-2 items-center select-none px-4 py-[4px] bg-sky-700">
							<span className="text-white">Help Center</span>
                            <span className="material-symbols-outlined text-zinc-50">
                                note_stack
                            </span>
						</HoverCardTrigger>
                        <HoverCardContent side="bottom" className="p-0 rounded bg-zinc-100 shadow-none mt-[2px]">
                            <div className="flex flex-col gap-3 rounded-xl text-zinc-50 p-2 px-3 border-[2px] border-nord-700">
                                <Link to="/help-center" className="hover:text-sky-700 transition">Help Center</Link>
                                <Link to="/help-center" className="hover:text-sky-700 transition">FAQs</Link>
                                <Link to="/help-center" className="hover:text-sky-700 transition">Contact Us</Link>
                            </div>
                        </HoverCardContent>
                        </HoverCard>
					</div>

					<div className="w-[4rem]">
						{authenticated.authenticated ? (
							<DropdownMenuDemo />
						) : (
							<div
								onClick={login}
								className="ml-auto flex items-center justify-center w-fit cursor-pointer font-medium"
							>
								<span className="material-symbols-outlined text-[2rem] hover:text-sky-500 transition">
									login
								</span>
							</div>
						)}
					</div>
				</nav>
			</div>

			<div className="flex flex-col border border-nord-700 my-[3rem] rounded-xl w-[30rem] mx-auto items-center justify-center">
				<div className="border-b border-b-nord-700 w-full">
					<h1 className="text-2xl px-3 pt-[1rem] pb-[1rem] flex justify-center items-center rounded-lg text-zinc-50">
						Suggest a Feature
					</h1>
				</div>

				<div className="w-full bg-transparent p-4 flex flex-col gap-3">
					<div className="flex flex-col gap-1">
						<label className="text-zinc-50">Your Name</label>
						<input
							type="text"
							className="outline-none p-3 py-2 text-zinc-50 rounded-lg placeholder:text-zinc-200 transition border border-nord-700 bg-nord-800"
                            defaultValue={authenticated.authenticated ? authenticated.name : ""}
						/>
					</div>

                    <div className="flex flex-col gap-1">
						<label className="text-zinc-50">Email</label>
						<input
							type="text"
							className="outline-none p-3 py-2 text-zinc-50 rounded-lg placeholder:text-zinc-200 transition border border-nord-700 bg-nord-800"
                            defaultValue={authenticated.authenticated ? authenticated.email : ""}
						/>
					</div>

                    <div className="flex flex-col gap-1">
						<label className="text-zinc-50">Feature Idea</label>
						<textarea
							className="outline-none p-3 py-2 text-zinc-50 rounded-lg placeholder:text-zinc-200 transition border border-nord-700 bg-nord-800"
                            placeholder="What feature would you like to see added?"
						/>
					</div>

                    <div className="p-2 pb-[5.5px] active:pb-[5.5px] px-5 items-center text-[1.05rem] w-fit rounded-xl bg-sky-700 text-white text-center cursor-pointer hover:bg-sky-800 transition border-b-[5px] select-none border-b-sky-900 hover:border-b-sky-950 active:border-b-0 active:mt-[5px]">
                        <div>Submit</div>
                    </div>
				</div>
			</div>

			<Footer light={false} />
		</div>
	);
}
