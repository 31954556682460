// down chevron
import { ChevronDown } from "lucide-react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "sonner";

export default function BookClubSelector(props) {
    const { authenticated, login, logout, signup } = useAuth();
    const [open, setOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const bookClubId = urlParams.get("id");

    function handleOpen() {
      setOpen(!open);
    }

    const [bookClubsData, setBookClubsData] = useState([]);

    useEffect(() => {
          async function fetchBookClubs() {
              const response = await fetch(`https://nerded.io/api/bookclub/bookclubs_user_in`, {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                      user_id: authenticated.userId,
                      session_token: authenticated.sessionToken,
                  })
              });

              const data = await response.json();

              if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                  toast.error(data.error);
                  return;
              }

              setBookClubsData(data.data);
          }

          fetchBookClubs();
      }, [authenticated]);

      // find the book club that matches the id
      let bookClub;

      if (bookClubsData.length > 0) {
          bookClub = bookClubsData.find((bookClub) => bookClub.id === bookClubId);
      }

    return (
      <div className="flex flex-col gap-3">
        {bookClub ? <Link to={`/bookclub/view?id=${bookClub.id}`} onClick={handleOpen} className={`font-semibold select-none text-[1.2rem] w-full rounded-lg px-3 py-2 flex items-center relative cursor-pointer`}>
          {bookClub.name}
          <ChevronDown
            className={`ml-auto h-4 w-4 text-slate-300 transition-transform duration-300 ${
              open ? "rotate-180" : ""
            }`}
          />
        </Link> : null}

        {open && (
          <div className="select-none flex flex-col gap-2 bg-nord-900 border border-slate-700 p-3 rounded-lg">
            <span className="flex items-center justify-center w-full text-slate-400 underline">
              My Book Clubs
            </span>

            {bookClubsData.map((bookClub) => {
              if (bookClub.id === bookClubId && bookClubsData.length !== 1) {
                return null;
              }

              if (bookClubsData.length === 1) {
                return (
                  <span className="w-full px-4 text-center bg-nord-800/50 border-[1.5px] border-slate-700/50 text-slate-300 rounded-full py-2">
                    No other book clubs
                  </span>
                )
              }

              return (
                <Link key={bookClub.id} to={`/bookclub/view?id=${bookClub.id}`} className="text-[1.2rem] bg-nord-900 hover:bg-nord-800 transition cursor-pointer rounded-lg py-2 px-3">
                  {bookClub.name}
                </Link>
              )
            })}
          </div>
        )}
      </div>
    );
}
